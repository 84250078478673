import axios from "axios";
import { APPOINTMENT_ENGINE_API_URL } from "api/ApiConstants";

export const AppointmentApiQueryKeys = {
  LANGUAGES: "languages",
  TASKS_FOR_LANGUAGE: "tasks_for_language",
};

export const getAppointmentLanguages = async () => {
  return axios.get(`${APPOINTMENT_ENGINE_API_URL}/appointment/languages`, {});
};

export const getAppointmentTasksForLanguage = async ({ queryKey }) => {
  if (queryKey[1] == null) {
    return null;
  }
  return axios.get(
    `${APPOINTMENT_ENGINE_API_URL}/appointment/tasks/${queryKey[1]}`
  );
};

export const getBranches = async (data) => {
  return axios.get(`${APPOINTMENT_ENGINE_API_URL}/appointment/branches`, {
    params: data,
  });
};

export const getEmptySlots = async (data) => {
  return axios.get(`${APPOINTMENT_ENGINE_API_URL}/appointment/emptySlots`, {
    params: data,
  });
};

export const reserveTimeSlot = async ({ data, token }) => {
  return axios
    .post(`${APPOINTMENT_ENGINE_API_URL}/appointment/reservingTimeSlot`, data, {
      headers: { "recaptcha-token": token },
    })
    .catch((res) => res);
};

export const finalizeReservation = async (data) => {
  return axios.get(
    `${APPOINTMENT_ENGINE_API_URL}/appointment/finalizing?bookingCode=${data.bookingCode}&mailedVerificationCode=${data.mailedVerificationCode}`
  );
};

export const deleteReservation = async (data) => {
  return axios.get(
    `${APPOINTMENT_ENGINE_API_URL}/appointment/deleting?bookingCode=${data.bookingCode}&mailedVerificationCode=${data.mailedVerificationCode}`
  );
};
