const icons = {
  5: "sources/pics/cikk_ikonok/idopont_nagy_feher.png",
  6: "sources/pics/cikk_ikonok/dokumentumok_nagy_feher.png",
  7: "sources/pics/cikk_ikonok/valtozasok_nagy_feher.png",
  8: "sources/pics/cikk_ikonok/idopontfoglalas_nagy_feher.png",
  9: "sources/pics/cikk_ikonok/irjonnekunk_nagy_feher.png",
  10: "sources/pics/cikk_ikonok/hivjon_nagy_feher.png",
  30: "sources/pics/cikk_ikonok/tortenelem_nagy_feher.png",
  31: "sources/pics/cikk_ikonok/utjelzo_nagy_feher.png",
  32: "sources/pics/cikk_ikonok/cegcsoport_nagy_feher.png",
  33: "sources/pics/cikk_ikonok/partnereink_nagy_feher.png",
  34: "sources/pics/cikk_ikonok/vezetoseg_nagy_feher.png",
  35: "sources/pics/cikk_ikonok/cegcsoport_tevekenyseg_nagy_feher.png",
  11: "sources/pics/cikk_ikonok/keressenszemelyesen_nagy_feher.png",
  657: "sources/pics/cikk_ikonok/bkfizetes_nagy_feher.png",
  654: "sources/pics/cikk_ikonok/atirjuk_nagy_feher.png",
  594: "sources/pics/cikk_ikonok/idopont_nagy_feher.png",
  595: "sources/pics/cikk_ikonok/dokumentumok_nagy_feher.png",
  596: "sources/pics/cikk_ikonok/valtozasok_nagy_feher.png",
  655: "sources/pics/cikk_ikonok/atirjuk_nagy_feher.png",
  661: "sources/pics/cikk_ikonok/bkfizetes_nagy_feher.png",
  293: "sources/pics/cikk_ikonok/altalanos_ikon.png",
  294: "sources/pics/cikk_ikonok/ingatlanorokles_24px.png",
  295: "sources/pics/cikk_ikonok/alberlet_24px.png",
  296: "sources/pics/cikk_ikonok/nevvaltozas_nagy_feher.png",
  297: "sources/pics/cikk_ikonok/cimvaltozas_nagy_feher.png",
  298: "sources/pics/cikk_ikonok/merocsere_nagy_feher.png",
  362: "sources/pics/cikk_ikonok/befizetesmodja_nagy_feher.png",
  363: "sources/pics/cikk_ikonok/kezbesites_nagy_feher.png",
  364: "sources/pics/cikk_ikonok/ugyfelszolgalatok_nagy_feher.png",
  365: "sources/pics/cikk_ikonok/dijfelosztas_nagy_feher.png",
  366: "sources/pics/cikk_ikonok/fogalomtar_nagy_feher.png",
  367: "sources/pics/cikk_ikonok/gyik_nagy_feher.png",
  18: "sources/pics/cikk_ikonok/befizetesmodja_nagy_feher.png",
  19: "sources/pics/cikk_ikonok/kezbesites_nagy_feher.png",
  20: "sources/pics/cikk_ikonok/ugyfelszolgalatok_nagy_feher.png",
  21: "sources/pics/cikk_ikonok/dijfelosztas_nagy_feher.png",
  22: "sources/pics/cikk_ikonok/fogalomtar_nagy_feher.png",
  23: "sources/pics/cikk_ikonok/gyik_nagy_feher.png",
  304: "sources/pics/cikk_ikonok/uj_kozos_ikon.png",
  306: "sources/pics/cikk_ikonok/ingatlanorokles_24px.png",
  307: "sources/pics/cikk_ikonok/alberlet_24px.png",
  390: "sources/pics/cikk_ikonok/befizetesmodja_nagy_feher.png",
  391: "sources/pics/cikk_ikonok/kezbesites_nagy_feher.png",
  392: "sources/pics/cikk_ikonok/ugyfelszolgalatok_nagy_feher.png",
  393: "sources/pics/cikk_ikonok/dijfelosztas_nagy_feher.png",
  394: "sources/pics/cikk_ikonok/fogalomtar_nagy_feher.png",
  395: "sources/pics/cikk_ikonok/gyik_nagy_feher.png",
  524: "sources/pics/cikk_ikonok/eugyintezes_nagy_feher.png",
  525: "sources/pics/cikk_ikonok/szamlamasolatok_nagy_feher.png",
  526: "sources/pics/cikk_ikonok/cimvaltozas_nagy_feher.png",
  527: "sources/pics/cikk_ikonok/szamlabejelentes_nagy_feher.png",
  528: "sources/pics/cikk_ikonok/ugyfelszolgalatok_nagy_feher.png",
  529: "sources/pics/cikk_ikonok/mit_hol_nagy_feher.png",
  597: "sources/pics/cikk_ikonok/idopont_nagy_feher.png",
  598: "sources/pics/cikk_ikonok/dokumentumok_nagy_feher.png",
  599: "sources/pics/cikk_ikonok/valtozasok_nagy_feher.png",
  656: "sources/pics/cikk_ikonok/atirjuk_nagy_feher.png",
  662: "sources/pics/cikk_ikonok/bkfizetes_nagy_feher.png",
  523: "sources/pics/cikk_ikonok/mit_hol_nagy_feher.png",
  519: "sources/pics/cikk_ikonok/szamlamasolatok_nagy_feher.png",
  521: "sources/pics/cikk_ikonok/szamlabejelentes_nagy_feher.png",
  518: "sources/pics/cikk_ikonok/eugyintezes_nagy_feher.png",
  520: "sources/pics/cikk_ikonok/cimvaltozas_nagy_feher.png",
  522: "sources/pics/cikk_ikonok/ugyfelszolgalatok_nagy_feher.png",
  745: "sources/pics/cikk_ikonok/e-szamla_nagy_feher.png",
  746: "media/pics/page_main_pics/e-szamla_nagy_feher.png",
  747: "media/pics/page_main_pics/e-szamla_dijnet_nagy_feher.png",
  sm_746: "media/pics/page_main_pics/e-szamla_kicsi_sarga.png",
  sm_747: "media/pics/page_main_pics/e-szamla_dijnet_kicsi_sarga.png",
  12: "sources/pics/cikk_ikonok/lakasvasarlas_nagy_feher.png",
  13: "sources/pics/cikk_ikonok/ingatlanorokles_24px.png",
  14: "sources/pics/cikk_ikonok/alberlet_24px.png",
  15: "sources/pics/cikk_ikonok/nevvaltozas_nagy_feher.png",
  16: "sources/pics/cikk_ikonok/cimvaltozas_nagy_feher.png",
  17: "sources/pics/cikk_ikonok/merocsere_nagy_feher.png",
  29: "sources/pics/cikk_ikonok/mit_hol_nagy_feher.png",
  25: "sources/pics/cikk_ikonok/szamlamasolatok_nagy_feher.png",
  27: "sources/pics/cikk_ikonok/szamlabejelentes_nagy_feher.png",
  24: "sources/pics/cikk_ikonok/eugyintezes_nagy_feher.png",
  26: "sources/pics/cikk_ikonok/cimvaltozas_nagy_feher.png",
  28: "sources/pics/cikk_ikonok/ugyfelszolgalatok_nagy_feher.png",
  663: "media/pics/page_main_pics/bankkartya_48x48.png",
  43: "media/pics/page_main_pics/dijnet.png",
  41: "media/pics/page_main_pics/qrcode.png",
  40: "media/pics/page_main_pics/csoportos_besz.png",
  39: "media/pics/page_main_pics/atutalas.png",
  44: "media/pics/page_main_pics/kirendeltsegek.png",
  38: "media/pics/page_main_pics/befizetesmodja_keszpenzesfizetes.png",
  42: "media/pics/page_main_pics/otpatm.png",
  45: "media/pics/page_main_pics/vedendo_fogyasztok.png",
  53: "media/pics/page_main_pics/dijbeszedes.png",
  54: "media/pics/page_main_pics/postai.png",
  55: "media/pics/page_main_pics/elektronikus.png",
  90: "media/pics/page_main_pics/info_altalanostajekoztatas.png",
  82: "media/pics/page_main_pics/felszolitolevel.png",
  83: "media/pics/page_main_pics/tertiveveny_felszolitolevel.png",
  84: "media/pics/page_main_pics/nav_level.png",
  85: "media/pics/page_main_pics/jegyzo_level.png",
  86: "media/pics/page_main_pics/fizetesimeghagyas.png",
  87: "media/pics/page_main_pics/vegrehajtas.png",
  88: "media/pics/page_main_pics/fizetes.png",
  89: "media/pics/page_main_pics/dijfelosztas_reszletfizetes.png",
  92: "media/pics/page_main_pics/tarsashaz.png",
  384: "media/pics/page_main_pics/dijbeszedes.png",
  385: "media/pics/page_main_pics/postai.png",
  386: "media/pics/page_main_pics/elektronikus.png",
  387: "media/pics/page_main_pics/dijbeszedes.png",
  388: "media/pics/page_main_pics/postai.png",
  389: "media/pics/page_main_pics/elektronikus.png",
  666: "media/pics/page_main_pics/bankkartya_48x48.png",
  373: "media/pics/page_main_pics/dijnet.png",
  371: "media/pics/page_main_pics/qrcode.png",
  370: "media/pics/page_main_pics/csoportos_besz.png",
  369: "media/pics/page_main_pics/atutalas.png",
  374: "media/pics/page_main_pics/kirendeltsegek.png",
  368: "media/pics/page_main_pics/befizetesmodja_keszpenzesfizetes.png",
  372: "media/pics/page_main_pics/otpatm.png",
  375: "media/pics/page_main_pics/vedendo_fogyasztok.png",
  667: "media/pics/page_main_pics/bankkartya_48x48.png",
  381: "media/pics/page_main_pics/dijnet.png",
  379: "media/pics/page_main_pics/qrcode.png",
  378: "media/pics/page_main_pics/csoportos_besz.png",
  377: "media/pics/page_main_pics/atutalas.png",
  382: "media/pics/page_main_pics/kirendeltsegek.png",
  376: "media/pics/page_main_pics/befizetesmodja_keszpenzesfizetes.png",
  380: "media/pics/page_main_pics/otpatm.png",
  383: "media/pics/page_main_pics/vedendo_fogyasztok.png",
  sm_5: "sources/pics/cikk_ikonok/idopont_kicsi_lila.png",
  sm_6: "sources/pics/cikk_ikonok/dokumentumok_kicsi_lila.png",
  sm_7: "sources/pics/cikk_ikonok/valtozasok_kicsi_lila.png",
  sm_purple_8: "sources/pics/cikk_ikonok/idopontfoglalas_kicsi_lila.png",
  sm_purple_9: "sources/pics/cikk_ikonok/irjonnekunk_kicsi_lila.png",
  sm_purple_10: "sources/pics/cikk_ikonok/hivjon_kicsi_lila.png",
  sm_purple_11: "sources/pics/cikk_ikonok/keressenszemelyesen_kicsi_lila.png",
  sm_orange_8: "sources/pics/cikk_ikonok/idopontfoglalas_kicsi_sarga.png",
  sm_orange_9: "sources/pics/cikk_ikonok/irjonnekunk_kicsi_sarga.png",
  sm_orange_10: "sources/pics/cikk_ikonok/hivjon_kicsi_sarga.png",
  sm_orange_11: "sources/pics/cikk_ikonok/keressenszemelyesen_kicsi_sarga.png",
  sm_green_8: "sources/pics/cikk_ikonok/idopontfoglalas_kicsi_zold.png",
  sm_green_9: "sources/pics/cikk_ikonok/irjonnekunk_kicsi_zold.png",
  sm_green_10: "sources/pics/cikk_ikonok/hivjon_kicsi_zold.png",
  sm_green_11: "sources/pics/cikk_ikonok/keressenszemelyesen_kicsi_zold.png",
  sm_purple_30: "sources/pics/cikk_ikonok/tortenelem_kicsi_lila.png",
  sm_purple_31: "sources/pics/cikk_ikonok/utjelzo_kicsi_lila.png",
  sm_purple_32: "sources/pics/cikk_ikonok/cegcsoport_kicsi_lila.png",
  sm_purple_33: "sources/pics/cikk_ikonok/partnereink_kicsi_lila.png",
  sm_purple_34: "sources/pics/cikk_ikonok/vezetoseg_kicsi_lila.png",
  sm_purple_35:
    "sources/pics/cikk_ikonok/cegcsoport_tevekenyseg_kicsi_lila.png",
  sm_orange_30: "sources/pics/cikk_ikonok/tortenelem_kicsi_sarga.png",
  sm_orange_31: "sources/pics/cikk_ikonok/utjelzo_kicsi_sarga.png",
  sm_orange_32: "sources/pics/cikk_ikonok/cegcsoport_kicsi_sarga.png",
  sm_orange_33: "sources/pics/cikk_ikonok/partnereink_kicsi_sarga.png",
  sm_orange_34: "sources/pics/cikk_ikonok/vezetoseg_kicsi_sarga.png",
  sm_orange_35:
    "sources/pics/cikk_ikonok/cegcsoport_tevekenyseg_kicsi_sarga.png",
  sm_green_30: "sources/pics/cikk_ikonok/tortenelem_kicsi_zold.png",
  sm_green_31: "sources/pics/cikk_ikonok/utjelzo_kicsi_zold.png",
  sm_green_32: "sources/pics/cikk_ikonok/cegcsoport_kicsi_zold.png",
  sm_green_33: "sources/pics/cikk_ikonok/partnereink_kicsi_zold.png",
  sm_green_34: "sources/pics/cikk_ikonok/vezetoseg_kicsi_zold.png",
  sm_green_35: "sources/pics/cikk_ikonok/cegcsoport_tevekenyseg_kicsi_zold.png",
  sm_657: "sources/pics/cikk_ikonok/bkfizetes_kicsi_lila.png",
  sm_654: "sources/pics/cikk_ikonok/atirjuk_kicsi_lila.png",
  sm_594: "sources/pics/cikk_ikonok/idopont_kicsi_sarga.png",
  sm_595: "sources/pics/cikk_ikonok/dokumentumok_kicsi_sarga.png",
  sm_596: "sources/pics/cikk_ikonok/valtozasok_kicsi_sarga.png",
  sm_655: "sources/pics/cikk_ikonok/atirjuk_kicsi_sarga.png",
  sm_661: "sources/pics/cikk_ikonok/bkfizetes_kicsi_sarga.png",
  sm_293: "sources/pics/cikk_ikonok/lakasvasarlas_kicsi_sarga.png",
  sm_294: "sources/pics/cikk_ikonok/ingatlanorokles_kicsi_sarga.png",
  sm_295: "sources/pics/cikk_ikonok/alberlet_kicsi_sarga.png",
  sm_296: "sources/pics/cikk_ikonok/nevvaltozas_kicsi_sarga.png",
  sm_297: "sources/pics/cikk_ikonok/cimvaltozas_kicsi_sarga.png",
  sm_298: "sources/pics/cikk_ikonok/merocsere_kicsi_sarga.png",
  sm_362: "sources/pics/cikk_ikonok/befizetesmodja_kicsi_sarga.png",
  sm_363: "sources/pics/cikk_ikonok/kezbesites_kicsi_sarga.png",
  sm_364: "sources/pics/cikk_ikonok/ugyfelszolgalatok_kicsi_sarga.png",
  sm_365: "sources/pics/cikk_ikonok/dijfelosztas_kicsi_sarga.png",
  sm_366: "sources/pics/cikk_ikonok/fogalomtar_kicsi_sarga.png",
  sm_367: "sources/pics/cikk_ikonok/gyik_kicsi_sarga.png",
  sm_18: "sources/pics/cikk_ikonok/befizetesmodja_kicsi_lila.png",
  sm_19: "sources/pics/cikk_ikonok/kezbesites_kicsi_lila.png",
  sm_20: "sources/pics/cikk_ikonok/ugyfelszolgalatok_kicsi_lila.png",
  sm_21: "sources/pics/cikk_ikonok/dijfelosztas_kicsi_lila.png",
  sm_22: "sources/pics/cikk_ikonok/fogalomtar_kicsi_lila.png",
  sm_23: "sources/pics/cikk_ikonok/gyik_kicsi_lila.png",
  sm_304: "sources/pics/cikk_ikonok/uj_kozos_ikon_kicsi_zold.png",
  sm_306: "sources/pics/cikk_ikonok/ingatlanorokles_kicsi_zold.png",
  sm_307: "sources/pics/cikk_ikonok/alberlet_kicsi_zold.png",
  sm_390: "sources/pics/cikk_ikonok/befizetesmodja_kicsi_zold.png",
  sm_391: "sources/pics/cikk_ikonok/kezbesites_kicsi_zold.png",
  sm_392: "sources/pics/cikk_ikonok/ugyfelszolgalatok_kicsi_zold.png",
  sm_393: "sources/pics/cikk_ikonok/dijfelosztas_kicsi_zold.png",
  sm_394: "sources/pics/cikk_ikonok/fogalomtar_kicsi_zold.png",
  sm_395: "sources/pics/cikk_ikonok/gyik_kicsi_zold.png",
  sm_529: "sources/pics/cikk_ikonok/mit_hol_kicsi_zold.png",
  sm_525: "sources/pics/cikk_ikonok/szamlamasolatok_kicsi_zold.png",
  sm_527: "sources/pics/cikk_ikonok/szamlabejelentes_kicsi_zold.png",
  sm_524: "sources/pics/cikk_ikonok/eugyintezes_kicsi_zold.png",
  sm_526: "sources/pics/cikk_ikonok/cimvaltozas_kicsi_zold.png",
  sm_528: "sources/pics/cikk_ikonok/ugyfelszolgalatok_kicsi_zold.png",
  sm_597: "sources/pics/cikk_ikonok/idopont_kicsi_zold.png",
  sm_598: "sources/pics/cikk_ikonok/dokumentumok_kicsi_zold.png",
  sm_599: "sources/pics/cikk_ikonok/valtozasok_kicsi_zold.png",
  sm_656: "sources/pics/cikk_ikonok/atirjuk_kicsi_zold.png",
  sm_662: "sources/pics/cikk_ikonok/bkfizetes_kicsi_zold.png",
  sm_523: "sources/pics/cikk_ikonok/mit_hol_kicsi_sarga.png",
  sm_519: "sources/pics/cikk_ikonok/szamlamasolatok_kicsi_sarga.png",
  sm_521: "sources/pics/cikk_ikonok/szamlabejelentes_kicsi_sarga.png",
  sm_518: "sources/pics/cikk_ikonok/eugyintezes_kicsi_sarga.png",
  sm_520: "sources/pics/cikk_ikonok/cimvaltozas_kicsi_sarga.png",
  sm_522: "sources/pics/cikk_ikonok/ugyfelszolgalatok_kicsi_sarga.png",
  sm_745: "sources/pics/cikk_ikonok/e-szamla_nagy_feher.png",
  sm_12: "sources/pics/cikk_ikonok/lakasvasarlas_kicsi_lila.png",
  sm_13: "sources/pics/cikk_ikonok/ingatlanorokles_kicsi_lila.png",
  sm_14: "sources/pics/cikk_ikonok/alberlet_kicsi_lila.png",
  sm_15: "sources/pics/cikk_ikonok/nevvaltozas_kicsi_lila.png",
  sm_16: "sources/pics/cikk_ikonok/cimvaltozas_kicsi_lila.png",
  sm_17: "sources/pics/cikk_ikonok/merocsere_kicsi_lila.png",
  sm_29: "sources/pics/cikk_ikonok/mit_hol_kicsi_lila.png",
  sm_25: "sources/pics/cikk_ikonok/szamlamasolatok_kicsi_lila.png",
  sm_27: "sources/pics/cikk_ikonok/szamlabejelentes_kicsi_lila.png",
  sm_24: "sources/pics/cikk_ikonok/eugyintezes_kicsi_lila.png",
  sm_26: "sources/pics/cikk_ikonok/cimvaltozas_kicsi_lila.png",
  sm_28: "sources/pics/cikk_ikonok/ugyfelszolgalatok_kicsi_lila.png",
  sm_663: "media/pics/page_main_pics/bankkartya_48x48.png",
  sm_43: "media/pics/page_main_pics/dijnet.png",
  sm_41: "media/pics/page_main_pics/qrcode.png",
  sm_40: "media/pics/page_main_pics/csoportos_besz.png",
  sm_39: "media/pics/page_main_pics/atutalas.png",
  sm_44: "media/pics/page_main_pics/kirendeltsegek.png",
  sm_38: "media/pics/page_main_pics/befizetesmodja_keszpenzesfizetes.png",
  sm_42: "media/pics/page_main_pics/otpatm.png",
  sm_45: "media/pics/page_main_pics/vedendo_fogyasztok.png",
  sm_53: "media/pics/page_main_pics/dijbeszedes.png",
  sm_54: "media/pics/page_main_pics/postai.png",
  sm_55: "media/pics/page_main_pics/elektronikus.png",
  sm_90: "media/pics/page_main_pics/info_altalanostajekoztatas.png",
  sm_82: "media/pics/page_main_pics/felszolitolevel.png",
  sm_83: "media/pics/page_main_pics/tertiveveny_felszolitolevel.png",
  sm_84: "media/pics/page_main_pics/nav_level.png",
  sm_85: "media/pics/page_main_pics/jegyzo_level.png",
  sm_86: "media/pics/page_main_pics/fizetesimeghagyas.png",
  sm_87: "media/pics/page_main_pics/vegrehajtas.png",
  sm_88: "media/pics/page_main_pics/fizetes.png",
  sm_89: "media/pics/page_main_pics/dijfelosztas_reszletfizetes.png",
  sm_92: "media/pics/page_main_pics/tarsashaz.png",
  91: "media/pics/page_main_pics/tarsashaz.png",
  580: "media/pics/page_main_pics/info_altalanostajekoztatas.png",
  572: "media/pics/page_main_pics/felszolitolevel.png",
  573: "media/pics/page_main_pics/tertiveveny_felszolitolevel.png",
  574: "media/pics/page_main_pics/nav_level.png",
  575: "media/pics/page_main_pics/jegyzo_level.png",
  576: "media/pics/page_main_pics/fizetesimeghagyas.png",
  577: "media/pics/page_main_pics/vegrehajtas.png",
  578: "media/pics/page_main_pics/fizetes.png",
  579: "media/pics/page_main_pics/dijfelosztas_reszletfizetes.png",
  582: "media/pics/page_main_pics/tarsashaz.png",
  581: "media/pics/page_main_pics/fizetes.png",
  591: "media/pics/page_main_pics/info_altalanostajekoztatas.png",
  583: "media/pics/page_main_pics/felszolitolevel.png",
  584: "media/pics/page_main_pics/tertiveveny_felszolitolevel.png",
  585: "media/pics/page_main_pics/nav_level.png",
  586: "media/pics/page_main_pics/jegyzo_level.png",
  587: "media/pics/page_main_pics/fizetesimeghagyas.png",
  588: "media/pics/page_main_pics/vegrehajtas.png",
  589: "media/pics/page_main_pics/fizetes.png",
  590: "media/pics/page_main_pics/dijfelosztas_reszletfizetes.png",
  593: "media/pics/page_main_pics/tarsashaz.png",
  592: "media/pics/page_main_pics/fizetes.png",
  765: "media/pics/page_main_pics/e-szamla_nagy_feher.png",
  767: "media/pics/page_main_pics/e-szamla_dijnet_nagy_feher.png",
  sm_765: "media/pics/page_main_pics/e-szamla_kicsi_sarga.png",
  sm_767: "media/pics/page_main_pics/e-szamla_dijnet_kicsi_sarga.png",
};

export const getIconByContentId = (id) => {
  return icons[id];
};

const contentBg = {
  5: "sources/pics/idopontfoglalas_vf.jpg",
  597: "sources/pics/idopontfoglalas_vf.jpg",
  6: "sources/pics/valtozasok_cikk.jpg",
  598: "sources/pics/valtozasok_cikk.jpg",
  7: "sources/pics/dokumentumok_cikk.jpg",
  599: "sources/pics/dokumentumok_cikk.jpg",
  657: "sources/pics/bkfizetes_hatter.jpg",
  654: "sources/pics/atirjuk_hatter.jpg",
  656: "sources/pics/atirjuk_hatter.jpg",
  594: "sources/pics/idopontfoglalas_vf.jpg",
  595: "sources/pics/valtozasok_cikk.jpg",
  596: "sources/pics/dokumentumok_cikk.jpg",
  655: "sources/pics/atirjuk_hatter.jpg",
  661: "sources/pics/bkfizetes_hatter.jpg",
  662: "sources/pics/bkfizetes_hatter.jpg",
};

export const getBackgroundByContentId = (id) => {
  return contentBg[id];
};

const pageBg = {
  22: "sources/pics/fejlecek/irjon_nekunk_v.jpg",
  23: "sources/pics/fejlecek/panaszkezeles_v.jpg",
  15: "sources/pics/fejlecek/lakast_vasaroltam_v.jpg",
  16: "sources/pics/fejlecek/orokoltem_header.jpg",
  17: "sources/pics/fejlecek/alberletbe_koltoztem_v.jpg",
  18: "sources/pics/fejlecek/valtozott_nev.jpg",
  19: "sources/pics/fejlecek/valtozott_fizetes_header.jpg",
  20: "sources/pics/fejlecek/lecsereltem_meromet_v.jpg",
  12: "sources/pics/fejlecek/letoltheto_dok.jpg",
  126: "sources/pics/fejlecek/ugyfelszolgalat_v.jpg",
  14: "sources/pics/fejlecek/kozosdijak_header.jpg",
  26: "sources/pics/fejlecek/eugyintezes_header.jpg",
  27: "sources/pics/fejlecek/szamlaval_kapcs_v.jpg",
  28: "sources/pics/fejlecek/szolg_dijak_v.jpg",
  29: "sources/pics/fejlecek/szamla_kimutat_v.jpeg",
  71: "sources/pics/fejlecek/panaszkezel_header.jpg",
  55: "sources/pics/fejlecek/eszamla_v.jpg",
  32: "sources/pics/fejlecek/cegtortenet.jpg",
  33: "sources/pics/fejlecek/celjaink_v.jpg",
  34: "sources/pics/fejlecek/cegcsoport_header.jpg",
  35: "sources/pics/fejlecek/partnereink_v.jpg",
  36: "sources/pics/fejlecek/vezetoseg_v.jpg",
  39: "sources/pics/fejlecek/miertjo_header.jpg",
  40: "sources/pics/fejlecek/allasok_header.jpg",
  41: "sources/pics/fejlecek/kollegaink_header.jpg",
  42: "sources/pics/fejlecek/rendezvenyek_header.jpg",
  56: "sources/pics/fejlecek/tarsadalmi_szerepvallalas_v.jpg",
  49: "sources/pics/fejlecek/ugyfel_header.jpg",
  13: "sources/pics/fejlecek/befizezetes.jpg",
  21: "sources/pics/fejlecek/szamlakezbesites_v.jpg",
  25: "sources/pics/fejlecek/fogalomtar_header.jpg",
  44: "sources/pics/fejlecek/gyik_header.jpg",
  43: "sources/pics/fejlecek/kiegyenlitetlen_szamlak_v2.jpg",
  31: "sources/pics/fejlecek/mithol.jpg",
  98: "sources/pics/fejlecek/letoltheto_dok.jpg",
  114: "sources/pics/fejlecek/ujkozos_header.jpg",
  116: "sources/pics/fejlecek/valtozott_nev.jpg",
  117: "sources/pics/fejlecek/valtozott_fizetes_header.jpg",
  122: "sources/pics/fejlecek/befizezetes.jpg",
  124: "sources/pics/fejlecek/szamlakezbesites_v.jpg",
  127: "sources/pics/fejlecek/ugyfelszolgalat_v.jpg",
  129: "sources/pics/fejlecek/kozosdijak_header.jpg",
  137: "sources/pics/fejlecek/eugyintezes_header.jpg",
  139: "sources/pics/fejlecek/mithol.jpg",
  141: "sources/pics/fejlecek/szamlaval_kapcs_v.jpg",
  143: "sources/pics/fejlecek/szamla_kimutat_v.jpeg",
  145: "sources/pics/fejlecek/szolg_dijak_v.jpg",
  147: "sources/pics/fejlecek/panaszkezel_header.jpg",
  151: "sources/pics/fejlecek/kiegyenlitetlen_szamlak_v2.jpg",
  107: "sources/pics/fejlecek/altalanos_header.jpg",
  108: "sources/pics/fejlecek/advesz_header.jpg",
  109: "sources/pics/fejlecek/alberletbe_koltoztem_v.jpg",
  110: "sources/pics/fejlecek/valtozott_nev.jpg",
  193: "sources/pics/fejlecek/valtozott_nev.jpg",
  111: "sources/pics/fejlecek/valtozott_fizetes_header.jpg",
  112: "sources/pics/fejlecek/lecsereltem_meromet_v.jpg",
  97: "sources/pics/fejlecek/letoltheto_dok.jpg",
  121: "sources/pics/fejlecek/befizezetes.jpg",
  123: "sources/pics/fejlecek/szamlakezbesites_v.jpg",
  54: "sources/pics/fejlecek/ugyfelszolgalat_v.jpg",
  128: "sources/pics/fejlecek/kozosdijak_header.jpg",
  150: "sources/pics/fejlecek/kiegyenlitetlen_szamlak_v2.jpg",
  138: "sources/pics/fejlecek/mithol.jpg",
  140: "sources/pics/fejlecek/szamlaval_kapcs_v.jpg",
  142: "sources/pics/fejlecek/szamla_kimutat_v.jpeg",
  136: "sources/pics/fejlecek/eugyintezes_header.jpg",
  144: "sources/pics/fejlecek/szolg_dijak_v.jpg",
  146: "sources/pics/fejlecek/panaszkezel_header.jpg",
  178: "sources/pics/fejlecek/eszamla_v.jpg",
  118: "sources/pics/fejlecek/lecsereltem_meromet_v.jpg",
  176: "sources/pics/fejlecek/eszamla_v.jpg",
  30: "sources/pics/fejlecek/panaszkezeles_v.jpg",
  190: "sources/pics/fejlecek/eszamla_email.jpg",
  186: "sources/pics/fejlecek/kiegyenlitetlen_szamlak_v2.jpg",
};

export const getBackgroundByPageId = (id) => {
  return pageBg[id];
};

const menuIcons = {
  76: "sources/pics/ikonok/kezdo_fekete.png",
  63: "sources/pics/ikonok/idopont_lila.png",
  64: "sources/pics/ikonok/valtozasok_lila.png",
  65: "sources/pics/ikonok/letoltheto_lila.png",
  210: "sources/pics/ikonok/atirjuk_lila.png",
  213: "sources/pics/ikonok/bkfizetes_lila.png",
  75: "sources/pics/ikonok/kereses_szurke.png",
  66: "sources/pics/ikonok/info_fekete.png",
  67: "sources/pics/ikonok/szamlalo_fekete.png",
  68: "sources/pics/ikonok/hatralek_fekete.png",
  69: "sources/pics/ikonok/ugyint_fekete.png",
  70: "sources/pics/small_d_dijnet_logo_2018_png.png",
  187: "sources/pics/ikonok/idopont_sarga.png",
  188: "sources/pics/ikonok/valtozasok_sarga.png",
  189: "sources/pics/ikonok/letoltheto_sarga.png",
  211: "sources/pics/ikonok/atirjuk_sarga.png",
  214: "sources/pics/ikonok/bkfizetes_sarga.png",
  193: "sources/pics/ikonok/info_fekete.png",
  196: "sources/pics/ikonok/szamlalo_fekete.png",
  197: "sources/pics/ikonok/hatralek_fekete.png",
  201: "sources/pics/ikonok/ugyint_fekete.png",
  216: "sources/pics/small_d_dijnet_logo_2018_png.png",
  190: "sources/pics/ikonok/idopont_zold.png",
  191: "sources/pics/ikonok/valtozasok_zold.png",
  192: "sources/pics/ikonok/letoltheto_zold.png",
  212: "sources/pics/ikonok/atirjuk_zold.png",
  215: "sources/pics/ikonok/bkfizetes_zold.png",
  194: "sources/pics/ikonok/info_fekete.png",
  195: "sources/pics/ikonok/szamlalo_fekete.png",
  198: "sources/pics/ikonok/hatralek_fekete.png",
  199: "sources/pics/ikonok/ugyint_fekete.png",
  200: "sources/pics/small_d_dijnet_logo_2018_png.png",
  202: "sources/pics/small_d_dijnet_logo_2018_png.png",
};

export const getIconByMenuId = (id) => {
  return menuIcons[id];
};

export const bannerGif = "media/pics/main_page_main_pics/dbrt_banner_2.gif";
