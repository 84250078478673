import axios from "axios";
import { API_URL } from "api/ApiConstants";

export const BlogApiQueryKeys = {
  BLOG: "blog",
  BLOG_DATA: "blog-data",
};

export const getBlog = async ({ queryKey }) => {
  const { data, headers } = await axios(
    `${API_URL}/blog?pageStart=${queryKey[1]}&pageSize=${queryKey[2]}&lang=hu&parentId=${queryKey[3]}`
  );

  return { data: data, itemCount: headers["x-drsm-allitemcount"] };
};

export const getBlogEntry = async ({ queryKey }) => {
  const { data } = await axios(`${API_URL}/blog/${queryKey[1]}?lang=hu`);
  return data;
};
