import { Breadcrumb } from "components/molecules/Breadcrumb/Breadcrumb";

export const BreadcrumbFromPageData = ({ pageData }) => {
  let elements = [];

  //Parent page
  if (pageData.parentPage) {
    elements.push({
      label: pageData.parentPage.subPageAddress,
      url: pageData.parentPage.url,
    });
  }

  //Current page
  elements.push({
    label: pageData.subPageAddress,
    url: null,
  });

  return <Breadcrumb elements={elements} />;
};
