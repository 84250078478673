import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import PropTypes from "prop-types";
import "./PopoverInfoIcon.scss";
import { Tooltip } from "@mui/material";

export const PopoverInfoIcon = ({ popoverText }) => {
  return (
    <div className="PopoverInfoIcon">
      <Tooltip title={popoverText} arrow={true} placement={"right"}>
        <InfoOutlinedIcon />
      </Tooltip>
    </div>
  );
};

PopoverInfoIcon.propTypes = {
  /**
   * Text to show on popover
   */
  popoverText: PropTypes.string,
};
