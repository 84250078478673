import axios from "axios";
import { API_URL } from "api/ApiConstants";

export const SearchApiQueryKeys = {
  SEARCH: "search",
};

export const search = async ({ queryKey }) => {
  const { data } = await axios(
    `${API_URL}/search?textToSearch=${queryKey[1]}&onlyTitle=${queryKey[2]}&spGroup=${queryKey[3]}`
  );
  return data;
};
