import React from "react";
import { Alert, Snackbar, Typography } from "@mui/material";
import { AlertTitle } from "@mui/lab";
import { useTranslation } from "react-i18next";

const AUTO_HIDE_DURATION = 5000;
const POSITION = {
  vertical: "top",
  horizontal: "center",
};

export const ErrorNotification = ({ open, onClose, message }) => {
  const { t } = useTranslation();
  return (
    <Snackbar
      className={"ErrorNotification"}
      open={open}
      onClose={onClose}
      anchorOrigin={POSITION}
      autoHideDuration={AUTO_HIDE_DURATION}
    >
      <Alert severity={"error"}>
        <AlertTitle>{t("errorNotification.error")}</AlertTitle>
        <Typography>{message}</Typography>
      </Alert>
    </Snackbar>
  );
};
