import axios from "axios";
import { API_URL } from "api/ApiConstants";

export const MenuApiQueryKeys = {
  MENU: "menu",
};

export const getMenu = async () => {
  const { data } = await axios(`${API_URL}/menu-structure?lang=hu`);
  return data;
};
