import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./OnlineBooking.scss";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { OnlineBookingPageOne } from "components/templates/OnlineBooking/pages/OnlineBookingPageOne";
import { OnlineBookingPageTwo } from "components/templates/OnlineBooking/pages/OnlineBookingPageTwo";
import { OnlineBookingPageThree } from "components/templates/OnlineBooking/pages/OnlineBookingPageThree";
import { OnlineBookingPageFour } from "components/templates/OnlineBooking/pages/OnlineBookingPageFour";
import { OnlineBookingPageFive } from "components/templates/OnlineBooking/pages/OnlineBookingPageFive";
import { OnlineBookingConfirmationPage } from "components/templates/OnlineBooking/pages/OnlineBookingConfirmationPage";
import {
  AppointmentApiQueryKeys,
  getAppointmentLanguages,
  getAppointmentTasksForLanguage,
  reserveTimeSlot,
} from "api/appointment/Appointment";
import { BreadcrumbFromPageData } from "../../molecules/Breadcrumb/BreadcrumbFromPageData";
import { useQuery } from "react-query";
import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import { GET_SITE_KEY, SITE_KEY, validateEmail } from "../../../utils/utils";
import { useFormik } from "formik";

export const OnlineBooking = ({ pageData }) => {
  const { t } = useTranslation();
  const recaptchaRef = React.useRef();

  const [errorMsgNeeded, setErrorMsgNeeded] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [languageErr, setLanguageErr] = useState(false);
  const formik = useFormik({
    initialValues: {
      bookingType: "",
      date: {},
      emailAddress: "",
      fullName: "",
      paymentId: "",
      phoneNumber: "",
      site: {},
      time: {},
    },
    onSubmit: () => {},
    validate: () => {
      validate();
    },
  });

  const { data: languages } = useQuery(
    AppointmentApiQueryKeys.LANGUAGES,
    getAppointmentLanguages,
    {
      retry: 0,
      onError: () => {
        setErrorMsgNeeded(true);
        setLanguageErr(true);
      },
    }
  );

  const huLangId = languages
    ? _.find(languages.data, { code: "hu" })?.id
    : null;

  const { data: tasks } = useQuery(
    [AppointmentApiQueryKeys.TASKS_FOR_LANGUAGE, huLangId],
    getAppointmentTasksForLanguage,
    {
      enabled: !!huLangId,
    }
  );

  const reserve = async () => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const values = formik.values;
    const data = {};
    data.name = values.fullName;
    data.email = values.emailAddress;
    data.phone = values.phoneNumber;
    data.begin = values.time.begin;
    data.end = values.time.end;
    data.languageID = huLangId;
    data.branchID = values.site.id;
    data.taskID = values.bookingType;
    data.customerID = values.paymentId;
    let response = await reserveTimeSlot({ data: data, token: token });
    return response.status === 200;
  };

  const next = async () => {
    if (validate()) {
      if (pageNum + 1 === 5) {
        let status = await reserve();
        if (status) {
          setPageNum(pageNum + 1);
        } else {
          setErrorMsgNeeded(true);
          return;
        }
      }
      setErrorMsgNeeded(false);
      setPageNum(pageNum + 1);
    } else {
      setErrorMsgNeeded(true);
    }
  };

  const back = () => {
    setErrorMsgNeeded(false);
    setPageNum(pageNum - 1);
  };

  /**
   * Returns true if form is correct
   */
  const validate = () => {
    let formIsOk = true;
    formik.setErrors({});

    switch (pageNum) {
      case 0: {
        if (
          formik.values.bookingType == null ||
          formik.values.bookingType === ""
        ) {
          formik.setFieldError(
            "bookingType",
            "Kérjük, válasszon szolgáltatásaink közül!"
          );
          return false;
        }
        break;
      }
      case 1: {
        if (formik.values.site == null || formik.values.site.id == null) {
          formik.setFieldError(
            "site",
            "Kérjük, válasszon ügyfélszolgálati irodáink közül!"
          );
          return false;
        }
        break;
      }
      case 2: {
        if (formik.values.date == null || formik.values.date === {}) {
          formik.setFieldError("date", "Kérjük, válasszon napot!");
          return false;
        }
        if (
          formik.values.time == null ||
          formik.values.time.available == null
        ) {
          formik.setFieldError("time", "Kérjük, válasszon időpontot!");
          return false;
        }
        break;
      }
      case 3: {
        if (formik.values.fullName === "") {
          formik.setFieldError("fullName", "Kérjük, adja meg nevét!");
          formIsOk = false;
        }
        if (formik.values.emailAddress === "") {
          formik.setFieldError(
            "emailAddress",
            "Kérjük adja meg az e-mail címét!"
          );
          formIsOk = false;
        } else {
          if (!validateEmail(formik.values.emailAddress)) {
            formik.setFieldError("emailAddress", "E-mail formátum helytelen!");
            formIsOk = false;
          }
        }
        if (formik.values.phoneNumber === "") {
          formik.setFieldError(
            "phoneNumber",
            "Kérjük, adja meg telefonszámát!"
          );
          formIsOk = false;
        }

        if (formik.values.paymentId != null && formik.values.paymentId !== "") {
          if (
            formik.values.paymentId.toString().charAt(0) === "" ||
            formik.values.paymentId.toString().charAt(0) !== "5"
          ) {
            formik.setFieldError(
              "paymentId",
              "A fizetőazonosító mindig 5-ös számmal kezdődik!"
            );
            formIsOk = false;
          }
          if (formik.values.paymentId.toString().length !== 9) {
            formik.setFieldError(
              "paymentId",
              "A fizetőazonosító 9 számjegyből áll!"
            );
            formIsOk = false;
          }
        }
        break;
      }
    }

    return formIsOk;
  };

  //hogy mindig jo helyen legyenek a gombok.....
  let width = 1152;
  if (pageNum === 1) {
    width = 1100;
  }
  if (pageNum === 2) {
    width = 620;
  }
  if (pageNum === 3) {
    width = 590;
  }
  if (pageNum === 4) {
    width = 760;
  }

  return (
    <div className={"OnlineBooking"} style={{ maxWidth: width }}>
      <BreadcrumbFromPageData pageData={pageData} />
      <h1 className={"title primary-color"}>{t("onlineBooking.title")}</h1>

      {pageNum === 0 && (
        <OnlineBookingPageOne formik={formik} tasks={tasks?.data} />
      )}
      {pageNum === 1 && (
        <OnlineBookingPageTwo
          formik={formik}
          bookingType={formik.values.bookingType}
          langId={huLangId}
        />
      )}
      {pageNum === 2 && (
        <>
          <h4 className={"h4-title"}>
            Kérjük, válasszon a szabad időpontok közül.
          </h4>
          <OnlineBookingPageThree
            formik={formik}
            branchId={formik.values.site}
            taskId={formik.values.bookingType}
          />
        </>
      )}
      {pageNum === 3 && <OnlineBookingPageFour formik={formik} />}

      {pageNum === 4 && (
        <OnlineBookingPageFive
          personalDetails={{
            fullName: formik.values.fullName,
            paymentId: formik.values.paymentId,
            phoneNumber: formik.values.phoneNumber,
            emailAddress: formik.values.emailAddress,
            bookingType: tasks?.data?.find(
              (x) => x.id === formik.values.bookingType
            ).name,
            site: formik.values.site?.mappings.address,
            time: formik.values.time?.begin,
          }}
        />
      )}

      {pageNum === 5 && (
        <OnlineBookingConfirmationPage
          values={formik.values}
          tasks={tasks.data}
        />
      )}

      {errorMsgNeeded && (
        <div className={"error-msg-wrapper"}>
          {languageErr && (
            <span className={"error-color"}>
              {t("onlineBooking.languageError")}
            </span>
          )}
          {pageNum === 0 && !languageErr && (
            <span className={"error-color"}>
              {t("onlineBooking.pleaseChooseAService")}
            </span>
          )}
          {pageNum === 1 && (
            <span className={"error-color"}>
              {t("onlineBooking.pleaseChooseABranch")}
            </span>
          )}
          {pageNum === 2 && (
            <span className={"error-color"}>
              {t("onlineBooking.pleaseChooseADateAndTime")}
            </span>
          )}
          {pageNum === 4 && (
            <span className={"error-color"}>
              {t("onlineBooking.failedReserve")}
            </span>
          )}
        </div>
      )}

      <div className={"button-wrapper"}>
        {pageNum !== 0 && pageNum < 5 && (
          <Button color={"default"} onClick={back}>
            <ArrowBackIosIcon />
            {t("onlineBooking.back")}
          </Button>
        )}
        {pageNum < 5 && (
          <Button color={"default"} onClick={next}>
            {t("onlineBooking.next")}
            <ArrowForwardIosIcon />
          </Button>
        )}
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={GET_SITE_KEY()} size="invisible" />
    </div>
  );
};
