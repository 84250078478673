import React, { useEffect, useState } from "react";
import { ShrinkingHeaderWithContent } from "../../organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { useFormik } from "formik";
import { Button, TextField, useTheme } from "@mui/material";
import "./ElengedesPage.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { QueryClient } from "react-query";
import { getElengedesData } from "../../../api/elengedes/elengedes";
import { Breadcrumb } from "../../molecules/Breadcrumb/Breadcrumb";
import { getBackgroundByPageId } from "../../../utils/ResourceMapper";
import { useCookies } from "react-cookie";
import { ErrorNotification } from "../../molecules/ErrorNotification/ErrorNotification";
import { useTranslation } from "react-i18next";

const ELENGEDES_PAYMENT_ID = "elengedesPaymentId";
const ELENGEDES_TEHO_ID = "elengedesTehoId";

export const ElengedesPage = ({ pageData }) => {
  const [elengedesData, setElengedesData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [error, setError] = useState(null);
  const [serverError, setServerError] = useState(null);
  const theme = useTheme();
  const { t } = useTranslation();

  const [cookies, setCookie] = useCookies([]);

  const queryClient = new QueryClient();

  useEffect(() => {
    if (cookies[ELENGEDES_PAYMENT_ID] != null) {
      formik.setFieldValue("paymentId", cookies[ELENGEDES_PAYMENT_ID]);
    }
    if (cookies[ELENGEDES_TEHO_ID] != null) {
      formik.setFieldValue("tehoId", cookies[ELENGEDES_TEHO_ID]);
    }
  }, []);

  const onSubmit = async (values) => {
    const d = await queryClient
      .fetchQuery("elengedesData", () =>
        getElengedesData(values.paymentId, values.tehoId)
      )
      .catch(() => {
        setServerError(
          "Az elengedés kampány szolgáltatásunk jelenleg nem elérhető. Kérjük próbálja meg később."
        );
      });
    if (Array.isArray(d) && d.length > 0) {
      setNoData(false);
      setElengedesData(mapElengedesData(d));
      setCookie(ELENGEDES_PAYMENT_ID, formik.values.paymentId);
      setCookie(ELENGEDES_TEHO_ID, formik.values.tehoId);
    } else {
      if (d?.status != null && d?.status === 429) {
        setError(
          "A hibás próbálkozásainak száma elérte a maximálisan engedélyezett lekérdezések számát. Kérjük ellenőrizze a beírt fizetőazonosítót, terhelési összesítő számot és később próbálja újra."
        );
      } else {
        setNoData(true);
      }
    }
  };

  const mapElengedesData = (data) => {
    return [
      data.filter((d) => d.bemevho === "0000" || d.bemevho === "1000"),
      data.filter(
        (d) =>
          d.bemevho !== "0000" &&
          d.bemevho !== "1000" &&
          parseInt(d.bemevho) < 3000
      ),
      data.filter((d) => parseInt(d.bemevho) > 3000),
    ];
  };

  const validate = (values) => {
    let errors = {};

    const paymentError = validatePaymentId(values.paymentId);
    if (paymentError != null) {
      errors.paymentId = paymentError;
    }

    const tehoError = validateTehoId(values.tehoId);
    if (tehoError != null) {
      errors.tehoId = tehoError;
    }

    return errors;
  };

  const validatePaymentId = (id) => {
    if (!id || id === "") {
      return "Kérjük adja meg a fizetőazonosítót!";
    } else {
      if (id.toString().charAt(0) === "" || id.toString().charAt(0) !== "5") {
        return "A fizetőazonosító mindig 5-ös számmal kezdődik!";
      }
      if (id.toString().length !== 9) {
        return "A fizetőazonosító 9 számjegyből áll!";
      }
    }
    return null;
  };

  const validateTehoId = (id) => {
    if (!id || id === "") {
      return "Kérjük adja meg a TEHO azonosítóját!";
    } else {
      if (id.toString().length !== 9) {
        return "A TEHO azonosító 9 számjegyből áll!";
      }
    }
    return null;
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      paymentId: "",
      tehoId: "",
    },
    onSubmit: onSubmit,
    validate: validate,
  });

  const getYear = (data, index) => {
    if (index === 0) {
      return data.bemevho;
    }
    if (data.bemevho === elengedesData[1][index - 1].bemevho) {
      return "";
    } else {
      return data.bemevho;
    }
  };

  const numberWithCommas = (x) => {
    if (x == null) {
      return 0;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getKampannyalErintettTeljesHatralek = () => {
    let sum = 0;
    if (elengedesData[1] != null && elengedesData[1].length > 0) {
      sum += elengedesData[1]?.reduce(
        (partialSum, a) => partialSum + a.balance,
        0
      );
    }

    if (elengedesData[2] != null) {
      if (elengedesData[2][0] != null) {
        sum += elengedesData[2][0].balance;
      }
      if (elengedesData[2][1] != null) {
        sum += elengedesData[2][1].balance;
      }
    }

    if (elengedesData[0] != null) {
      if (elengedesData[0][0] != null) {
        sum += elengedesData[0][0].balance;
      }
      if (elengedesData[0][1] != null) {
        sum += elengedesData[0][1].balance;
      }
    }
    return sum;
  };

  const getElerhetoKedvezmenyekOsszege = () => {
    let sum = 0;
    if (elengedesData[0] != null) {
      if (elengedesData[0][1] != null) {
        sum += elengedesData[0][1].balance;
      }
      if (elengedesData[0][0] != null) {
        sum += elengedesData[0][0].balance;
      }
    }
    return sum;
  };

  const getKoltsegekOsszesen = () => {
    let sum = 0;
    if (elengedesData[0] != null && elengedesData[0][1] != null) {
      sum += elengedesData[0][1].balance;
    }
    return sum;
  };

  const getKesedelmiKamatokOsszesen = () => {
    let sum = 0;
    if (elengedesData[0] != null && elengedesData[0][0] != null) {
      sum += elengedesData[0][0].balance;
    }
    return sum;
  };

  const getFizetendoOsszesen = () => {
    let sum = 0;
    if (elengedesData[1] != null) {
      sum += elengedesData[1]?.reduce(
        (partialSum, a) => partialSum + a.balance,
        0
      );
    }
    if (elengedesData[2] != null) {
      if (elengedesData[2][1] != null) {
        sum += elengedesData[2][1].balance;
      }
      if (elengedesData[2][0] != null) {
        sum += elengedesData[2][0].balance;
      }
    }
    return sum;
  };

  const getKesedelmiKamatokOsszesen2 = () => {
    let sum = 0;
    if (elengedesData[2] != null && elengedesData[2][0] != null) {
      sum += elengedesData[2][0].balance;
    }
    return sum;
  };

  const getKoltsegekOsszesen2 = () => {
    let sum = 0;
    if (elengedesData[2] != null && elengedesData[2][1] != null) {
      sum += elengedesData[2][1].balance;
    }
    return sum;
  };

  const resetServerError = () => {
    setNoData(false);
    setServerError(null);
  };

  return (
    <div className={"ElengedesPage"}>
      <Breadcrumb elements={[]} />
      <ShrinkingHeaderWithContent
        text={"Elengedés kampány"}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <div className={"content-inner"}>
            <div className={"elengedes-form"}>
              <div className={"form-box"}>
                <TextField
                  id={"paymentId"}
                  name={"paymentId"}
                  color={"primary"}
                  label={"Fizetőazonosító*"}
                  variant={"standard"}
                  fullWidth={true}
                  inputProps={{ required: true, maxLength: 9 }}
                  value={formik.values.paymentId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.paymentId != null}
                  helperText={formik.errors.paymentId}
                />
                <TextField
                  id={"tehoId"}
                  name={"tehoId"}
                  color={"primary"}
                  label={"Terhelési összesítő száma**"}
                  variant={"standard"}
                  fullWidth={true}
                  inputProps={{ required: true, maxLength: 9 }}
                  value={formik.values.tehoId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.tehoId != null}
                  helperText={formik.errors.tehoId}
                />
              </div>
              <Button color={"default"} onClick={formik.submitForm}>
                {t("elengedes.send")}
                <ArrowForwardIosIcon />
              </Button>
              <div className={"stars"}>
                <small>{t("elengedes.star")}</small> <br />
                <br />
                <small>{t("elengedes.2star")}</small>
              </div>
            </div>
            {error && (
              <div className={"error"}>
                <h4 className={"error-color"}>{error}</h4>
              </div>
            )}
            {noData && !serverError && (
              <div className={"no-data"}>
                <h4 className={"error-color"}>{t("elengedes.noData")}</h4>
              </div>
            )}
            <div className={"elengedes-data"}>
              {elengedesData.length > 0 && (
                <>
                  <div className={"top"}>
                    <h3 className={"address"}>
                      {t("elengedes.consumerAddress") + ": "}
                      {elengedesData[0][0]?.consumerAddress != null
                        ? elengedesData[0][0]?.consumerAddress
                        : ""}
                    </h3>
                    <div className={"space"} />
                    <table>
                      <tbody>
                        <tr>
                          <th colSpan={2} className={"double"}>
                            {t("elengedes.fullRemainderWithCampaign")}
                          </th>
                          <th className={"right"}>
                            {numberWithCommas(
                              getKampannyalErintettTeljesHatralek()
                            ) + " "}
                            Ft
                          </th>
                        </tr>

                        <tr className={"space"} />
                        <tr>
                          <th colSpan={3}>
                            {t("elengedes.discountWithCampaign")}
                          </th>
                        </tr>
                        <tr>
                          <td colSpan={2}>{t("elengedes.allLateCharges")}</td>
                          <td className={"right"}>
                            {numberWithCommas(getKesedelmiKamatokOsszesen())} Ft
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>{t("elengedes.allExpenses")}</td>
                          <td className={"right"}>
                            {numberWithCommas(getKoltsegekOsszesen())} Ft
                          </td>
                        </tr>
                        <tr className={"space"} />
                        <tr>
                          <td className={"error-color"} colSpan={2}>
                            {t("elengedes.allAvailableDiscount")}
                          </td>
                          <td className={"right error-color"}>
                            <b>
                              {numberWithCommas(
                                getElerhetoKedvezmenyekOsszege()
                              )}{" "}
                              Ft
                            </b>
                          </td>
                        </tr>
                        <tr className={"space"} />
                        <tr>
                          <th className={"line"} colSpan={3}>
                            {t("elengedes.allPayableItems")}
                          </th>
                        </tr>

                        <tr className={"space"} />

                        <tr>
                          <th>{t("elengedes.year")}</th>
                          <th>{t("elengedes.provider")}</th>
                          <th>{t("elengedes.remainder")}</th>
                        </tr>

                        <>
                          {elengedesData[1]?.map((e, index) => {
                            return (
                              <tr key={index}>
                                <td>{getYear(e, index)}</td>
                                <td>{e.serviceName}</td>
                                <td className={"right"}>
                                  {numberWithCommas(e.balance)} Ft
                                </td>
                              </tr>
                            );
                          })}
                        </>

                        <tr className={"space"} />

                        <tr>
                          <td colSpan={2}>{t("elengedes.allInAll")}</td>
                          <td className={"right"}>
                            <b>
                              {numberWithCommas(
                                elengedesData[1]?.reduce(
                                  (partialSum, a) => partialSum + a.balance,
                                  0
                                )
                              )}{" "}
                              Ft
                            </b>
                          </td>
                        </tr>

                        <tr className={"space"} />
                        <tr>
                          <td colSpan={2}>{t("elengedes.allLateCharges")}</td>
                          <td className={"right"}>
                            {numberWithCommas(getKesedelmiKamatokOsszesen2())}{" "}
                            Ft
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>{t("elengedes.allExpenses")}</td>
                          <td className={"right"}>
                            {numberWithCommas(getKoltsegekOsszesen2())} Ft
                          </td>
                        </tr>
                        <tr className={"space"} />
                        <tr>
                          <td colSpan={2}>{t("elengedes.allPayable")}</td>
                          <td className={"right"}>
                            <b>{numberWithCommas(getFizetendoOsszesen())} Ft</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={"info"}>
                    <h4>
                      A befizetendő összeget online befizetheti a{" "}
                      <a
                        href={`/bankkartyas-fizetes?paymentId=${formik.values.paymentId}&tehoId=${formik.values.tehoId}`}
                      >
                        Bankkártyás
                      </a>{" "}
                      fizetés menüpontban.
                    </h4>
                    <h5>{t("elengedes.info1")}</h5>
                    <h5>{t("elengedes.info2")}</h5>
                  </div>
                </>
              )}
            </div>
          </div>
        }
      />
      {serverError && (
        <ErrorNotification
          open={!!serverError}
          onClose={resetServerError}
          message={serverError}
        />
      )}
    </div>
  );
};
