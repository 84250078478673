import React from "react";
import {
  getBackgroundByPageId,
  getIconByContentId,
} from "utils/ResourceMapper";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { useTheme } from "@mui/material";
import DOMPurify from "dompurify";
import "./BefizetesModjaiTemplate.scss";
import { BreadcrumbFromPageData } from "../../molecules/Breadcrumb/BreadcrumbFromPageData";
import { getClassNameByColor } from "../../../utils/utils";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";

export const BefizetesModjaiTemplate = ({ pageData }) => {
  const theme = useTheme();
  const color = getClassNameByColor(theme.palette.primary.main);

  return (
    <div className={"BefizetesModjaiTemplate"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={
          "/" + (pageData.parentPage != null ? pageData.parentPage.url : "")
        }
        text={pageData.subPageAddress}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <div className={"content-wrapper"}>
            {pageData.content.map((c, index) => {
              let cn =
                index < pageData.content.length - 1 ? "lower-border" : "";
              return (
                <>
                  <div className={"header"}>
                    <div
                      className={"icon"}
                      style={{
                        width: "48px",
                        height: "48px",
                        backgroundImage: `url(${getIconByContentId(c.id)})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <h2>{c.address}</h2>
                  </div>
                  <div
                    className={cn + " " + color}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(c.text),
                    }}
                  />
                </>
              );
            })}
          </div>
        }
      />
      <BlueBanner />
    </div>
  );
};
