import { HomeHeader } from "components/pages/Home/HomeHeader/HomeHeader";
import { SubpageSelector } from "components/pages/Home/SubpageSelector/SubpageSelector";
import { Actualities } from "components/pages/Home/Actualities/Actualities";
import { PageLayout } from "components/molecules/PageLayout/PageLayout";
import "./Home.scss";
import { PageFooter } from "components/fragments/PageFooter/PageFooter";
import { SimpleBottomBoxes } from "components/pages/Home/SimpleBottomBoxes/SimpleBottomBoxes";
import { ScrollToTop } from "../../atoms/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";

export const Home = () => {
  return (
    <div className={"Home"}>
      <Helmet>
        <title>Főoldal | DBRT</title>
      </Helmet>
      <PageLayout
        header={<HomeHeader />}
        content={
          <>
            <SubpageSelector />
            <Actualities />
            <SimpleBottomBoxes />
          </>
        }
        footer={<PageFooter />}
      />
      <ScrollToTop />
    </div>
  );
};
