import { GradientTile } from "components/organisms/GradientTile/GradientTile";
import "./SubpageSelector.scss";
import { Paths } from "routing/Paths";

export const SubpageSelector = () => {
  return (
    <div className={"SubpageSelector"}>
      <GradientTile
        color={"purple"}
        navigateTo={Paths.RESIDENTAL}
        text={"Lakossági ügyfél"}
        backgroundUrl={"sources/pics/lakossagi_ugyfel_c.jpg"}
      />
      <GradientTile
        color={"orange"}
        navigateTo={Paths.NON_RESIDENTAL}
        text={"Nem lakossági ügyfél"}
        backgroundUrl={"sources/pics/nem_lakossagi_v.jpg"}
      />
      <GradientTile
        color={"green"}
        navigateTo={Paths.CONDOMINIUM_MANAGER}
        text={"Társasházkezelő"}
        backgroundUrl={"sources/pics/tarsashazkezelo_c.jpg"}
      />
    </div>
  );
};
