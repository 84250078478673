import { SubpageHeader } from "components/fragments/SubpageHeader/SubpageHeader";
import { PageLayout } from "components/molecules/PageLayout/PageLayout";
import { PageFooter } from "components/fragments/PageFooter/PageFooter";
import { Menu } from "components/fragments/Menu/Menu";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

export const Subpage = ({ className, content }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(null);
  const sm = useMediaQuery("(max-width: 800px)");

  useEffect(() => {
    setIsMenuOpened(!sm);
  }, [sm]);

  const menuItemClicked = () => {
    setIsMenuOpened(!sm);
  };

  return (
    <div className={className}>
      <PageLayout
        header={
          <SubpageHeader
            isMenuOpened={isMenuOpened}
            onMenuClick={(value) => setIsMenuOpened(value)}
          />
        }
        content={content}
        footer={<PageFooter />}
        menu={<Menu isOpened={isMenuOpened} click={menuItemClicked} />}
      />
    </div>
  );
};
