import React from "react";
import PropTypes from "prop-types";
import "./ActualityCard.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ActualityCard = ({ title, text, navigateTo, borderNeeded }) => {
  const { t } = useTranslation();
  return (
    <div className={"ActualityCard" + (borderNeeded ? " border-needed" : "")}>
      <div className={"title-wrapper"}>
        <h4>{title}</h4>
      </div>
      <div className={"text-wrapper"}>
        <div>{text}</div>
          <Link to={navigateTo} className={"navigation"}>
              <b>{t("components.actualities.more").toUpperCase()}</b>
          </Link>
      </div>
    </div>
  );
};

ActualityCard.propTypes = {
  /**
   * Title of the component
   */
  title: PropTypes.string,
  /**
   * Text of the component
   */
  text: PropTypes.object,
  /**
   * Navigate here on clickink "more"
   */
  navigateTo: PropTypes.string,
  /**
   * If true, there will be a visible bottom-border
   */
  borderNeeded: PropTypes.bool,
};
