/* eslint-disable */
export const validateEmail = (emailToValidate) => {
  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(emailToValidate);
};

export const getClassNameByColor = (color) => {
  let className;
  switch (color) {
    case "#7cb342":
      className = "green";
      break;
    case "#a8216b":
      className = "purple";
      break;
    case "#ffa000":
      className = "orange";
      break;
    default:
      className = "blue";
      break;
  }
  return className;
};

export const GET_SITE_KEY = () => {
  if (process.env.REACT_APP_SITE_KEY) {
    return process.env.REACT_APP_SITE_KEY;
  }
  return SITE_KEY;
};

export const sanitizeNumberInputFromEvent = (e) => {
  e.target.value = e.target.value.replace(/\D/g, "");
};

//Dorsum key
// export const SITE_KEY = "6LcOPGYeAAAAAEBRCNuCJcUHaMOA8LIEBHZXHRWE";

// DBRT key
export const SITE_KEY = "REACT_APP_RECAPTCHA_SITE_KEY";
