import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./BigNavigationBox.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import style from "style/variables.module.scss";

export const BigNavigationBox = ({
  navigateTo,
  backgroundImage,
  color,
  mainText,
  buttonText,
  icon,
}) => {
  const { t } = useTranslation();
  let bgi = null;
  let bgc = null;
  if (backgroundImage != null && backgroundImage !== "/resources/") {
    bgi = {
      backgroundImage: `url(${backgroundImage})`,
    };
  } else {
    bgc = {
      backgroundColor: style.dirtyWhite,
    };
  }

  if (mainText === "E-számla e-mailben") {
    mainText = (
      <div>
        E-számla <br /> e-mailben
      </div>
    );
  }

  if (mainText === "E-számla Díjneten") {
    mainText = (
      <div>
        E-számla <br /> Díjneten
      </div>
    );
  }

  return (
    <Link className={"BigNavigationBox"} to={navigateTo} style={bgc}>
      <div className={"bg"} style={bgi} />
      <div className={"big-navigation-box"}>
        <div className={"desktop"}>
          <div className={"top-container"}>
            <div className={`icon-container ${color}-background-color`}>
              {icon}
            </div>
          </div>
        </div>
        <div className={"mobile"}>
          <div className={`top-container ${color}-color`}>{icon}</div>
        </div>
        <h2>{mainText}</h2>
        <div className={"button-container"}>
          <div className={"desktop"}>
            <Button variant="contained">
              {buttonText != null && buttonText !== ""
                ? buttonText
                : t("components.articleTile.more")}
            </Button>
          </div>
          <div className={"mobile"}>
            <Button
              variant="contained"
              color={color}
              sx={{
                borderRadius: "50%",
                minWidth: "32px",
                maxWidth: "32px",
                maxHeight: "32px",
              }}
            >
              <ArrowForwardIosIcon sx={{ width: "24px", height: "24px" }} />
            </Button>
          </div>
        </div>
      </div>
    </Link>
  );
};

BigNavigationBox.propTypes = {
  /** navigate to this URL */
  navigateTo: PropTypes.string,

  /** image that will be the background */
  backgroundImage: PropTypes.string,

  /** icon that will be in the middle of the circle
  needs to be a materialUI icon object */
  icon: PropTypes.object,

  /** color of the circle and button */
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "warning",
    "info",
    "success",
  ]),

  /** text that will be displayed in the middle of the component */
  mainText: PropTypes.string,

  /** text that will be displayed in the button */
  buttonText: PropTypes.string,
};
