import DateIOAdapter from "@mui/lab/AdapterMoment";

export default function DatePickerAdapter(options) {
    const adapter = new DateIOAdapter(options);

    const constructUpperObject = (text) => ({ toUpperCase: () => text });
    const constructDayObject = (day) => ({
        charAt: () => constructUpperObject(day)
    });

    return {
        ...adapter,
        getWeekdays() {
            const customWeekdays = ["H", "K", "Sze", "Cs", "P", "Szo", "V"];
            return customWeekdays.map((day) => constructDayObject(day));
        }
    };
}