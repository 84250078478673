import React from "react";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { PropTypes } from "prop-types";
import "./SimpleTemplate.scss";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import { useTheme } from "@mui/material";
import { getBackgroundByPageId } from "utils/ResourceMapper";

import { getClassNameByColor } from "../../../utils/utils";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";
import { CareerBanner } from "../../careerBanner/CareerBanner";

export const SimpleTemplate = ({ pageData }) => {
  const theme = useTheme();
  const cmsPagesBanner = [52, 53, 166];
  const color = getClassNameByColor(theme.palette.primary.main);

  return (
    <div className={"SimpleTemplate"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={
          pageData.parentPage?.url != null ? `/${pageData.parentPage.url}` : "/"
        }
        text={pageData.subPageAddress}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <>
            {pageData.content.map((content, index) => {
              return (
                <div key={index} className={"content-" + index}>
                  {/* KIVETTEM A SANITIZE-T a cégtörténetes iframe, meg youtube miatt, DE EZ ELÉG ROSSZ ÖTLET
                    __html: DOMPurify.sanitize(content.text),*/}
                  <div
                    className={color}
                    dangerouslySetInnerHTML={{
                      __html: content.text,
                    }}
                  />
                </div>
              );
            })}
          </>
        }
      />
      {pageData &&
        (pageData.parentPage?.subPageId !== "38" ||
          cmsPagesBanner.includes(pageData.id)) && <BlueBanner />}
      {pageData &&
        pageData.parentPage &&
        pageData.parentPage.subPageId === "38" && (
          <div className={"banner"}>
            <CareerBanner />
          </div>
        )}
    </div>
  );
};

SimpleTemplate.propTypes = {
  /**
   * {
   *   navigation,
   *   headerText,
   *   backgroundImage,
   *   color,
   *   subpageCim,
   *   content: [
   *     cim,
   *     szoveg
   *   ]
   * }
   */
  pageData: PropTypes.object,
};
