import React, { useEffect, useState } from "react";
import DatePicker from "@mui/lab/DatePicker";
import { Radio, TextField } from "@mui/material";
import moment from "moment";
import { getEmptySlots } from "api/appointment/Appointment";
import { PickersDay } from "@mui/lab";
import DatePickerAdapter from "components/atoms/DatePickerAdapter/DatePickerAdapter";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { styled } from "@mui/material/styles";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "weekEndDays" || prop !== "today",
})(({ theme, weekEndDays, today }) => ({
  ...((weekEndDays || today) && {
    backgroundColor: "#f6f6f6",
    color: "#454545",
    pointerEvents: "none",
    opacity: ".35",
    margin: "1px",
  }),
  ...(!weekEndDays && {
    backgroundColor: "#f6f6f6",
    "&:hover, &:focus": {
      backgroundColor: "#ededed",
    },
    color: "#454545",
    margin: "1px",
  }),
}));

const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
  const weekEndDays = date.day() === 6 || date.day() === 0;
  const today = date.isSame(new Date(), "day");

  return (
    <CustomPickersDay
      {...pickersDayProps}
      weekEndDays={weekEndDays}
      today={today}
    />
  );
};

/* eslint-disable */
export const OnlineBookingPageThree = ({ branchId, taskId, formik }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [emptySlots, setEmptySlots] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    formik.setFieldValue("date", null);
  }, []);

  const loadEmptySlots = async (date) => {
    const tmrrw = new Date(date);
    tmrrw.setDate(date.getDate() + 1);
    const dataToSend = {
      begin: date,
      end: tmrrw,
      branchID: branchId.id,
      taskID: taskId,
    };
    const data = await getEmptySlots(dataToSend);
    let es = data.data;
    es.forEach((slot) => {
      slot.begin = new Date(slot.begin);
      slot.end = new Date(slot.end);
    });
    setEmptySlots(es);
  };

  const getTimeFromFullDate = (dt) => {
    let h = dt.getHours();
    if (h.toString().length === 1) {
      h = "0" + h;
    }

    let m = dt.getMinutes();
    if (m.toString().length === 1) {
      m = "0" + m;
    }

    return h + ":" + m;
  };

  return (
    <div className={"OnlineBookingPageThree"}>
      <div className={"date-picker"}>
        <LocalizationProvider
          dateAdapter={DatePickerAdapter}
          locale={"huLocale"}
        >
          <DatePicker
            onClose={() => setOpen(false)}
            mask={"____.__.__."}
            value={formik.values.date}
            open={open}
            onChange={(date) => {
              const dt = new Date(date);
              formik.setFieldValue("date", dt);
              loadEmptySlots(dt);
              setOpen(false);
            }}
            inputProps={{
              placeholder: "Dátum megadása",
            }}
            renderInput={(params) => (
              <TextField {...params} onClick={() => setOpen(!open)} />
            )}
            minDate={moment(tomorrow)}
            disableHighlightToday={true}
            toolbarTitle="Kiválasztott dátum"
            renderDay={renderWeekPickerDay}
            labelFunc={(date) => (date ? date.format("YYYY/MM/DD") : "")}
            disablePast={true}
            toolbarFormat={"YYYY-MM-DD"}
          />
        </LocalizationProvider>
      </div>
      <div className={"hour-picker"}>
        {emptySlots.map((availableTime, index) => {
          if (!availableTime.available) {
            return null;
          }
          return (
            <div className={"radio-wrapper"} key={index}>
              <Radio
                onClick={() => formik.setFieldValue("time", availableTime)}
                checked={availableTime?.begin === formik.values.time?.begin}
                name="radio-button"
                inputProps={{ "aria-label": "A" }}
              />
              <p>{getTimeFromFullDate(availableTime.begin)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
