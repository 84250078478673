import React from "react";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { PropTypes } from "prop-types";
import DOMPurify from "dompurify";
import "./Complaint.scss";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import { useTheme } from "@mui/material";
import { getBackgroundByPageId } from "utils/ResourceMapper";
import { ContactUsForm } from "components/fragments/ContactUsForm/ContactUsForm";
import { useTranslation } from "react-i18next";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";

export const Complaint = ({ pageData }) => {
  const theme = useTheme();

  const { t } = useTranslation();
  return (
    <div className={"Complaint"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={"/"}
        text={pageData.subPageAddress}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <>
            {pageData.content != null && pageData.content.length > 0 && (
              <div className={"upper-box"}>
                <div>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(pageData.content[0].address),
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(pageData.content[0].text),
                    }}
                  />
                </div>
                <div>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(pageData.content[1].address),
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(pageData.content[1].text),
                    }}
                  />
                </div>
              </div>
            )}
            {pageData?.content.map((content, index) => {
              if (index < 2) {
                return null;
              }
              return (
                <div key={index} className={"content-" + index}>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.address),
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.text),
                    }}
                  />
                </div>
              );
            })}
            <h4>{t("writeToUs.giveUsDetails")}</h4>
            <h5 className="form-mandatory-text">{t("writeToUs.mandatory")}</h5>
            <ContactUsForm complaint={true} />
            <BlueBanner />
          </>
        }
      />
    </div>
  );
};

Complaint.propTypes = {
  pageData: PropTypes.object,
};
