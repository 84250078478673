import "./SubpageHeader.scss";
import { Header } from "components/organisms/Header/Header";
import dbrtLogo from "resources/dbrt-logo.png";
import { Paths } from "routing/Paths";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { SearchBox } from "components/molecules/SearchBox/SearchBox";
import { useMediaQuery, useTheme } from "@mui/material";

export const SubpageHeader = ({ isMenuOpened, onMenuClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={"SubpageHeader"}>
      <div className={"header"}>
        <Header
          left={
            <div
              onClick={() => onMenuClick(!isMenuOpened)}
              className={"menu-icon"}
            >
              {isMenuOpened ? <CloseIcon /> : <MenuIcon />}
              <span>{sm ? t("subpage.header.menu") : ""}</span>
            </div>
          }
          middle={
            <Link to={Paths.HOME}>
              <img src={dbrtLogo} alt={"logo"} />
            </Link>
          }
          right={<SearchBox />}
        />
      </div>
      <div className={"header-placeholder"} style={{ paddingTop: "64px" }} />
    </div>
  );
};
