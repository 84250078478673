import { ArticleTile } from "components/organisms/ArticleTile/ArticleTile";
import "./SimpleBottomBoxes.scss";

export const SimpleBottomBoxes = () => {
  return (
    <div className="BottomBoxes">
      <ArticleTile
        navigateTo={"rolunk"}
        icon={
          <img alt={"rolunk"} src={"sources/pics/ikonok/rolunk_ikon.png"} />
        }
        iconText="Rólunk"
        mainColor={"#0097A7"}
        mainText={
          "Részletek a társaságunkról, vezetőségről és a szervezeti felépítésről."
        }
      />
      <ArticleTile
        navigateTo={"karrier"}
        icon={
          <img alt={"karrier"} src={"sources/pics/ikonok/karrier_ikon.png"} />
        }
        iconText="Karrier"
        mainColor={"#0097A7"}
        mainText={"Aktuális, betöltetlen pozícióink és munkalehetőségeink."}
      />
    </div>
  );
};
