import { PictureTile } from "components/organisms/PictureTile/PictureTile";
import "./Career.scss";
import { Paths } from "routing/Paths";

export const Career = () => {
  return (
    <div className={"Career"}>
      <PictureTile
        navigateTo={Paths.WORKATUS}
        text={"Miért jó nálunk dolgozni?"}
        backgroundUrl={"sources/pics/karrier_1.jpg"}
      />
      <PictureTile
        navigateTo={Paths.JOBOFFERS}
        text={"Állásajánlataink"}
        backgroundUrl={"sources/pics/karrier_2.jpg"}
      />
      <PictureTile
        navigateTo={Paths.COLLEAGUESCOMMENTS}
        text={"Kollégáink véleménye"}
        backgroundUrl={"sources/pics/karrier_3.jpg"}
      />
      <PictureTile
        navigateTo={Paths.EVENTS}
        text={"Közösségi események"}
        backgroundUrl={"sources/pics/karrier_4.jpg"}
      />
    </div>
  );
};
