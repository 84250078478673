import { createTheme } from "@mui/material";
import styles from "style/variables.module.scss";

export const DefaultTheme = createTheme({
  palette: {
    primary: {
      main: styles.primary,
    },
    secondary: {
      main: styles.secondary,
    },
    error: {
      main: styles.error,
    },
    warning: {
      main: styles.warning,
    },
    info: {
      main: styles.info,
    },
    success: {
      main: styles.success,
    },
    default: {
      main: styles.primary,
    },
  },
});
