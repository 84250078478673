import { ShrinkingHeader } from "components/molecules/ShrinkingHeader/ShrinkingHeader";
import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import "./ShrinkingHeaderWithContent.scss";

export const ShrinkingHeaderWithContent = ({
  navigateTo,
  text,
  backgroundImage,
  color,
  content,
}) => {
  const [state, setState] = useState({
    shrunk: window.scrollY !== 0,
    width: 960,
  });

  useEffect(() => {
    const resizeHandle = () => {
      setState({
        shrunk: state.shrunk,
        width: getContentOffset(),
      });
    };
    window.addEventListener("resize", resizeHandle);
    return () => window.removeEventListener("resize", resizeHandle);
  }, []);

  useEffect(() => {
    const scrollHandle = () => {
      setState({
        shrunk: window.scrollY > 0,
        width: getContentOffset(),
      });
    };
    window.addEventListener("scroll", scrollHandle);
    return () => window.removeEventListener("scroll", scrollHandle);
  }, []);

  useEffect(() => {
    const element = document.getElementById("content");
    setState({
      shrunk: state.shrunk,
      width: element.offsetWidth,
    });
  }, []);

  return (
    <div
      className={"ShrinkingHeaderWithContent"}
      style={{ width: "inherit", maxWidth: "inherit" }}
    >
      <ShrinkingHeader
        navigateTo={navigateTo}
        text={text}
        backgroundImage={backgroundImage}
        color={color}
        shrunk={state.shrunk}
        width={state.width}
      />
      <div id={"content"} className={"content"}>
        {content}
      </div>
    </div>
  );
};

const getContentOffset = () => {
  const element = document.getElementById("content");
  if (element != null) {
    return element.offsetWidth;
  }
  return 960;
};

ShrinkingHeaderWithContent.propTypes = {
  /**
   * Path to navigate to on clinking the icon on the right upper corner.
   */
  navigateTo: PropTypes.string,
  /**
   * Text that will be displayed on that shrinking header .
   */
  text: PropTypes.string,
  /**
   * Image that will be in the background of the header, below the color.
   */
  backgroundImage: PropTypes.string,
  /**
   * The transitional color of the header. Needs to be rgb(), rgba(), or hex.
   */
  color: PropTypes.string,
  /**
   * The content that will be displayed under the header. Needs to be JSX object.
   */
  content: PropTypes.object,
};
