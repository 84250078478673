import { SimpleTemplate } from "components/templates/SimpleTemplate/SimpleTemplate";
import { TileGrid } from "components/templates/TileGrid/TileGrid";
import { PaymentPageTemplate } from "../components/templates/PaymentPageTemplate/PaymentPageTemplate";
import { OnlineBooking } from "../components/templates/OnlineBooking/OnlineBooking";
import { MeterReading } from "../components/pages/MeterReading/MeterReading";
import { WriteToUs } from "../components/pages/WriteToUs/WriteToUs";
import { MultipleCollapsibleInfoPage } from "../components/templates/MultipleCollapsibleInfoPage/MultipleCollapsibleInfoPage";
import { FoglalasTorlesTemplate } from "../components/templates/FoglalasTorlesTemplate/FoglalasTorlesTemplate";
import { FAQTemplate } from "../components/templates/FAQTemplate/FAQTemplate";
import { SiteMap } from "../components/templates/SiteMap/SiteMap";
import { Ugyfelfogadas } from "../components/templates/Ugyfelfogadas/Ugyfelfogadas";
import { FogalomtarTemplate } from "../components/templates/FogalomtarTemplate/FogalomtarTemplate";
import { Complaint } from "../components/pages/Complaint/Complaint";
import { Career } from "../components/pages/Career/Career";
import { BefizetesModjaiTemplate } from "../components/templates/BefizetesModjaiTemplate/BefizetesModjaiTemplate";
import { DownloadableForms } from "../components/pages/DownloadableForms/DownloadableForms";
import { ServiceCharges } from "../components/pages/ServiceCharges/ServiceCharges";
import { Partners } from "../components/pages/Partners/Partners";
import { JobsTemplate } from "../components/templates/JobsTemplate/JobsTemplate";
import { EAccountPage } from "../components/pages/EAccountPage/EAccountPage";
import { HivjonTemplate } from "../components/templates/HivjonTemplate/HivjonTemplate";
import { EAccountEmailTemplate } from "../components/templates/EAccountEmailTemplate/EAccountEmailTemplate";
import { ElengedesPage } from "../components/pages/ElengedesPage/ElengedesPage";

const templates = {
  "templates/sub_page_templates/dbrt_alberlet.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_cegtortenet.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_celjaink.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_eszamla.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_fizetes_valtozas.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_hivjon.php": HivjonTemplate,
  "templates/sub_page_templates/dbrt_mero_csere.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_nev_valtozas.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_partnereink.php": Partners,
  "templates/sub_page_templates/dbrt_szamla_kimutat.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_szamlaval_kapcs.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_szolg_dijak.php": ServiceCharges,
  "templates/sub_page_templates/dbrt_tarsadalmi.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_ugyfelszolg.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_vezetoseg.php": SimpleTemplate,
  "templates/sub_page_templates/egyszeru_aloldal.php": SimpleTemplate,
  "templates/sub_page_templates/dbrt_lakast.php": SimpleTemplate,
  "templates/sub_page_templates/letoltheto.php": DownloadableForms,
  "templates/sub_page_templates/idopont.php": TileGrid,
  "templates/sub_page_templates/template_1.php": TileGrid,
  "templates/sub_page_templates/valtozasok.php": TileGrid,
  "templates/sub_page_templates/tudnivalok.php": TileGrid,
  "templates/sub_page_templates/ugyintezes.php": TileGrid,
  "templates/sub_page_templates/rolunk.php": TileGrid,
  "templates/sub_page_templates/dbrt_online_fizetes.php": PaymentPageTemplate,
  "templates/sub_page_templates/dbrt_idopontfoglalas.php": OnlineBooking,
  "templates/sub_page_templates/dbrt_meroallas_bejelentes.php": MeterReading,
  "templates/sub_page_templates/dbrt_irjon.php": WriteToUs,
  "templates/sub_page_templates/dbrt_mit_hol.php": MultipleCollapsibleInfoPage,
  "templates/sub_page_templates/befizetes.php": BefizetesModjaiTemplate,
  "templates/sub_page_templates/dbrt_allasok.php": JobsTemplate,
  "templates/sub_page_templates/dbrt_kiegyenlitett_szamlak.php":
    MultipleCollapsibleInfoPage,
  "templates/sub_page_templates/e_szamla_csempek.php": TileGrid,
  "templates/sub_page_templates/dbrt_eszamla_cikk_kerdes.php":
    EAccountEmailTemplate,
  "templates/sub_page_templates/dbrt_foglalas.php": FoglalasTorlesTemplate,
  "templates/sub_page_templates/dbrt_gyik.php": FAQTemplate,
  "templates/sub_page_templates/oldalterkep.php": SiteMap,
  "templates/sub_page_templates/ugyfelfogadas.php": Ugyfelfogadas,
  "templates/sub_page_templates/szamlakezbesites.php": BefizetesModjaiTemplate,
  "templates/sub_page_templates/dbrt_panaszkezel.php": Complaint,
  "templates/sub_page_templates/karrier.php": Career,
  "templates/sub_page_templates/fogalomtar.php": FogalomtarTemplate,
  "templates/sub_page_templates/dbrt_e_szamla_igenyles.php": EAccountPage,
  "templates/sub_page_templates/dbrt_eszamla_form.php": EAccountPage,
  "templates/sub_page_templates/dbrt_dijnet.php": SimpleTemplate,
  "templates/sub_page_templates/elengedes.php": ElengedesPage,
};

export const getTemplateComponent = (phpPath) => {
  if (!phpPath) {
    return SimpleTemplate;
  } else {
    return templates[phpPath] || (() => <div>Missing template: {phpPath}</div>);
  }
};
