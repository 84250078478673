import React, { useState } from "react";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import "./Ugyfelfogadas.scss";
import DOMPurify from "dompurify";
import { Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Link } from "react-router-dom";
import { getBackgroundByPageId } from "utils/ResourceMapper";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";

export const Ugyfelfogadas = ({ pageData }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [content] = useState(_.orderBy(_.cloneDeep(pageData.content), "order"));

  return (
    <div className={"Ugyfelfogadas"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        text={pageData.address}
        navigateTo={null}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <div className={"cards"}>
            {content != null && content[0] != null && (
              <div
                className="title-text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(content[0].text),
                }}
              />
            )}
            {content != null &&
              content.map((c, index) => {
                if (index === 0 || c.address == null || c.address === "") {
                  return null;
                }
                return (
                  <div
                    className={
                      "card-wrapper " + (index % 2 === 0 ? "even" : "odd")
                    }
                    key={index}
                  >
                    <img
                      src={"sources/pics/cikk_ikonok/hely_kicsi_sarga.png"}
                      alt={"hely_kicsi_sarga"}
                    />
                    <div
                      className={"card"}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(c.text),
                      }}
                    />
                    <Link to={"/idopont-foglalas"}>
                      <Button
                        style={{
                          backgroundColor: theme.palette.default.main,
                          color: "#fff",
                        }}
                      >
                        {t("onlineBooking.title")}
                      </Button>
                    </Link>
                  </div>
                );
              })}
          </div>
        }
      />
      <iframe
        src="https://www.google.com/maps/d/u/1/embed?mid=1yILVPAU7-HXkJijg-9kWM2O0UIFdom8&ehbc=2E312F&noprof=1"
        title="Map"
        width="960"
        height="575"
      />
    </div>
  );
};
