import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ShrinkingHeaderWithContent } from "../../organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { useTranslation } from "react-i18next";
import "./EAccountPage.scss";
import { EAccountForm } from "../../fragments/EAccountForm/EAccountForm";
import { Breadcrumb } from "../../molecules/Breadcrumb/Breadcrumb";
import { useTheme } from "@mui/material";

export const EAccountPage = () => {
  const [url] = useState(useLocation());
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={"EAccountPage"}>
      <Breadcrumb elements={[]} />
      <ShrinkingHeaderWithContent
        navigateTo={"/e-szamla"}
        text={t("eAccountPage.title")}
        backgroundImage={"sources/pics/fejlecek/eszamla_email.jpg"}
        color={theme.palette.primary.main}
        content={<EAccountForm url={url.search} />}
      />
    </div>
  );
};
