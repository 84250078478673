import { Header } from "components/organisms/Header/Header";
import dbrtLogo from "resources/dbrt-logo.png";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import "./HomeHeader.scss";

export const HomeHeader = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  let logo = <img src={dbrtLogo} alt={"logo"} />;
  let content;

  if (md || sm || lg) {
    content = <Header left={logo} />;
  } else {
    content = <Header middle={logo} />;
  }

  return (
    <div className={"HomeHeader"}>
      <div className={"header"}>{content}</div>
    </div>
  );
};
