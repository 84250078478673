import React from "react";
import PropTypes from "prop-types";
import "./AppointmentListItem.scss";
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { Button, Radio } from "@mui/material";
import theme from "../../../style/variables.module.scss";

export const AppointmentListItem = ({
  address,
  addressDetail,
  buttonAction,
  openingHours,
  availableService,
  darkBackground,
  radioButton,
  checked,
  onCheck,
}) => {
  const { t } = useTranslation();

  const clickCallback = () => {
    if (radioButton) {
      onCheck();
    }
  };

  return (
    <div
      className={
        "AppointmentListItem" +
        (darkBackground ? " dark" : "") +
        (radioButton ? " radio" : "") +
        (checked ? " checked" : "")
      }
      onClick={clickCallback}
    >
      {radioButton && (
        <Radio
          onClick={onCheck}
          checked={checked}
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
          sx={{
            "&.Mui-checked": {
              color: theme.primary,
            },
          }}
        />
      )}
      <div className="basic-info">
        <div className="icon">
          <LocationOnIcon sx={{ width: 35, height: 35, fill: "orange" }} />
        </div>
        <div className={"first-row"}>
          <div className="address">{address}</div>
          <div className="address-details">{addressDetail}</div>
          <div className="available-services">
            {availableService?.babySafe && (
              <img
                src={"sources/pics/cikk_ikonok/baba.png"}
                alt={"baba"}
                width={"25px"}
                height={"25px"}
                title={"Bababarát"}
              />
            )}
            {availableService?.cardPayment && (
              <img
                src={"sources/pics/cikk_ikonok/bankkartyasfizetes.png"}
                alt={"bankkartyasfizetes"}
                width={"25px"}
                height={"25px"}
                title={"Bankkártyás fizetés"}
              />
            )}
            {availableService?.cashPayment && (
              <img
                src={"sources/pics/cikk_ikonok/keszpenzesfizetes.png"}
                alt={"keszpenzesfizetes"}
                width={"25px"}
                height={"25px"}
                title={"Készpénzes fizetés"}
              />
            )}
            {availableService?.accessible && (
              <img
                src={"sources/pics/cikk_ikonok/akadalymentesitett.png"}
                alt={"akadalymentesitett"}
                width={"25px"}
                height={"25px"}
                title={"Akadálymentesített"}
              />
            )}
          </div>
        </div>
      </div>

      <div className="times">
        <div className="opening-hours-title">Ügyfélfogadási idő:</div>
        <div className="opening-hours">
          {openingHours?.map((oh, index) => {
            return (
              <div className="day" key={index}>
                <div>{oh.day}</div>
                <div>{oh.time}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={"appointment "}>
        {!radioButton && (
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => buttonAction("időpontfoglalás")}
          >
            {t("components.appointmentListItem.appointment")}
          </Button>
        )}
      </div>
    </div>
  );
};

AppointmentListItem.propTypes = {
  /** show address */
  address: PropTypes.string,

  /** show address detail  */
  addressDetail: PropTypes.string,

  /** Background color of the component*/
  backgroundColor: PropTypes.string,

  /** List of available services */
  availableService: PropTypes.object,

  /** Callback function of the button */
  buttonAction: PropTypes.func,

  /**
   * Object needs to have 2 properties: day and time
   * example:
   * { day: "Monday", time: "09:00 - 11:00" }
   * both needs to be strings
   */
  openingHours: PropTypes.arrayOf(PropTypes.object),

  /** If true, the background will be  #FAFAFA*/
  darkBackground: PropTypes.bool,
};
