import React from "react";
import "./PictureTile.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const PictureTile = ({ navigateTo, text, backgroundUrl }) => {
  return (
    <Link className={"PictureTile"} to={navigateTo}>
      <div className={"desktop"}>
        <div className={"top-part"}>
          <div className={`text`}>
            <h2>{text}</h2>
          </div>
        </div>
        <div
          className={"img"}
          style={{ backgroundImage: `url(${backgroundUrl})` }}
        />
      </div>
    </Link>
  );
};

PictureTile.propTypes = {
  /**
   *  Navigate to this URL on click
   */
  navigateTo: PropTypes.string,
  /**
   * Text that will be displayed in the middle of the box
   */
  text: PropTypes.string,
  /**
   * Text that will be displayed in the middle of the box
   */
  picture: PropTypes.object,
};
