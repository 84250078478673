import axios from "axios";
import { MESSAGING_ENGINE_API_URL } from "api/ApiConstants";

export const contactUs = async (data) => {
  return axios.post(`${MESSAGING_ENGINE_API_URL}/contactUs`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCategories = async () => {
  return axios.get(`${MESSAGING_ENGINE_API_URL}/contactUs/categories`, {});
};
export const getCities = async () => {
  const { data } = await axios.get(
    `${MESSAGING_ENGINE_API_URL}/contactUs/postcodes`
  );
  return data;
};

export const getCity = async (postCode) => {
  const { data } = await axios.get(
    `${MESSAGING_ENGINE_API_URL}/contactUs/postcodes/${postCode}`
  );
  return data;
};
