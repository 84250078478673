import React from "react";
import PropTypes from "prop-types";
import "./ArticleTile.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ArticleTile = ({
  navigateTo,
  mainText,
  icon,
  iconText,
  mainColor,
}) => {
  const { t } = useTranslation();

  return (
    <div className="ArticleTile" style={{ borderColor: mainColor }}>
      <Link className="article-tile" to={navigateTo}>
        <div className="article-tile-container">
          <div className="icon">{icon}</div>
          <div className="icon-text" style={{ color: mainColor }}>
            {iconText}
          </div>
        </div>
        <div className="main-text">{mainText}</div>
        <div className="more" style={{ color: mainColor }}>
          {t("components.articleTile.more")}
        </div>
      </Link>
    </div>
  );
};

ArticleTile.propTypes = {
  /** navigate to this URL */
  navigateTo: PropTypes.string,

  /** icon that will be displayed, needs to be a materialUI icon object, can be customized with css */
  icon: PropTypes.object,

  /** Text to display under the icon */
  iconText: PropTypes.string,

  /** Main color of the component, includes: borderColor,text under icon and text at the bottom */
  mainColor: PropTypes.string,

  /** Description to show at the middle of the component  */
  mainText: PropTypes.string,
};
