import { Route, Switch, useLocation } from "react-router-dom";
import { Paths } from "routing/Paths";
import { Home } from "components/pages/Home/Home";
import { Test } from "components/pages/Test/Test";
import { CMSPage } from "components/pages/CMSPage/CMSPage";
import { SearchResults } from "components/pages/SearchResults/SearchResults";
import { ActualitiesPage } from "components/pages/ActualitiesPage/ActualitiesPage";
import { useEffect } from "react";
import { ga } from "react-ga";

export const Routes = () => {
  let location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      ga("send", "pageview", location.pathname + location.search);
    }, 0);
  }, [location]);

  return (
    <Switch>
      <Route path={Paths.ACTUALITIES} exact={true}>
        <ActualitiesPage />
      </Route>
      <Route path={Paths.SEARCH_RESULTS} exact={true}>
        <SearchResults />
      </Route>
      <Route path={Paths.TEST}>
        <Test />
      </Route>
      <Route path={Paths.HOME} exact={true}>
        <Home />
      </Route>
      <Route>
        <CMSPage />
      </Route>
    </Switch>
  );
};
