import axios from "axios";
import { PAYMENT_ENGINE_API_URL } from "api/ApiConstants";

export const postPayment = async ({ data, token }) => {
  return axios.post(`${PAYMENT_ENGINE_API_URL}/payment/startpayment`, data, {
    headers: { "recaptcha-token": token },
  });
};

export const checkAmountDue = async ({ data, token }) => {
  return axios
    .get(`${PAYMENT_ENGINE_API_URL}/payment/checkamountdue`, {
      params: data,
      headers: { "recaptcha-token": token },
    })
    .catch((err) => {
      return err.response;
    });
};

export const getPaymentState = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${PAYMENT_ENGINE_API_URL}/payment/paymentstatus?currentPaymentId=${queryKey[1]}`
  );
  return data;
};
