import React, { useEffect, useState } from "react";
import "./ScrollToTop.scss";

export const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    document.addEventListener("scroll", toggleVisibility);
    return () => {
      document.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (visible) {
    return (
      <div className={"ScrollToTop"}>
        <div
          id={"toTop"}
          className={"toTop"}
          style={{
            background: "url(sources/ui.totop.png) no-repeat left top",
          }}
          onClick={scrollToTop}
        >
          <div
            id={"toTopHover"}
            className={"toTopHover"}
            style={{
              background: "url(sources/ui.totop.png) no-repeat left -70px",
            }}
          />
        </div>
        <div
          className={"img"}
          style={{
            background: "url(sources/ui.totop.png) no-repeat left -70px",
          }}
        />
      </div>
    );
  }
  return null;
};
