import "./Breadcrumb.scss";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

export const Breadcrumb = ({ elements }) => {
  return (
    <div className={"Breadcrumb"}>
      <div className={"element"}>
        <Link to={"/"}>
          <HomeIcon />
        </Link>
      </div>
      {elements.map((element, index) => (
        <div className={"element-wrapper"} key={index}>
          {element.url && (
            <div className={"element"}>
              <Link to={element.url}>{"/ " + element.label}</Link>
            </div>
          )}
          {!element.url && (
            <div className={"element"}>
              <div className={"element-inner"}>{"/ " + element.label}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
