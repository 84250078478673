import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { checkAmountDue } from "api/payment/payment";
import "./PaymentPageTemplate.scss";
import { Button, TextField } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { GET_SITE_KEY } from "../../../utils/utils";
import { ErrorNotification } from "../../molecules/ErrorNotification/ErrorNotification";

export const PaymentForm = ({ setFormData }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [serverError, setServerError] = useState(null);
  const recaptchaRef = React.useRef();

  useEffect(() => {
    const queryString = window.location.search;
    if (queryString.includes("tehoId")) {
      const urlParams = new URLSearchParams(queryString);
      for (const [key, value] of urlParams.entries()) {
        if (key === "paymentId" && value != null && value !== "") {
          formik.setFieldValue("paymentId", value);
        }
        if (key === "tehoId" && value != null && value !== "") {
          formik.setFieldValue("sum", value);
        }
      }
    }
  }, []);

  const validate = (values) => {
    let errors = {};

    if (
      values.paymentId.toString().charAt(0) === "" ||
      values.paymentId.toString().charAt(0) !== "5"
    ) {
      errors.paymentId = "A fizetőazonosító mindig 5-ös számmal kezdődik!";
    }

    if (values.paymentId.toString().length !== 9) {
      errors.paymentId = "A fizetőazonosító 9 számjegyből áll!";
    }

    if (values.paymentId.toString().length === 0) {
      errors.paymentId = "Kérjük adja meg azonosítóját!";
    }

    if (values.sum.toString().length === 0) {
      errors.sum = "Kérjük adja meg az összeget!";
    }
    if (values.sum.toString().length !== 9) {
      errors.sum = "A terhelési összesítő 9 számjegyből áll!";
    }

    return errors;
  };

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const dataToSend = {
      tehoId: data.sum,
      paymentId: data.paymentId,
      paymentCode: data.paymentId,
      redirectUrl: window.location.href,
    };
    const response = await mutation.mutateAsync({
      data: dataToSend,
      token: token,
    });

    if (response == null) {
      paymentServiceUnavailable();
      return;
    }

    switch (response?.status) {
      case 200: {
        if (
          response.data != null &&
          response.data.amountDue != null &&
          response.data.queryId != null
        ) {
          setErrorMessage(null);
          setFormData({
            ...dataToSend,
            amountDue: response?.data?.amountDue,
            queryId: response?.data?.queryId,
          });
          setDataSubmitted(true);
        } else {
          setServerError(t("payment.noData"));
        }
        break;
      }
      case 404: {
        setErrorMessage(response.data?.dbh_error_message);
        break;
      }
      case 400: {
        setErrorMessage(t("payment.incorrectData"));
        break;
      }
      default: {
        paymentServiceUnavailable();
        break;
      }
    }
  };

  const paymentServiceUnavailable = () => {
    setServerError(t("payment.paymentServiceUnavailable"));
  };

  const formik = useFormik({
    initialValues: {
      paymentId: "",
      sum: "",
    },
    onSubmit: onSubmit,
    validate: validate,
  });

  const [dataSubmitted, setDataSubmitted] = useState(false);

  const mutation = useMutation(checkAmountDue);

  const reload = () => {
    formik.setFieldValue("paymentId", "");
    formik.setFieldValue("sum", "");
    setDataSubmitted(false);
  };

  const resetServerError = () => {
    setServerError(null);
  };

  return (
    <div className={"PaymentForm"}>
      <form className={"check-form"}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={GET_SITE_KEY()}
          size="invisible"
        />
        <TextField
          id={"paymentId"}
          color={"default"}
          label={t("payment.paymentId") + "*"}
          variant={"standard"}
          helperText={formik.touched.paymentId && formik.errors.paymentId}
          error={formik.touched.paymentId && formik.errors.paymentId}
          fullWidth={true}
          value={formik.values.paymentId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inputProps={{ maxLength: 9 }}
        />
        <TextField
          id={"sum"}
          color={"default"}
          label={t("payment.sum") + "**"}
          variant={"standard"}
          helperText={formik.touched.sum && formik.errors.sum}
          error={formik.touched.sum && formik.errors.sum}
          fullWidth={true}
          value={formik.values.sum}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inputProps={{ required: true, maxLength: 9 }}
        />
      </form>
      <div className={"condition"}>
        {t("payment.generalContractConditionsAcceptedPart1") + " "}
        <a
          href={"/altalanos-szerzodesi-feltetelek"}
          target={"_blank"}
          rel={"noreferrer"}
        >
          Általános Szerződési Feltételeit
        </a>
        {" " + t("payment.generalContractConditionsAcceptedPart2")}
      </div>
      <div className={"button-wrapper"}>
        {!dataSubmitted ? (
          <Button
            onClick={() => formik.submitForm()}
            disabled={mutation.isLoading}
            color={"default"}
          >
            {t("payment.send")}
            <ArrowForwardIosIcon />
          </Button>
        ) : (
          <Button onClick={reload} color={"default"}>
            {t("payment.newId")}
            <ArrowForwardIosIcon />
          </Button>
        )}
      </div>
      {errorMessage && (
        <div className={"error-message-container error-color"}>
          {errorMessage}
        </div>
      )}
      {serverError && (
        <ErrorNotification
          open={!!serverError}
          onClose={resetServerError}
          message={serverError}
        />
      )}
    </div>
  );
};
