import { getTemplateComponent } from "utils/TemplateMapper";
import { getPageData } from "api/page/Page";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";

export const TemplateWrapper = ({ urlData }) => {
  const { data: pageData } = useQuery(
    ["page-data", urlData.pageId],
    getPageData
  );

  const TemplateComponent = pageData
    ? getTemplateComponent(pageData.spTemplate)
    : null;

  return TemplateComponent ? (
    <>
      <Helmet>
        <title>{pageData.address} | DBRT</title>
      </Helmet>
      <TemplateComponent pageData={pageData} />
    </>
  ) : null;
};
