import React from "react";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { CollapsableInfoCard } from "components/molecules/CollapsableInfoCard/CollapsableInfoCard";
import "./MultipleCollapsibleInfoPage.scss";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import {
  getBackgroundByPageId,
  getIconByContentId,
} from "utils/ResourceMapper";
import { useTheme } from "@mui/material";
import { getClassNameByColor } from "../../../utils/utils";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";

export const MultipleCollapsibleInfoPage = ({ pageData }) => {
  const theme = useTheme();
  return (
    <div className={"MultipleCollapsibleInfoPage"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={
          "/" + (pageData.parentPage != null ? pageData.parentPage.url : "")
        }
        text={pageData.subPageAddress}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <div className="multiple-collapse-info-page">
            {pageData.content.map((content, index) => {
              return (
                <CollapsableInfoCard
                  key={index}
                  icon={getIconByContentId(content.id)}
                  title={content.address}
                  upperBorder={index !== 0}
                >
                  <div
                    className={getClassNameByColor(theme.palette.primary.main)}
                    dangerouslySetInnerHTML={{ __html: content.text }}
                  />
                </CollapsableInfoCard>
              );
            })}
          </div>
        }
      />
      <BlueBanner />
    </div>
  );
};
