import { Footer } from "components/organisms/Footer/Footer";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getMenu, MenuApiQueryKeys } from "api/menu/Menu";
import { getUrls, UrlsApiQueryKeys } from "api/urls/Urls";
import _ from "lodash";
import { Link } from "react-router-dom";
import "./PageFooter.scss";

export const PageFooter = () => {
  const { t } = useTranslation();

  const { data: menuData } = useQuery(MenuApiQueryKeys.MENU, getMenu);
  const { data: urls } = useQuery(UrlsApiQueryKeys.URLS, getUrls);

  return (
    <div className={"PageFooter"}>
      <Footer
        left={<p>{t("home.footer.holding")}</p>}
        right={
          <div className={"right-inner"}>
            {menuData &&
              urls &&
              _.find(menuData, { link: "footer_menu" })
                ?.menuItems.filter((menuItem) => menuItem.link)
                .map((menuItem) => (
                  <Link
                    key={menuItem.link}
                    to={_.find(urls, { pageId: parseInt(menuItem.link) }).url}
                    style={{ textDecoration: "none" }}
                  >
                    {menuItem.name}
                  </Link>
                ))}
          </div>
        }
      />
    </div>
  );
};
