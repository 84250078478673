import React, { useEffect, useState } from "react";
import { BreadcrumbFromPageData } from "../../molecules/Breadcrumb/BreadcrumbFromPageData";
import { ShrinkingHeaderWithContent } from "../../organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { getBackgroundByPageId } from "../../../utils/ResourceMapper";
import { CollapsableInfoCard } from "../../molecules/CollapsableInfoCard/CollapsableInfoCard";

import { useTheme } from "@mui/material";
import "./JobsTemplate.scss";
import moment from "moment";
import { CareerBanner } from "../../careerBanner/CareerBanner";

export const JobsTemplate = ({ pageData }) => {
  const theme = useTheme();

  const openedId = window.location.href.split("#")[1];

  const [bevezetoId, setBevezetoId] = useState(0);

  useEffect(() => {
    setBevezetoId(pageData.content.findIndex((x) => x.address === "Bevezető"));
  }, []);

  return (
    <div className={"JobsTemplate"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={"/" + pageData.parentPage.url}
        text={pageData.subPageAddress}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <div className="jobs-content">
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content[bevezetoId].text,
              }}
            />
            {pageData.content.map((content, index) => {
              if (index === bevezetoId) {
                return null;
              } else if (
                content.text != null &&
                content.address != null &&
                content.address !== ""
              ) {
                let available =
                  content.availableFrom &&
                  content.availableTo &&
                  moment().isAfter(content.availableFrom) &&
                  moment().isBefore(content.availableTo);
                return (
                  content.id !== 157 &&
                  content.address &&
                  content.active === true &&
                  available && (
                    <CollapsableInfoCard
                      id={content.id}
                      key={content.id}
                      icon={null}
                      title={content.address}
                      upperBorder={index !== 1}
                      noButton={true}
                      isOpenInit={openedId && parseInt(openedId) === content.id}
                    >
                      <div dangerouslySetInnerHTML={{ __html: content.text }} />
                    </CollapsableInfoCard>
                  )
                );
              }
              return null;
            })}
          </div>
        }
      />
      <div className={"banner"}>
        <CareerBanner />
      </div>
    </div>
  );
};
