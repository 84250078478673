import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Routes } from "routing/Routes";
import { ThemeProvider } from "@mui/material";
import { DefaultTheme } from "style/DefaultTheme";
import { initI18n } from "i18n/I18nConfig";
import { initApi } from "api/ApiConfig";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import moment from "moment";
import "moment/locale/hu";
import DOMPurify from "dompurify";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import { initialize } from "react-ga";

moment.locale("hu");

initI18n();

const queryClient = initApi();

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  if ("target" in node) {
    // node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
  }
});

const tagManagerArgs = {
  gtmId: "GTM-KTWTJ7J",
};
TagManager.initialize(tagManagerArgs);

initialize("UA-121568779-1");

export const App = () => {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Helmet>
              <title>DBRT</title>
            </Helmet>
            <Routes />
          </BrowserRouter>
        </LocalizationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <CookieConsent
        location="bottom"
        buttonText="MEGÉRTETTEM"
        cookieName="dbrt-cookie"
        style={{
          background: DefaultTheme.palette.primary.main,
          width: "75%",
          padding: "10px 15px",
          position: "fixed",
          bottom: "0",
          left: "12.5%",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: "1000",
        }}
        buttonStyle={{
          background: DefaultTheme.palette.primary.main,
          color: "#fff",
          fontWeight: 600,
          fontSize: "15px",
        }}
        expires={150}
        buttonId={"ok_cookie"}
      >
        Oldalunk a felhasználói élmény javítása érdekében cookie-kat (sütiket)
        használ. Ha Ön folytatja a böngészést nálunk, hozzájárul a cookie-k
        aktiválásához.
      </CookieConsent>
    </ThemeProvider>
  );
};

/*
  bottom: "0",
  fontSize: "14px",
  fontWeight: "300",
  alignContent: "center"
 */
