import React from "react";
import { ActualityCard } from "components/organisms/ActualityCard/ActualityCard";
import "./Actualities.scss";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { BlogApiQueryKeys, getBlog } from "api/blog/Blog";
import { getUrls, UrlsApiQueryKeys } from "api/urls/Urls";
import _ from "lodash";
import DOMPurify from "dompurify";

export const Actualities = () => {
  const { t } = useTranslation();

  const { data: urls } = useQuery(UrlsApiQueryKeys.URLS, getUrls);

  let pageId =
    urls && Array.isArray(urls)
      ? _.find(urls, { url: "aktualitasok" }).pageId
      : false;

  const { data: blogEntries } = useQuery(
    [BlogApiQueryKeys.BLOG, 0, 4, pageId],
    getBlog,
    {
      enabled: !!pageId,
    }
  );

  return (
    <div className={"Actualities"}>
      <h2 className={"header"}>{t("components.actualities.actualities")}</h2>
      <div className={"content"}>
        {blogEntries?.data.map((entry, index) => (
          <ActualityCard
            key={index}
            title={entry.blogAddress}
            text={
              <>
                {entry.postMainPics && (
                  <img
                    className="main-pic"
                    src={"media/pics/post_main_pics/" + entry.postMainPics}
                    alt={"main-pic"}
                  />
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(entry.text),
                  }}
                />
              </>
            }
            navigateTo={
              _.filter(urls, { pageId: entry.id, type: "blog_more" })[0]?.url ||
              "/"
            }
            borderNeeded={index === 0 || index === 1}
          />
        ))}
      </div>
      <div className={"button-wrapper"}>
        <Link to={"/aktualitasok"}>
          <Button variant="contained" color={"primary"}>
            További hírek
          </Button>
        </Link>
      </div>
    </div>
  );
};
