import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./LetterPicker.scss";

export const LetterPicker = ({ onSelect, availableLetters }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("all");
  const abc = [
    "a",
    "á",
    "b",
    "c",
    "d",
    "e",
    "é",
    "f",
    "g",
    "h",
    "i",
    "í",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "ú",
    "ü",
    "ű",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  const selectCallback = (l) => {
    setSelected(l);
    if (onSelect != null) {
      onSelect(l);
    }
  };

  const letters = () => {
    const pusIntoJsx = (l) => {
      jsx.push(
        <span
          key={l}
          className={"btn" + (selected === l ? " selected" : "")}
          onClick={() => selectCallback(l)}
        >
          {l.toUpperCase()}
        </span>
      );
    };

    let jsx = [];
    if (availableLetters != null && availableLetters.length > 0) {
      abc.forEach((l) => {
        if (availableLetters.includes(l)) {
          pusIntoJsx(l);
        } else {
          jsx.push(
            <span key={l} className={"disabled"}>
              {l.toUpperCase()}
            </span>
          );
        }
      });
    } else {
      abc.forEach((l) => {
        pusIntoJsx(l);
      });
    }
    return jsx;
  };

  return (
    <div className={"LetterPicker"}>
      <div className={"letters-wrapper"}>
        <span
          className={"btn" + (selected === "all" ? " selected" : "")}
          onClick={() => selectCallback("all")}
        >
          {t("components.letterPicker.all").toUpperCase()}
        </span>
        {letters()}
      </div>
    </div>
  );
};

LetterPicker.propTypes = {
  /**
   * Callback for when a letter is selected
   */
  onSelect: PropTypes.func,
  /**
   * An array of string, if not null,
   * the letters in the array will be active,
   * every other letter will be disabled
   */
  availableLetters: PropTypes.arrayOf(PropTypes.string),
};
