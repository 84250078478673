import React from "react";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import DOMPurify from "dompurify";
import { useQuery } from "react-query";
import { getPageData } from "api/page/Page";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import { getBackgroundByPageId } from "utils/ResourceMapper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./FAQTemplate.scss";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";

export const FAQTemplate = ({ pageData }) => {
  const theme = useTheme();

  const data1 = useQuery(["page-data", 46], getPageData).data;
  const data2 = useQuery(["page-data", 48], getPageData).data;
  const data3 = useQuery(["page-data", 47], getPageData).data;
  const data4 = useQuery(["page-data", 45], getPageData).data;

  let element = document.getElementById(window.location.hash.substring(1));
  if (element && element.offsetTop) {
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  }

  const infoCardContent = (index) => {
    let data;
    switch (index) {
      case 0:
        data = data1;
        break;
      case 1:
        data = data2;
        break;
      case 2:
        data = data3;
        break;
      case 3:
        data = data4;
        break;
      default:
        break;
    }

    if (data != null && data.content != null) {
      return (
        <div>
          {data.content.map((c) => {
            return (
              <a href={"/gyik#" + c.id} key={c.id}>
                <h4>{c.address}</h4>
              </a>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const lowerCardContent = (data) => {
    return data.content.map((c) => {
      return (
        <div className={"anchored"} id={c.id} key={c.id}>
          <h4>{c.address}</h4>
          <div
            key={c.id}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(c.text),
            }}
          />
          <div className={"return-button"}>
            <Button
              variant={"text"}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Vissza
              <KeyboardArrowUpIcon />
            </Button>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={"FAQTemplate"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={
          "/" + (pageData.parentPage != null ? pageData.parentPage.url : "")
        }
        text={pageData.subPageAddress}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <div>
            <div className={"upper"}>
              {pageData.content.map((content, index) => {
                return (
                  <Accordion key={index}>
                    <AccordionSummary
                      key={index}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id={"accordion-" + index}
                    >
                      <h2 className={"h2"}>{content.address}</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={"questions"}>
                        {infoCardContent(index)}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
            <div className={"lower"}>
              <div className={"lower-inner"}>
                {data1 != null && <h2>{data1.address}</h2>}
                {data1 != null && lowerCardContent(data1)}
              </div>
              <div className={"lower-inner"}>
                {data2 != null && <h2>{data2.address}</h2>}
                {data2 != null && lowerCardContent(data2)}
              </div>
              <div className={"lower-inner"}>
                {data3 != null && <h2>{data3.address}</h2>}
                {data3 != null && lowerCardContent(data3)}
              </div>
              <div className={"lower-inner"}>
                {data4 != null && <h2>{data4.address}</h2>}
                {data4 != null && lowerCardContent(data4)}
              </div>
            </div>
          </div>
        }
      />
      <BlueBanner />
    </div>
  );
};
