import React from "react";
import { RadioCard } from "components/molecules/RadioCard/RadioCard";
import { RadioGroup } from "@mui/material";

import { useTranslation } from "react-i18next";

export const OnlineBookingPageOne = ({ formik, tasks }) => {
  const { t } = useTranslation();
  return (
    <div className={"OnlineBookingPageOne"}>
      <h4>{t("onlineBooking.pleaseChoose")}</h4>
      <div className={"cards"}>
        <RadioGroup value={formik.values.bookingType}>
          {tasks != null &&
            Array.isArray(tasks) &&
            tasks.map((d) => {
              return (
                <RadioCard
                  key={d.id}
                  value={d.id}
                  checked={d.id === formik.values.bookingType}
                  text={d.text}
                  title={d.title}
                  onChange={(value) =>
                    formik.setFieldValue("bookingType", value)
                  }
                />
              );
            })}
        </RadioGroup>
      </div>
    </div>
  );
};
