import React from "react";
import { CollapsePanel } from "components/molecules/CollapsePanel/CollapsePanel";
import { useTranslation } from "react-i18next";
import "./MeterReading.scss";
import { MeterReadingForm } from "components/pages/MeterReading/MeterReadingForm";
import DOMPurify from "dompurify";
import { PropTypes } from "prop-types";
import { useTheme } from "@mui/material";
import { getClassNameByColor } from "../../../utils/utils";

export const MeterReading = ({ pageData }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={"MeterReadingDeclaration"}>
      <div className="sub-title">
        {t("meterReading.meterReadingDeclaration")}
      </div>
      <div className={getClassNameByColor(theme.palette.primary.main)}>
        {pageData != null &&
          pageData.content != null &&
          pageData.content.map((collapsible, index) => {
            return (
              <CollapsePanel key={index} title={collapsible.address}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(collapsible.text),
                  }}
                />
              </CollapsePanel>
            );
          })}
      </div>
      <MeterReadingForm />
    </div>
  );
};

MeterReading.propTypes = {
  pageData: PropTypes.object,
};
