import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const OnlineBookingConfirmationPage = ({ values, tasks }) => {
  const { t } = useTranslation();
  const [selectedTask, setSelectedTask] = useState(values.bookingType);

  useEffect(() => {
    if (tasks != null && Array.isArray(tasks)) {
      tasks.forEach((task) => {
        if (task.id === selectedTask) {
          setSelectedTask(task.name);
        }
      });
    }
  }, []);

  return (
    <div className={"OnlineBookingConfirmationPage"}>
      <h4>{t("onlineBooking.bookingDetails")}</h4>
      <div className={"content-wrapper"}>
        <div className={"upper"}>
          <h4 className={"confirm"}>
            {t("onlineBooking.pleaseConfirmBooking", {
              email: values.emailAddress,
            })}
          </h4>
          <br />
          <div className={"missed-confirmation"}>
            {t("onlineBooking.missedConfirmation")}
          </div>
        </div>

        <div className={"lower"}>
          <label>{t("onlineBooking.site")}</label>
          <h4 className={"mg"}>{values.site.mappings.address}</h4>

          <label>{t("onlineBooking.chosenBookingType")}</label>
          <h4 className={"mg"}>{selectedTask}</h4>

          <label>{t("onlineBooking.chosenDateAndTime")}</label>
          <h4>
            {moment(values.date).format("yyyy.MM.DD")}
            <br /> {moment(values.time.begin).format("HH:mm")}
          </h4>
        </div>
      </div>
    </div>
  );
};
