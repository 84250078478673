import { useQuery } from "react-query";
import { search, SearchApiQueryKeys } from "api/search/Search";
import { Link, useLocation } from "react-router-dom";
import { Button, Divider, ThemeProvider } from "@mui/material";
import { Subpage } from "components/fragments/Subpage/Subpage";
import { useColorVariant } from "hooks/useColorVariant";
import DOMPurify from "dompurify";
import "./SearchResults.scss";
import { Helmet } from "react-helmet";

export const SearchResults = () => {
  let { state } = useLocation();
  let searchValue = state?.searchValue;
  const { colorVariant, theme, spGroup } = useColorVariant();

  const { data: results } = useQuery(
    [SearchApiQueryKeys.SEARCH, searchValue, false, spGroup],
    search,
    {
      enabled: Boolean(searchValue),
    }
  );

  return (
    <Subpage
      className={"SearchResults " + (colorVariant + "-variant")}
      content={
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>Találatok | DBRT</title>
          </Helmet>
          <div className={"search-results"}>
            <div className={"flex"}>
              Találatok a(z){" "}
              <h3 className={"primary-color"}>&nbsp;"{searchValue}"&nbsp;</h3>{" "}
              kifejezésre
            </div>
            <div className={"results-wrapper"}>
              {results &&
                results.map((result, index) => (
                  <div key={index}>
                    <h4>{result.pageTitle}</h4>
                    <div
                      className={"content"}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          result.content?.replace(/<\/?[^>]+(>|$)/g, "")
                        ),
                      }}
                    />
                    <Link
                      to={result.pageUrl}
                      style={{ textDecoration: "none" }}
                    >
                      <Button>Bővebben</Button>
                    </Link>
                    {index !== results.length - 1 && <Divider />}
                  </div>
                ))}
            </div>
          </div>
        </ThemeProvider>
      }
    />
  );
};
