import { Subpage } from "components/fragments/Subpage/Subpage";
import { Route } from "react-router-dom";
import "./CMSPage.scss";
import { useQuery } from "react-query";
import { getUrls, UrlsApiQueryKeys } from "api/urls/Urls";
import { TemplateWrapper } from "components/pages/TemplateWrapper/TemplateWrapper";
import _ from "lodash";
import { ThemeProvider } from "@mui/material";
import { useColorVariant } from "hooks/useColorVariant";
import { BlogEntryPage } from "components/pages/BlogEntryPage/BlogEntryPage";
import { ScrollToTop } from "../../atoms/ScrollToTop/ScrollToTop";
import { Four0Four } from "../404/404";

export const CMSPage = () => {
  const { data: urls } = useQuery(UrlsApiQueryKeys.URLS, getUrls);
  const { colorVariant, theme } = useColorVariant();

  const getUrlDataByUrl = (url) => {
    return _.find(_.orderBy(urls, "id"), { url: url.substr(1) });
  };

  const urlMatchFound = (match) => {
    let urlData = getUrlDataByUrl(match.url);
    return (
      <Subpage
        className={colorVariant + "-variant"}
        content={
          <ThemeProvider theme={theme}>
            {urlData.type === "oldal" && <TemplateWrapper urlData={urlData} />}
            {urlData.type === "blog_more" && (
              <BlogEntryPage urlData={urlData} />
            )}
          </ThemeProvider>
        }
      />
    );
  };

  const urlMatchNotFound = () => {
    return (
      <Subpage className={colorVariant + "-variant"} content={<Four0Four />} />
    );
  };

  const children = ({ match }) => {
    if (match == null) {
      return urlMatchNotFound();
    } else {
      return urlMatchFound(match);
    }
  };

  return (
    <div className={"CMSPage"}>
      {urls && (
        <Route
          path={urls
            .map((url) => "/" + url.url)
            .filter((urlString) => urlString !== "/")}
          children={children}
        />
      )}
      <ScrollToTop />
    </div>
  );
};
