import React, { useEffect, useRef, useState } from "react";
import "./CollapsableInfoCard.scss";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const CollapsableInfoCard = ({
  id,
  icon,
  title,
  upperBorder,
  noButton,
  children,
  isOpenInit = false,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(isOpenInit);
  const ref = useRef();

  const h2Click = () => {
    if (noButton) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (isOpenInit) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [ref, isOpenInit]);

  return (
    <div className={"CollapsableInfoCard"} id={id} ref={ref}>
      {upperBorder && <div className={"upper-border"} />}
      <div className={"header"}>
        {icon && (
          <div className="icon" style={{ backgroundImage: `url(${icon})` }} />
        )}
        <h2 className={noButton ? "pointer" : ""} onClick={h2Click}>
          {title}
        </h2>
      </div>
      {(open || isOpenInit) && <div className={"content"}>{children}</div>}
      {!noButton && (
        <div className={"button-wrapper"}>
          <Button onClick={() => setOpen(!open)} color={"primary"}>
            {open
              ? t("collapsableInfoCard.close")
              : t("collapsableInfoCard.details")}
          </Button>
        </div>
      )}
    </div>
  );
};

CollapsableInfoCard.propTypes = {
  /**
   * Icon address
   */
  icon: PropTypes.string,
  /**
   * The text that will be displayed on the info card
   */
  title: PropTypes.string,
  /**
   * If true, there will be a border displayed above the component
   */
  upperBorder: PropTypes.bool,

  /**
   * If ture, there won't be a "more" button, users will have to click the title to open the content
   */
  noButton: PropTypes.bool,
};
