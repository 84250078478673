import React from "react";
import PropTypes from "prop-types";
import "./ShrinkingHeader.scss";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { Link } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { getClassNameByColor } from "../../../utils/utils";

export const ShrinkingHeader = ({
  navigateTo,
  text,
  backgroundImage,
  color,
  shrunk,
  width,
}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div
      className={
        "ShrinkingHeader " +
        getClassNameByColor(theme.palette.primary.main) +
        (shrunk ? " shrunk" : "")
      }
      style={{
        maxWidth: `${width}px`,
        width: `${width}px`,
      }}
    >
      <h2
        style={{
          color: sm ? "#fff" : color,
        }}
      >
        {text}
      </h2>
      <div
        className={"header-image"}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        {navigateTo != null && (
          <Link className={"go-back"} to={navigateTo}>
            <BackspaceIcon />
          </Link>
        )}
      </div>
    </div>
  );
};

ShrinkingHeader.propTypes = {
  /**
   * The icon in the right upper corner navigates back to this URL
   */
  navigateTo: PropTypes.string,
  /**
   * The text that will be diplayed in the middle of the element
   */
  text: PropTypes.string,
  /**
   * The image displayed in the background of the element
   */
  backgroundImage: PropTypes.string,
  /**
   * Color for the transient (hex or rgba)
   */
  color: PropTypes.string,
};
