import React from "react";
import "./GradientTile.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const GradientTile = ({ navigateTo, text, color, backgroundUrl }) => {
  return (
    <Link
      className={"GradientTile"}
      to={navigateTo}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      <div className={"desktop"}>
        <div className={`gradient-tile-wrapper ${color}`}>
          <div className={`gradient-tile ${color}`}>
            <div className={`gradient-tile-inner ${color}`}>
              <h2>{text}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={"mobile"}>
        <div className={`gradient-tile-wrapper ${color}`}>
          <div className={`gradient-tile ${color}`}>
            <div className={`gradient-tile-inner ${color}`}>
              <h2>{text}</h2>
              <div className={"next-button-container"}>
                <div className={"next-button"}>Tovább</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

GradientTile.propTypes = {
  /**
   *  Navigate to this URL on click
   */
  navigateTo: PropTypes.string,
  /**
   * Text that will be displayed in the middle of the box
   */
  text: PropTypes.string,
  /**
   * Color for the border and the transient
   */
  color: PropTypes.oneOf(["purple", "orange", "green"]),
};
