import { createTheme } from "@mui/material";
import styles from "style/variables.module.scss";

export const OrangeTheme = createTheme({
  palette: {
    primary: {
      main: styles.primaryOrange,
    },
    default: {
      main: styles.primary,
    },
  },
});
