import React, { useEffect, useState } from "react";
import { AppointmentListItem } from "components/organisms/AppointmentListItem/AppointmentListItem";
import { getBranches } from "api/appointment/Appointment";

export const OnlineBookingPageTwo = ({ formik, bookingType, langId }) => {
  const [branches, setBranches] = useState(null);

  const loadBranches = async () => {
    let brs = await getBranches({
      languageID: langId,
      taskID: bookingType,
    });
    setBranches(brs.data.filter((x) => x.mappings != null));
  };

  useEffect(() => {
    loadBranches();
  }, []);

  return (
    <div className={"OnlineBookingPageTwo"}>
      <div className={"appointment-list-item-wrapper"}>
        {branches != null &&
          Array.isArray(branches) &&
          branches.map((d) => {
            return (
              <AppointmentListItem
                key={d.id}
                address={d.mappings?.address}
                addressDetail={d.mappings?.addressDetail}
                availableService={d.mappings?.availableService}
                openingHours={d.mappings?.openingHours}
                darkBackground={d.darkBackground}
                onCheck={() => formik.setFieldValue("site", d)}
                radioButton={true}
                checked={d.id === formik.values?.site?.id}
              />
            );
          })}
      </div>
    </div>
  );
};
