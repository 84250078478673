import React from "react";
import "./404.scss";
import { useTranslation } from "react-i18next";

export const Four0Four = () => {
  const { t } = useTranslation();

  return (
    <div className={"Four0Four"}>
      <h1 className={"big-number primary-color"}>{t("404.404")}</h1>
      <h1 className={"primary-color"}>{t("404.pageNotFound")}</h1>
    </div>
  );
};
