import axios from "axios";
import { PAYMENT_ENGINE_API_URL } from "api/ApiConstants";

export const getElengedesData = async (paymentCode, tehoId) => {
  const { data } = await axios
    .get(
      `${PAYMENT_ENGINE_API_URL}/elengedes?paymentCode=${paymentCode}&tehoId=${tehoId}`
    )
    .catch((res) => {
      console.log("res", res);
      return res;
    });
  return data;
};
