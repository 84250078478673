export const Paths = {
  HOME: "/",
  TEST: "/test",
  SEARCH_RESULTS: "/talalatok",
  RESIDENTAL: "/kezdolap",
  NON_RESIDENTAL: "/kezdolap-nem-lakossagi",
  CONDOMINIUM_MANAGER: "/kezdolap-tarsashazkezelo",
  PAYMENT: "/fizetes",
  CONTACT_US: "/contactus",
  METER_READING_DECLARATION: "/meroallas-bejelentes",
  UNPAID_INVOICE_MANAGEMENT: "/kiegyenlitetlen-szamlak-kezelese",
  ACTUALITIES: "/aktualitasok",
  WORKATUS: "/miert-jo-nalunk-dolgozni",
  JOBOFFERS: "/allasajanlataink",
  COLLEAGUESCOMMENTS: "/kollegaink-velemenye",
  EVENTS: "/rendezvenyek-esemenyek",
  SERVICEANDCHARGES: "/szolgaltatasok-es-dijak",
};
