import React from "react";
import "./WriteToUs.scss";
import DOMPurify from "dompurify";
import { ContactUsForm } from "components/fragments/ContactUsForm/ContactUsForm";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import irjonNekunk from "resources/irjon_nekunk_v.jpg";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import { useTheme } from "@mui/material";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";

export const WriteToUs = ({ pageData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <div className={"WriteToUs"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={
          pageData.parentPage?.url != null ? `/${pageData.parentPage.url}` : "/"
        }
        text={pageData.subPageAddress}
        backgroundImage={irjonNekunk}
        color={theme.palette.primary.main}
        shrunk={false}
        content={
          <>
            <div className={"write-to-us-content"}>
              {pageData.content != null &&
                _.sortBy(pageData.content, "order").map((content, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        "sub-page " +
                        index +
                        (index === 1 || index === 2 ? " wrap-line" : "")
                      }
                    >
                      {index !== 1 && index !== 2 && (
                        <div>
                          <div className="h4-elements">{content.address}</div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(content.text),
                            }}
                          />
                        </div>
                      )}
                      {(index === 1 || index === 2) && (
                        <div>
                          <div className="h4-elements">{content.address}</div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(content.text),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              <div className="contact-form">
                <h4>{t("writeToUs.giveUsDetails")}</h4>
                <h5 className="form-mandatory-text">
                  {t("writeToUs.mandatory")}
                </h5>
                <ContactUsForm complaint={false} />
              </div>
            </div>
            <BlueBanner />
          </>
        }
      />
    </div>
  );
};
