import { useQuery } from "react-query";
import { BlogApiQueryKeys, getBlogEntry } from "api/blog/Blog";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import DOMPurify from "dompurify";
import React from "react";
import { useTheme } from "@mui/material";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";
import "./BlogEntryPage.scss";
import { Helmet } from "react-helmet";

export const BlogEntryPage = ({ urlData }) => {
  const { data: blogEntryData } = useQuery(
    [BlogApiQueryKeys.BLOG_DATA, urlData.pageId],
    getBlogEntry
  );

  const theme = useTheme();

  return (
    <>
      {blogEntryData && (
        <div className={"SimpleTemplate blog BlogEntryPage"}>
          <Helmet>
            <title>{blogEntryData.blogAddress} | DBRT</title>
          </Helmet>
          <BreadcrumbFromPageData
            pageData={{
              subPageAddress: blogEntryData.blogAddress,
              parentPage: {
                subPageAddress: "Aktualitások",
                url: "/aktualitasok",
              },
            }}
          />
          <ShrinkingHeaderWithContent
            navigateTo={blogEntryData.parentUrl}
            text={blogEntryData.blogAddress}
            backgroundImage={"sources/pics/fejlecek/cikkek_header.jpg"}
            color={theme.palette.primary.main}
            content={
              <div className={"blog"}>
                <div className={"main-pic"}>
                  {blogEntryData.postMainPics && (
                    <img
                      src={
                        "media/pics/post_main_pics/" +
                        blogEntryData.postMainPics
                      }
                      alt={"main-pic"}
                    />
                  )}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(blogEntryData.textOfSubPage),
                  }}
                />
              </div>
            }
          />
          <BlueBanner />
        </div>
      )}
    </>
  );
};
