import React, { useState } from "react";
import "./CollapsePanel.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PropTypes from "prop-types";

export const CollapsePanel = ({ openByDefault, title, onChange, children }) => {
  const [open, setOpen] = useState(
    openByDefault != null ? openByDefault : false
  );

  const handleClick = () => {
    setOpen(!open);
    if (onChange != null) {
      onChange(open);
    }
  };

  return (
    <div className={"CollapsePanel"}>
      <div className={"collapse-header"} onClick={handleClick}>
        <h3>{title}</h3>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      {open && (
        <div className={"collapse-content" + (!open ? " hide" : "")}>
          {children}
        </div>
      )}
    </div>
  );
};

CollapsePanel.propTypes = {
  /**
   * If true, the panel is opened by default on load
   */
  openByDefault: PropTypes.bool,
  /**
   * Text that is displayed before the arrow
   */
  title: PropTypes.string,
  /**
   * Returns a boolean, which indicates if the panel is open or closed
   */
  onChange: PropTypes.func,
};
