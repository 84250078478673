import "./Menu.scss";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getMenu, MenuApiQueryKeys } from "api/menu/Menu";
import _ from "lodash";
import { getUrls, UrlsApiQueryKeys } from "api/urls/Urls";
import { getIconByMenuId } from "utils/ResourceMapper";
import React, { useEffect, useState } from "react";
import { useColorVariant } from "hooks/useColorVariant";
import { SearchBox } from "../../molecules/SearchBox/SearchBox";

export const Menu = ({ isOpened, click }) => {
  const { data: menuData } = useQuery(MenuApiQueryKeys.MENU, getMenu);
  const { data: urls } = useQuery(UrlsApiQueryKeys.URLS, getUrls);
  const { menu: menuVariant } = useColorVariant();
  const [menuHeight, setMenuHeight] = useState(0);

  const calculateNewHeight = () => {
    setMenuHeight(document.body.clientHeight - 64);
  };

  useEffect(() => {
    calculateNewHeight();
    document.addEventListener("resize", calculateNewHeight);
    return () => document.removeEventListener("resize", calculateNewHeight);
  }, []);

  const clk = () => {
    click();
  };

  const renderCatalog = (linkName) => {
    return (
      menuData &&
      urls &&
      _.find(menuData, { link: linkName })?.menuItems.map((item) => {
        if (item.id === 75) {
          return (
            <MenuItem
              key={item.order}
              to={""}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <SearchBox placeHolder={"Keresés az oldalon"} isMenu={true} />
            </MenuItem>
          );
        }
        let toUrl = "/";

        if (isNaN(item.link)) {
          toUrl = item.link;
        } else {
          let urlFromDb = _.find(urls, { pageId: parseInt(item.link) });

          if (urlFromDb) {
            toUrl = urlFromDb?.url;
          }
        }

        return (
          <MenuItem key={item.order} component={Link} to={toUrl} onClick={clk}>
            {getIconByMenuId(item.id) && (
              <ListItemIcon>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundSize: "contain",
                    backgroundImage: `url(${getIconByMenuId(item.id)})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </ListItemIcon>
            )}
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        );
      })
    );
  };

  const sm = useMediaQuery("(max-width: 800px)");
  return (
    <div className={"Menu"}>
      <div
        className={"menu-container"}
        style={{ height: menuHeight, width: 304, left: isOpened ? 0 : -304 }}
        onClick={() => {
          if (localStorage.getItem("actualitiesPageNumber"))
            localStorage.removeItem("actualitiesPageNumber");
        }}
      >
        <MenuList>
          {renderCatalog("main_menu_start")}

          <Divider variant="middle" />

          {renderCatalog("main_menu_top_" + menuVariant)}

          <Divider variant="middle" />

          {renderCatalog("main_menu_search")}

          <Divider variant="middle" />

          {renderCatalog("main_menu_mid_" + menuVariant)}

          <Divider variant="middle" />

          {renderCatalog("main_menu_bot")}
        </MenuList>
        <a href={"https://www.dijnet.hu/"}>
          <img
            src={"/media/pics/main_page_main_pics/DBH_banner_gomb_érdekel.jpg"}
            alt={"DBH_banner_gomb_érdekel"}
          />
        </a>
      </div>
      {!sm && (
        <div
          className={"menu-placeholder"}
          style={{ width: isOpened ? 300 : 0 }}
        />
      )}
    </div>
  );
};
