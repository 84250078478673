import React, { useEffect, useState } from "react";
import { BigNavigationBox } from "components/organisms/BigNavigationBox/BigNavigationBox";
import { useTranslation } from "react-i18next";
import "./TileGrid.scss";
import {
  getBackgroundByContentId,
  getIconByContentId,
} from "utils/ResourceMapper";
import { useMediaQuery, useTheme } from "@mui/material";
import { getClassNameByColor } from "../../../utils/utils";

export const TileGrid = ({ pageData }) => {
  const { t } = useTranslation();
  const [style, setStyle] = useState("default");
  const theme = useTheme();

  const small = !useMediaQuery("(min-width:900px)");

  useEffect(() => {
    switch (pageData?.content.length) {
      case 3:
        setStyle("three");
        break;
      case 4:
        setStyle("one-three");
        break;
      case 5:
        setStyle("three-two");
        break;
      case 6:
        setStyle("three-three");
        break;
      default:
        setStyle("default");
        break;
    }
  }, [pageData.content]);

  const getBackgroundImage = (id) => {
    let color = getClassNameByColor(theme.palette.primary.main);
    let resourceId = "";
    if (small) {
      resourceId += "sm_" + color + "_";
    }
    resourceId += id;
    return `url(${getIconByContentId(resourceId)})`;
  };

  const boxes = (coloredBackgroundImage) => {
    return pageData.content.map((data) => {
      let id;
      if (coloredBackgroundImage) {
        id = getBackgroundImage(data.id);
      } else {
        id = `url(${getIconByContentId((small ? "sm_" : "") + data.id)})`;
      }
      return (
        <BigNavigationBox
          key={data.nextLink}
          navigateTo={data.nextLink}
          backgroundImage={getBackgroundByContentId(data.id)}
          color={"primary"}
          mainText={data.address}
          buttonText={t(data.nextLinkTitle)}
          icon={
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: id,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          }
        />
      );
    });
  };

  if (
    pageData.id === 7 &&
    pageData.address === "Időpontfoglalás és elérhetőségek"
  ) {
    return (
      <div className={"TileGrid"}>
        <h2 className={"title"}>{pageData.address}</h2>

        <div className={"one-three"}>
          <div className={"first-row"}>
            <BigNavigationBox
              key={pageData.content[0].nextLink}
              navigateTo={pageData.content[0].nextLink}
              backgroundImage={"/resources/" + pageData.content[0].pageMainPics}
              color={"primary"}
              mainText={pageData.content[0].address}
              buttonText={t(pageData.content[0].nextLinkTitle)}
              icon={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: getBackgroundImage(pageData.content[0].id),
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              }
            />
          </div>
          <div className={"second-row"}>
            {pageData.content.map((data, index) => {
              if (index === 0) {
                return null;
              }
              return (
                <BigNavigationBox
                  key={data.nextLink}
                  navigateTo={data.nextLink}
                  backgroundImage={getBackgroundByContentId(data.id)}
                  color={"primary"}
                  mainText={data.address}
                  buttonText={t(data.nextLinkTitle)}
                  icon={
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: getBackgroundImage(data.id),
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  if (pageData.id === 11 && pageData.address === "Rólunk") {
    return (
      <div className={"TileGrid"}>
        <h2 className={"title"}>{pageData.address}</h2>
        <div className={style}>{boxes(true)}</div>
      </div>
    );
  }

  return (
    <div className={"TileGrid"}>
      {pageData.address != null && pageData.address !== "Kezdőlap" && (
        <h2 className={"title"}>{pageData.address}</h2>
      )}
      {style === "default" && pageData.content != null && (
        <div className={"default"}>{boxes()}</div>
      )}

      {style === "three" && pageData.content != null && (
        <div className={"three"}>{boxes()}</div>
      )}

      {style === "one-three" &&
        pageData.content != null &&
        pageData.content.length === 4 && (
          <div className={"one-three"}>
            <div className={"first-row"}>
              <BigNavigationBox
                key={pageData.content[0].nextLink}
                navigateTo={pageData.content[0].nextLink}
                backgroundImage={
                  "/resources/" + pageData.content[0].pageMainPics
                }
                color={"primary"}
                mainText={pageData.content[0].address}
                buttonText={t(pageData.content[0].nextLinkTitle)}
                icon={
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url(${getIconByContentId(
                        (small ? "sm_" : "") + pageData.content[0].id
                      )})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                }
              />
            </div>
            <div className={"second-row"}>
              {pageData.content.map((data, index) => {
                if (index === 0) {
                  return null;
                }
                return (
                  <BigNavigationBox
                    key={data.nextLink}
                    navigateTo={data.nextLink}
                    backgroundImage={getBackgroundByContentId(data.id)}
                    color={"primary"}
                    mainText={data.address}
                    buttonText={t(data.nextLinkTitle)}
                    icon={
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundImage: `url(${getIconByContentId(
                            (small ? "sm_" : "") + data.id
                          )})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    }
                  />
                );
              })}
            </div>
          </div>
        )}

      {(style === "three-three" || style === "three-two") &&
        pageData.content != null && <div className={style}>{boxes()}</div>}
    </div>
  );
};
