import React from "react";
import { useTranslation } from "react-i18next";

export const SiteMap = () => {
  const { t } = useTranslation();
  return (
    <div className={"SiteMap"}>
      <h1>{t("random.mainMenu")}</h1>
    </div>
  );
};
