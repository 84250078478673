import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { sanitizeNumberInputFromEvent } from "../../../../utils/utils";

export const OnlineBookingPageFour = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <div className={"OnlineBookingPageFour"}>
      <h4>{t("onlineBooking.givePersonalDetails")}</h4>
      <h5>{t("onlineBooking.everyFieldIsReqiured")}</h5>
      <div className={"form-wrapper"}>
        <TextField
          id={"fullName"}
          color={"primary"}
          label={t("onlineBooking.fullName")}
          variant={"standard"}
          error={formik.errors.fullName != null}
          fullWidth={true}
          helperText={formik.errors.fullName}
          value={formik.values.fullName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inputProps={{ required: true }}
        />
        <TextField
          id={"paymentId"}
          color={"primary"}
          label={t("onlineBooking.paymentId")}
          variant={"standard"}
          fullWidth={true}
          helperText={formik.errors.paymentId}
          error={formik.errors.paymentId != null}
          value={formik.values.paymentId}
          onChange={(e) => {
            sanitizeNumberInputFromEvent(e);
            if (e.target.value.toString().length > 9) {
              e.target.value = e.target.value.toString().substring(0, 9);
            }
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          inputProps={{ maxLength: 9 }}
        />
        <TextField
          id={"emailAddress"}
          color={"primary"}
          label={t("onlineBooking.emailAddress")}
          variant={"standard"}
          helperText={formik.errors.emailAddress}
          fullWidth={true}
          error={formik.errors.emailAddress != null}
          value={formik.values.emailAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextField
          id={"phoneNumber"}
          color={"primary"}
          label={t("onlineBooking.phoneNumber")}
          variant={"standard"}
          fullWidth={true}
          helperText={formik.errors.phoneNumber}
          error={formik.errors.phoneNumber != null}
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
    </div>
  );
};
