import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import {
  deleteReservation,
  finalizeReservation,
} from "../../../api/appointment/Appointment";
import "./FoglalasTorlesTemplate.scss";

export const FoglalasTorlesTemplate = () => {
  const { t } = useTranslation();

  const [finalizeData, setFinalizeData] = useState(null);
  const [finalizationSuccess, setFinalizationSuccess] = useState(null);

  const [deleteData, setDeleteData] = useState(null);
  const [deletionSuccess, setDeletionSuccess] = useState(null);

  useQuery(
    "finalize",
    () => {
      finalizeReservation(finalizeData).then((res) =>
        setFinalizationSuccess(res.data)
      );
    },
    { enabled: finalizeData != null }
  );
  useQuery(
    "delete",
    () => {
      deleteReservation(deleteData).then((res) => setDeletionSuccess(res.data));
    },
    { enabled: deleteData != null }
  );

  useEffect(() => {
    const paths = window.location.pathname.split("/");
    if (paths.length > 3) {
      action(paths);
    }
  }, []);

  const action = (paths) => {
    if (paths[1] === "foglalas") {
      setFinalizeData({
        bookingCode: paths[2],
        mailedVerificationCode: paths[3],
      });
    } else {
      setDeleteData({
        bookingCode: paths[2],
        mailedVerificationCode: paths[3],
      });
    }
  };

  const header = () => {
    if (finalizeData != null) {
      if (finalizationSuccess) {
        return "Foglalás érvényesítése sikeres volt!";
      } else {
        return "Foglalás érvényesítése sikertelen, vagy már érvényesítve van!";
      }
    } else {
      if (deletionSuccess) {
        return "Foglalás törlése sikeres volt!";
      } else {
        return "Foglalás törlése sikertelen, vagy már törölve lett!";
      }
    }
  };

  return (
    <div className={"FoglalasTorlesTemplate"}>
      <h1 className={"primary-color"}>{t("onlineBooking.title")}</h1>
      <h4>{header()}</h4>
      <p>{t("onlineBooking.newOnlineBooking")}</p>
      <Link to={"/idopont-foglalas"} style={{ textDecoration: "none" }}>
        <Button color={"default"}>
          {t("onlineBooking.newOnlineBookingButton")}
          <ArrowForwardIosIcon />
        </Button>
      </Link>
    </div>
  );
};
