import smell from "resources/smell.jpg";
import "./Test.scss";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";

export const Test = () => {
  return (
    <div className={"TestPage"}>
      <ShrinkingHeaderWithContent
        navigateTo={"/"}
        text={"test test test"}
        backgroundImage={smell}
        color={"pink"}
        content={
          <div className={"text-wrapper"}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              rutrum urna a est lacinia vulputate. Mauris varius libero nisl,
              sed viverra eros condimentum accumsan. Donec eu accumsan nisl.
              Phasellus faucibus leo nisl, at volutpat mauris lobortis et.
              Integer bibendum, leo sit amet efficitur mattis, sem ligula
              efficitur lorem, eget elementum elit tortor id neque. Curabitur
              cursus pretium varius. Nam ultrices posuere diam at bibendum.
              Nulla suscipit mollis ex id placerat. Phasellus fermentum non dui
              vitae eleifend. Aliquam dictum convallis orci in rhoncus.
              Pellentesque consequat tempus dolor, id sagittis elit pretium a.
              Curabitur et justo vel urna accumsan egestas id quis quam. Nunc
              maximus suscipit massa a viverra. Pellentesque condimentum euismod
              nisl id vulputate. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae;
            </p>
            <p>
              Proin dapibus semper dolor, at fringilla arcu mollis at. Curabitur
              quis consequat augue, vitae blandit dui. Fusce laoreet nisl ut
              metus rutrum, nec tincidunt nunc faucibus. Orci varius natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Vestibulum lacus orci, semper ut volutpat non, suscipit in tortor.
              Aenean elit metus, varius nec dui non, vulputate gravida massa.
              Vestibulum et odio a ex iaculis sodales. Donec laoreet, ex sit
              amet facilisis sagittis, magna dolor bibendum ligula, at interdum
              libero eros et est. Aenean dictum, ex in hendrerit cursus, velit
              massa aliquet nibh, et porta urna dolor sed nunc. Cras nulla eros,
              fermentum ut hendrerit vel, varius sit amet nisi. Nulla laoreet
              ipsum urna, nec placerat risus fringilla quis. Pellentesque
              sodales pharetra sapien ac tristique. Etiam ligula magna,
              sollicitudin eget faucibus nec, dapibus vitae neque.
            </p>
            <p>
              Ut lobortis sagittis mi eu lobortis. Pellentesque blandit id urna
              vitae aliquam. Praesent tincidunt pellentesque gravida. Mauris
              elementum elit ut lacus mollis ultrices. Aliquam id metus nibh.
              Sed sit amet mi cursus, fringilla felis ac, mollis arcu. Morbi
              scelerisque sit amet leo at convallis.
            </p>
            <p>
              Quisque eu enim lacus. Maecenas eget tincidunt nisi, et sodales
              mauris. Donec a ipsum at nisl feugiat tristique sit amet eget
              arcu. Donec eu velit sit amet mi commodo pellentesque. Interdum et
              malesuada fames ac ante ipsum primis in faucibus. Pellentesque
              ullamcorper tempus lectus at sollicitudin. Curabitur vulputate
              lacus vel viverra volutpat. Aenean facilisis pretium facilisis.
              Praesent a urna felis.
            </p>
            <p>
              Quisque dapibus volutpat massa. Etiam vitae libero id ante
              vulputate finibus a in diam. Donec congue, erat in commodo
              iaculis, augue mauris tristique nisi, ut rhoncus leo mauris nec
              dolor. Sed ultrices tristique tincidunt. Nullam malesuada accumsan
              lacus id fermentum. Sed bibendum sed neque at mollis. Morbi lacus
              lectus, varius non sem suscipit, suscipit fermentum justo.
            </p>
            <p>
              Nulla dapibus nunc vel pharetra euismod. Duis viverra nisi id
              mattis scelerisque. Interdum et malesuada fames ac ante ipsum
              primis in faucibus. Donec molestie consectetur semper. Praesent
              laoreet ac est et tincidunt. Integer varius arcu id sem tempus
              sagittis. Donec a tincidunt magna, ut molestie tellus. Praesent
              pretium elit ut ante sollicitudin maximus. Class aptent taciti
              sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos. Vestibulum eget enim ipsum. Vivamus vestibulum, nulla
              nec ultrices molestie, lacus lacus molestie sem, id laoreet mi
              tellus in arcu.
            </p>
          </div>
        }
      />
    </div>
  );
};
