import axios from "axios";
import { MESSAGING_ENGINE_API_URL, API_URL } from "api/ApiConstants";

export const postEAccount = (data) => {
  const header = { "recaptcha-token": data.token };
  return axios.post(`${MESSAGING_ENGINE_API_URL}/eAccount`, data.data, {
    headers: header,
  });
};

export const getCompanyDetails = (taxNumber) => {
  return axios.get(`${API_URL}/companyDetails?taxNumber=${taxNumber}`);
};

export const getCompanyByHash = (hash) => {
  return axios.get(`${API_URL}/companyDetails?hash=${hash}`);
};
