import axios from "axios";
import { METER_ENGINE_API_URL } from "api/ApiConstants";

export const postPayerIdentifier = async ({
  payerIdentifierNumbers,
  payload,
  token,
}) => {
  const { data } = await axios.post(
    METER_ENGINE_API_URL + "/" + payerIdentifierNumbers,
    payload,
    { headers: { "recaptcha-token": token } }
  );
  return data;
};

export const getPayerIdentifier = async (d) => {
  const response = await axios
    .get(METER_ENGINE_API_URL + "/" + d.paymentId, {
      headers: { "recaptcha-token": d.token },
    })
    .catch((err) => err);
  return response.data;
};
