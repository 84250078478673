import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const OnlineBookingPageFive = ({ personalDetails }) => {
  const { t } = useTranslation();
  return (
    <div className={"OnlineBookingPageFive"}>
      <strong>{t("onlineBooking.checkData")}</strong>
      <div className={"check-details-wrapper"}>
        <label>{t("onlineBooking.fullName")}</label>
        <h4 className={"mg"}>{personalDetails.fullName}</h4>

        <label>{t("onlineBooking.emailAddress")}</label>
        <h4 className={"mg"}>{personalDetails.emailAddress}</h4>

        <label>{t("onlineBooking.paymentId")}</label>
        <h4 className={"mg"}>{personalDetails.paymentId}</h4>

        <label>{t("onlineBooking.phoneNumber")}</label>
        <h4 className={"mg"}>{personalDetails.phoneNumber}</h4>

        <label>{t("onlineBooking.chosenService")}</label>
        <h4 className={"mg"}>{personalDetails.bookingType}</h4>

        <label>{t("onlineBooking.site")}</label>
        <h4 className={"mg"}>{personalDetails.site}</h4>

        <label>{t("onlineBooking.chosenTime")}</label>
        <h4 className={"nomargin"}>
          {moment(personalDetails.time).format("yyyy. MM. DD")}
        </h4>
        <h4>{moment(personalDetails.time).format("HH:mm")}</h4>
      </div>
    </div>
  );
};
