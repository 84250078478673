import React, { useEffect, useState } from "react";
import { bannerGif } from "../../../utils/ResourceMapper";
import "./BlueBanner.scss";

export const BlueBanner = () => {
  const [style, setStyle] = useState({});

  // if the height of the page is more than 800px, we have to put the banner on the top of the page,
  // because it would overlap with the footer
  const scrollEventCallback = () => {
    if (window.visualViewport.height > 800) {
      return;
    }
    const fullyScrolled =
      window.innerHeight + document.documentElement.scrollTop >=
      document.body.scrollHeight;
    if (fullyScrolled) {
      setStyle({
        position: "absolute",
        top: "80px",
        right: "-320px",
      });
    } else {
      setStyle({});
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEventCallback);
  }, []);

  return (
    <div
      className={"BlueBanner"}
      style={{
        background: `url(${bannerGif})`,
        ...style,
      }}
    />
  );
};
