import axios from "axios";
import { API_URL } from "api/ApiConstants";
import _ from "lodash";

export const PageApiQueryKeys = {
  PAGE: "page",
};

export const getPageData = async ({ queryKey }) => {
  const pageId = queryKey[1];

  const { data } = await axios(`${API_URL}/page?id=${pageId}&lang=hu`);
  data.content = _.sortBy(data.content, "order");
  return data;
};
