import React, { useState } from "react";
import { ShrinkingHeaderWithContent } from "../../organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { BreadcrumbFromPageData } from "../../molecules/Breadcrumb/BreadcrumbFromPageData";
import { Button, useTheme } from "@mui/material";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";
import "./EAccountEmailTemplate.scss";
import _ from "lodash";
import { getClassNameByColor } from "../../../utils/utils";

export const EAccountEmailTemplate = ({ pageData }) => {
  const theme = useTheme();

  const [openQuestion, setOpenQuestion] = useState([]);

  const buttonHandler = (index) => {
    let q = _.cloneDeep(openQuestion);
    q[index] = !openQuestion[index];
    setOpenQuestion(q);
  };

  return (
    <div className={"EAccountEmailTemplate"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={"/e-szamla"}
        text={pageData.address}
        backgroundImage={"sources/pics/fejlecek/eszamla_email.jpg"}
        color={theme.palette.primary.main}
        content={
          <div className={"e-account-email-template-content"}>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content[0].text,
              }}
            />
            {pageData.content?.map((content, index) => {
              if (index === 0) {
                return null;
              }
              return (
                <div className={"faq-item"} key={index}>
                  <h3>{content.address}</h3>
                  {openQuestion[index] && (
                    <div
                      className={getClassNameByColor(
                        theme.palette.primary.main
                      )}
                      dangerouslySetInnerHTML={{ __html: content.text }}
                    />
                  )}
                  <Button
                    color={"default"}
                    type={"text"}
                    onClick={() => buttonHandler(index)}
                  >
                    {openQuestion[index] ? "BEZÁR" : "VÁLASZ"}
                  </Button>
                </div>
              );
            })}
            <BlueBanner />
          </div>
        }
      />
    </div>
  );
};
