import PropTypes from "prop-types";
import "./Header.scss";

export const Header = ({ left, middle, right }) => {
  return (
    <div className={"Header primary-background-color primary-inverse-color"}>
      <div className={"left-content"}>{left}</div>
      <div className={"middle-content"}>{middle}</div>
      <div className={"right-content"}>{right}</div>
    </div>
  );
};

Header.propTypes = {
  /** Left side content */
  left: PropTypes.element,

  /** Middle content */
  middle: PropTypes.element,

  /** Right side content */
  right: PropTypes.element,
};
