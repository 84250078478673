import React from "react";
import { Radio } from "@mui/material";
import PropTypes from "prop-types";
import "./RadioCard.scss";
import theme from "style/variables.module.scss";

export const RadioCard = ({ title, text, checked, value, onChange }) => {
  return (
    <div
      className={"RadioCard" + (checked ? " checked" : "")}
      onClick={() => onChange(value)}
    >
      <Radio
        checked={checked}
        value={value}
        name="radio-buttons"
        inputProps={{ "aria-label": "A" }}
        onClick={() => onChange(value)}
        sx={{
          "&.Mui-checked": {
            color: theme.primary,
          },
        }}
      />
      <div className={"data"}>
        <p>
          <strong>{title}</strong>
        </p>
        <p>
          <em>{text}</em>
        </p>
      </div>
    </div>
  );
};

RadioCard.propTypes = {
  /**
   * Title for the card
   */
  title: PropTypes.string,
  /**
   * Text for the card
   */
  text: PropTypes.string,
  /**
   * Is the radio button checked
   */
  checked: PropTypes.bool,
  /**
   * Handles the click on the radio button
   */
  handleChange: PropTypes.func,
};
