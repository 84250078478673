import React from "react";
import { ShrinkingHeaderWithContent } from "../../organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { PropTypes } from "prop-types";
import DOMPurify from "dompurify";
import "./Partners.scss";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import { useTheme } from "@mui/material";
import { getBackgroundByPageId } from "utils/ResourceMapper";
import { useColorVariant } from "hooks/useColorVariant";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";

export const Partners = ({ pageData }) => {
  const theme = useTheme();

  const { colorVariant } = useColorVariant();
  return (
    <div className={"Partners"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        text={pageData.subPageAddress}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <>
            {pageData.content.map((content, index) => {
              return (
                <div key={index} className={colorVariant + " content-" + index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.text),
                    }}
                  />
                </div>
              );
            })}
          </>
        }
      />
      <BlueBanner />
    </div>
  );
};

Partners.propTypes = {
  pageData: PropTypes.object,
};
