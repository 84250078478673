import React, { useEffect, useState } from "react";
import "./CareerBanner.scss";
import { Link } from "react-router-dom";
import { getPageData } from "api/page/Page";
import { useQuery } from "react-query";
import moment from "moment";
import { useTheme } from "@mui/material";

export const CareerBanner = () => {
  const data = useQuery(["page-data", 40], getPageData).data;
  const theme = useTheme();

  const [style, setStyle] = useState({});

  // if the height of the page is more than 800px, we have to put the banner on the top of the page,
  // because it would overlap with the footer
  const scrollEventCallback = () => {
    if (window.visualViewport.height > 800) {
      return;
    }
    const fullyScrolled =
      window.innerHeight + document.documentElement.scrollTop >=
      document.body.scrollHeight;
    if (fullyScrolled) {
      setStyle({
        position: "absolute",
        top: "80px",
        right: "-350px",
      });
    } else {
      setStyle({});
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEventCallback);
  }, []);

  return (
    <div
      className="CareerBanner"
      style={{
        background: theme.palette.primary.main,
        ...style,
      }}
    >
      <h3>Állásajánlataink</h3>
      {data &&
        data.content.map((job) => {
          if (job.text != null &&
              job.address != null &&
              job.address !== "")
          {
            let available =
                job.availableFrom &&
                job.availableTo &&
                moment().isAfter(job.availableFrom) &&
                moment().isBefore(job.availableTo);
            return (
                <div key={job.id}>
                  {job.id !== 157 &&
                  job.address &&
                  job.active === true &&
                  available && (
                      <Link to={"/allasajanlataink#" + job.id}>
                        <h4 className="more_details">{job.address}</h4>
                      </Link>
                  )}
                </div>
            );
          } else {
            return  null;
          }

        })}
      <img
        className="career_logo"
        src="/media/style_images/karrier_100px-feher.png"
        alt="karrier logo"
      />
    </div>
  );
};

CareerBanner.propTypes = {};
