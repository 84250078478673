import { useLocation } from "react-router-dom";
import { DefaultTheme } from "style/DefaultTheme";
import { PurpleTheme } from "style/PurpleTheme";
import { OrangeTheme } from "style/OrangeTheme";
import { GreenTheme } from "style/GreenTheme";

export const useColorVariant = () => {
  let location = useLocation();

  const colorVariants = {
    "/kezdolap": { color: "purple", menu: "lakossagi", spGroup: 5 },
    "/kezdolap-nem-lakossagi": {
      color: "orange",
      menu: "gazdalkodo",
      spGroup: 6,
    },
    "/kezdolap-tarsashazkezelo": { color: "green", menu: "tarsas", spGroup: 7 },
  };

  const themes = {
    default: DefaultTheme,
    purple: PurpleTheme,
    orange: OrangeTheme,
    green: GreenTheme,
  };

  if (colorVariants[location.pathname]) {
    localStorage.setItem(
      "color-variant",
      JSON.stringify(colorVariants[location.pathname])
    );
  }

  const storedColorVariant =
    JSON.parse(localStorage.getItem("color-variant")) || "default";
  const theme = themes[storedColorVariant.color];

  return {
    colorVariant: storedColorVariant.color,
    menu: storedColorVariant.menu,
    spGroup: storedColorVariant.spGroup,
    theme: theme,
  };
};
