import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  FormControlLabel,
  FormGroup,
  TextField,
  Checkbox,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import "./EAccountForm.scss";
import _ from "lodash";
import { GET_SITE_KEY, validateEmail } from "../../../utils/utils";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  postEAccount,
  getCompanyDetails,
  getCompanyByHash,
} from "../../../api/eAccount/eAccount";
import InfoIcon from "@mui/icons-material/Info";
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorNotification } from "../../molecules/ErrorNotification/ErrorNotification";

const MULTIPLE_PAYMENT_ID_DEFAULT = "asd";

const TAX_NUMBER_TYPES = {
  Eight: "8",
  Nine: "9",
  Ten: "10",
  Eleven: "11",
  ElevenWithDash: "11-",
  TenNoDash: "10--",
};

export const EAccountForm = ({ url }) => {
  const { t } = useTranslation();
  const recaptchaRef = React.useRef();

  const [multiplePayment, setMultiplePayment] = useState([]);
  const [feedBack, setFeedBack] = useState(null);
  const [taxNumber, setTaxNumber] = useState(null);
  const [hash, setHash] = useState(null);
  const [paymentIdNeeded, setPaymentIdNeeded] = useState(true);
  const [formSent, setFormSent] = useState(false);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [serverError, setServerError] = useState(null);

  useQuery(
    "companyDataTaxNumber",
    () => getCompanyDetails(taxNumber).then((res) => companiesLoaded(res)),
    { enabled: taxNumber != null }
  );

  useQuery(
    "companyDataFromHash",
    () => getCompanyByHash(hash).then((res) => companiesLoadedFromHash(res)),
    { enabled: hash != null }
  );

  useEffect(() => {
    let copiedUrl = _.cloneDeep(url);
    if (copiedUrl[0] === "?") {
      copiedUrl = copiedUrl.substring(1);
    }

    if (!copiedUrl.includes("reglink")) {
      return;
    }

    setHash(copiedUrl.split("=")[1]);
  });

  const companiesLoaded = (res) => {
    if (res.status === 200) {
      const data = res.data;
      formik.setFieldValue("email", data.email);
      formik.setFieldValue("clientName", data.name);
    }
  };

  const companiesLoadedFromHash = (res) => {
    if (res.status === 200) {
      const data = res.data;
      if (data.alreadySubmitted) {
        setAlreadySubmitted(true);
      } else {
        formik.setFieldValue("email", data.email);
        formik.setFieldValue("clientName", data.name);
        formik.setFieldValue("taxNumber", data.taxNumber);
        setPaymentIdNeeded(false);
      }
    }
  };

  const sendForm = (values) => {
    console.log(values);
  };

  const validate = (values) => {
    let errors = {};

    checkPaymentId(values, errors);
    checkTaxNumber(values, errors);
    checkEmail(values, errors);
    checkIfUserAcceptedConditions(values, errors);
    checkIfEmailsAreTheSame(values);
    validateEmails(values, errors);

    if (!values.iAcceptTheConditions) {
      errors.iAcceptTheConditions =
        "Kérjük fogadja el a tájékoztatóban foglaltakat!";
    }

    return errors;
  };

  const checkPaymentId = (values, errors) => {
    if (paymentIdNeeded) {
      let errorMsg = validatePaymentId(values.paymentId);
      if (errorMsg != null) {
        errors.paymentId = errorMsg;
      }

      if (values.multiplePaymentId === "new") {
        values.multiplePaymentPaymentId.forEach((id, index) => {
          let msg = validatePaymentId(id);
          if (msg != null) {
            if (errors.multiplePaymentPaymentId == null) {
              errors.multiplePaymentPaymentId = [];
            }
            errors.multiplePaymentPaymentId[index] = msg;
          }
        });
      }
    }
  };

  const validatePaymentId = (id) => {
    if (!id || id === "") {
      return "Kérjük adja meg a fizetőazonosítót!";
    } else {
      if (id.toString().charAt(0) === "" || id.toString().charAt(0) !== "5") {
        return "A fizetőazonosító mindig 5-ös számmal kezdődik!";
      }
      if (id.toString().length !== 9) {
        return "A fizetőazonosító 9 számjegyből áll!";
      }
    }
    return null;
  };

  const checkTaxNumber = (values, errors) => {
    if (!values.taxNumber || values.taxNumber === "") {
      errors.taxNumber = "Kérjük adja meg az adószámot!";
    } else {
      if (values.taxNumber.length !== 13) {
        errors.taxNumber = "Az adószám 11 számjegyből áll!";
      }
    }
  };
  const checkEmail = (values, errors) => {
    if (!values.email || values.email === "") {
      errors.email = "Kérjük adja meg az e-mail címet!";
    }
    if (
      values.multiplePaymentId === "new" &&
      !values.everyAccountForTheSameEmail
    ) {
      values.multiplePaymentEmail.forEach((email, index) => {
        checkMultiplePaymentEmail(email, index, errors);
      });
    }
  };
  const checkMultiplePaymentEmail = (email, index, errors) => {
    if (!email || email === "") {
      if (errors.multiplePaymentEmail == null) {
        errors.multiplePaymentEmail = [];
      }
      errors.multiplePaymentEmail[index] = "Kérjük adja meg az e-mail címet!";
    } else if (!validateEmail(email)) {
      if (errors.multiplePaymentEmail == null) {
        errors.multiplePaymentEmail = [];
      }
      errors.multiplePaymentEmail[index] = "Helytelen e-mail cím!";
    }
  };
  const checkIfUserAcceptedConditions = (values, errors) => {
    if (!values.iAcceptTheConditions) {
      errors.iAcceptTheConditions = "Kérjük fogadja el a feltételeket!";
    }
  };
  const checkIfEmailsAreTheSame = (values) => {
    if (values.emailsAreTheSame) {
      values.secondaryEmail = values.email;
    }
  };

  const validateEmails = (values, errors) => {
    if (
      values.email != null &&
      values.email !== "" &&
      !validateEmail(values.email)
    ) {
      errors.email = "Helytelen e-mail!";
    }
  };

  const deleteMultiplePaymentId = () => {
    setMultiplePayment([]);
    formik.setFieldValue("multiplePaymentPaymentId", []);
    formik.setFieldValue("multiplePaymentEmail", []);
    formik.setFieldValue("multiplePaymentId", MULTIPLE_PAYMENT_ID_DEFAULT);
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      paymentId: "",
      taxNumber: "",
      clientName: "",
      phoneNumber: "",
      email: "",
      emailsAreTheSame: false,
      secondaryEmail: "",
      multiplePaymentId: "",
      everyAccountForTheSameEmail: true,
      multiplePaymentPaymentId: [],
      multiplePaymentEmail: [],
      iWantToBeContacted: false,
      iAcceptTheConditions: false,
    },
    onSubmit: sendForm,
    validate: validate,
  });

  const touchFields = (fields) => {
    fields.forEach((field) => {
      formik.setFieldTouched(field);
    });
  };

  const postEAccountMutation = useMutation(postEAccount);

  const send = async () => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const data = _.cloneDeep(formik.values);
    if (!data.multiplePaymentId) {
      data.everyAccountForTheSameEmail = null;
      data.multiplePaymentEmail = null;
      data.multiplePaymentPaymentId = null;
    } else if (data.everyAccountForTheSameEmail) {
      data.multiplePaymentEmail = [];
    }
    postEAccountMutation
      .mutateAsync({ data: data, token: token })
      .then((response) => {
        if (response.status === 200) {
          setFeedBack(true);
          setFormSent(true);
          emptyForm();
        } else {
          setFeedBack(false);
        }
      })
      .catch(() => {
        setServerError(t("eAccountForm.serviceUnavailable"));
      });
  };

  const emptyForm = () => {
    formik.setFieldValue("paymentId", "");
    formik.setFieldValue("taxNumber", "");
    formik.setFieldValue("clientName", "");
    formik.setFieldValue("phoneNumber", "");
    formik.setFieldValue("email", "");
    formik.setFieldValue("emailsAreTheSame", false);
    formik.setFieldValue("secondaryEmail", "");
    formik.setFieldValue("multiplePaymentId", "");
    formik.setFieldValue("everyAccountForTheSameEmail", true);
    formik.setFieldValue("multiplePaymentPaymentId", []);
    formik.setFieldValue("multiplePaymentEmail", []);
    formik.setFieldValue("iWantToBeContacted", false);
    formik.setFieldValue("iAcceptTheConditions", false);
  };

  const incrementMultiplePayment = () => {
    let mp = _.cloneDeep(multiplePayment);
    mp.push(0);
    setMultiplePayment(mp);
    formik.values.multiplePaymentPaymentId.push("");
    formik.values.multiplePaymentEmail.push("");
  };

  const decrementMultiplePayment = () => {
    let mp = _.cloneDeep(multiplePayment);
    mp.pop();
    setMultiplePayment(mp);
    formik.values.multiplePaymentPaymentId.pop();
    formik.values.multiplePaymentEmail.pop();
  };

  let feedBackBody;
  if (feedBack === true) {
    feedBackBody = (
      <div className={"positive"} onClick={() => setFeedBack(null)}>
        Üzenet sikeresen elküldve!
      </div>
    );
  }
  if (feedBack === false) {
    feedBackBody = (
      <div className={"negative"} onClick={() => setFeedBack(null)}>
        Üzenet küldése sikertelen volt!
      </div>
    );
  }

  const handleTaxNumberChange = (e) => {
    let newNumber = e.target.value.toString();
    if (newNumber.length > formik.values.taxNumber.length) {
      if (newNumber.length > 7) {
        switch (newNumber.length) {
          case 8: {
            newNumber = formatTaxNumber(newNumber, TAX_NUMBER_TYPES.Eight);
            break;
          }
          case 9: {
            newNumber = formatTaxNumber(newNumber, TAX_NUMBER_TYPES.Nine);
            break;
          }
          case 10: {
            if (!newNumber.includes("-")) {
              newNumber = formatTaxNumber(
                newNumber,
                TAX_NUMBER_TYPES.TenNoDash
              );
            } else {
              newNumber = formatTaxNumber(newNumber, TAX_NUMBER_TYPES.Ten);
            }
            break;
          }
          case 11: {
            newNumber = formatTaxNumber(newNumber, TAX_NUMBER_TYPES.Eleven);
            break;
          }
          default: {
            if (newNumber.length > 11 && !newNumber.includes("-")) {
              newNumber = formatTaxNumber(newNumber, TAX_NUMBER_TYPES.Eleven);
            }
          }
        }
      }
    } else {
      if (newNumber.length === 8 || newNumber.length === 10) {
        newNumber = newNumber.slice(0, newNumber.length - 1);
      }
    }
    formik.setFieldValue("taxNumber", newNumber);
    if (newNumber.length === 13) {
      setTaxNumber(newNumber);
      setTimeout(() => {
        setTaxNumber(null);
      }, 500);
    }
  };

  const formatTaxNumber = (number, type) => {
    switch (type) {
      case TAX_NUMBER_TYPES.Eight:
      case TAX_NUMBER_TYPES.Ten: {
        return number.concat("-");
      }
      case TAX_NUMBER_TYPES.Eleven: {
        return number
          .slice(0, 8)
          .concat("-")
          .concat(number[8])
          .concat("-")
          .concat(number.slice(9, 11));
      }
      case TAX_NUMBER_TYPES.TenNoDash: {
        return number.slice(0, 8).concat("-" + number[8] + "-" + number[9]);
      }
      case TAX_NUMBER_TYPES.Nine: {
        return number.slice(0, 8).concat("-").concat(number[8]);
      }
      default: {
        return number;
      }
    }
  };

  const resetServerError = () => {
    setServerError(null);
  };

  if (formSent) {
    return (
      <div className={"EAccountForm"}>
        <div className={"feedback"}>{feedBackBody}</div>
      </div>
    );
  }

  if (alreadySubmitted) {
    return (
      <div className={"EAccountForm"}>
        <h1>
          Ezen a linken keresztül az e-számla igény már benyújtásra került.
        </h1>
      </div>
    );
  }

  const iAcceptConditionsLabel = () => {
    return (
      <span>
        {t("eAccountPage.iAcceptTheConditions1")}{" "}
        <a href="media/files/pdf/elektronikus_szamlakuldes_tajekoztato_20211111.pdf">
          tájékoztatóban
        </a>{" "}
        {t("eAccountPage.iAcceptTheConditions2")}
      </span>
    );
  };

  return (
    <div className={"EAccountForm"}>
      <div className={"upper"}>
        <h4>{t("eAccountForm.giveDetails")}</h4>
        <h5>
          {t("eAccountForm.checkAutoFilledFields")}
          <Link to={"/idopontfoglalas-es-elerhetosegek"}>
            {" ügyfélszolgálatunkhoz"}
          </Link>
          .
        </h5>
        <h5>{t("eAccountForm.fieldsWithStarsAreRequired")}</h5>
      </div>
      <div className={"form-wrapper"}>
        {paymentIdNeeded && (
          <TextField
            id={"paymentId"}
            name={"paymentId"}
            color={"default"}
            label={t("eAccountPage.paymentId")}
            variant={"standard"}
            fullWidth={true}
            inputProps={{ required: true, maxLength: 9 }}
            value={formik.values.paymentId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.paymentId && formik.errors.paymentId}
            helperText={formik.errors.paymentId}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <Tooltip title={t("eAccountForm.tooltip.paymentId")}>
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        )}
        <TextField
          id={"taxNumber"}
          name={"taxNumber"}
          color={"default"}
          label={t("eAccountPage.taxNumber")}
          variant={"standard"}
          fullWidth={true}
          inputProps={{ required: true, maxLength: 13 }}
          value={formik.values.taxNumber}
          onChange={handleTaxNumberChange}
          onBlur={formik.handleBlur}
          error={formik.touched.taxNumber && formik.errors.taxNumber}
          helperText={formik.errors.taxNumber}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <Tooltip title={t("eAccountForm.tooltip.taxNumber")}>
                  <InfoIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id={"clientName"}
          name={"clientName"}
          color={"default"}
          label={t("eAccountPage.clientName")}
          variant={"standard"}
          fullWidth={true}
          value={formik.values.clientName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <Tooltip title={t("eAccountForm.tooltip.clientName")}>
                  <InfoIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id={"phoneNumber"}
          name={"phoneNumber"}
          color={"default"}
          label={t("eAccountPage.phoneNumber")}
          variant={"standard"}
          fullWidth={true}
          inputProps={{ required: true }}
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phoneNumber && formik.errors.phoneNumber}
          helperText={formik.errors.phoneNumber}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <Tooltip title={t("eAccountForm.tooltip.phoneNumber")}>
                  <InfoIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id={"email"}
          name={"email"}
          color={"default"}
          label={t("eAccountPage.email")}
          variant={"standard"}
          fullWidth={true}
          inputProps={{ required: true }}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
          helperText={formik.errors.email}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <Tooltip title={t("eAccountForm.tooltip.email")}>
                  <InfoIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id={"emailsAreTheSame"}
                name={"emailsAreTheSame"}
                color={"default"}
                checked={formik.values.emailsAreTheSame}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label={t("eAccountPage.emailsAreTheSame")}
          />
        </FormGroup>
        <TextField
          id={"secondaryEmail"}
          name={"secondaryEmail"}
          color={"default"}
          label={t("eAccountPage.secondaryEmail")}
          variant={"standard"}
          fullWidth={true}
          disabled={formik.values.emailsAreTheSame}
          value={
            formik.values.emailsAreTheSame
              ? formik.values.email
              : formik.values.secondaryEmail
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <Tooltip title={t("eAccountForm.tooltip.secondaryEmail")}>
                  <InfoIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <FormGroup>
          <FormControlLabel
            label={""}
            control={
              <>
                <FormLabel component="legend">
                  {t("eAccountForm.multiplePaymentId.iHaveMultiplePaymentId")}
                </FormLabel>
                <RadioGroup
                  aria-label="multiplePaymentId"
                  name="multiplePaymentId"
                  defaultValue={""}
                  onChange={formik.handleChange}
                  value={formik.values.multiplePaymentId}
                >
                  <FormControlLabel
                    value="existing"
                    control={<Radio />}
                    label={t("eAccountForm.multiplePaymentId.everyInvoice")}
                  />
                  <FormControlLabel
                    value="new"
                    control={<Radio />}
                    label={t("eAccountForm.multiplePaymentId.someInvoice")}
                  />
                </RadioGroup>
                <Button
                  className={"to-right"}
                  variant={"text"}
                  color={"default"}
                  onClick={deleteMultiplePaymentId}
                >
                  Törlés
                  <ArrowForwardIosIcon />
                </Button>
              </>
            }
          />
        </FormGroup>

        {formik.values.multiplePaymentId === "new" && (
          <>
            <FormGroup>
              <FormControlLabel
                className={"more-margin"}
                control={
                  <Checkbox
                    id={"everyAccountForTheSameEmail"}
                    name={"everyAccountForTheSameEmail"}
                    color={"default"}
                    checked={formik.values.everyAccountForTheSameEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label={t("eAccountPage.everyAccountForTheSameEmail")}
              />
            </FormGroup>
            <div className={"buttons-container"}>
              <Button color={"default"} onClick={incrementMultiplePayment}>
                <AddIcon />
              </Button>
              {multiplePayment.length > 0 && (
                <Button color={"default"} onClick={decrementMultiplePayment}>
                  <RemoveIcon />
                </Button>
              )}
            </div>

            {formik.values.multiplePaymentPaymentId?.map((x, index) => {
              return (
                <div className={"multiple"} key={index}>
                  <TextField
                    className={"more-margin"}
                    id={"multiplePaymentPaymentId[" + index + "]"}
                    name={"multiplePaymentPaymentId[" + index + "]"}
                    color={"default"}
                    label={t("eAccountPage.multiplePaymentPaymentId")}
                    variant={"standard"}
                    fullWidth={true}
                    value={formik.values.multiplePaymentPaymentId[index]}
                    inputProps={{ maxLength: 9 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.multiplePaymentPaymentId != null &&
                      formik.errors.multiplePaymentPaymentId[index] != null
                        ? formik.errors.multiplePaymentPaymentId[index]
                        : null
                    }
                    helperText={
                      formik.errors.multiplePaymentPaymentId != null
                        ? formik.errors.multiplePaymentPaymentId[index]
                        : null
                    }
                  />
                  {!formik.values.everyAccountForTheSameEmail && (
                    <TextField
                      className={"more-margin"}
                      id={"multiplePaymentEmail[" + index + "]"}
                      name={"multiplePaymentEmail[" + index + "]"}
                      color={"default"}
                      label={t("eAccountPage.multiplePaymentEmail")}
                      variant={"standard"}
                      fullWidth={true}
                      value={formik.values.multiplePaymentEmail[index]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.multiplePaymentEmail != null
                          ? formik.errors.multiplePaymentEmail[index]
                          : null
                      }
                      helperText={formik.errors.multiplePaymentEmail}
                      disabled={formik.values.everyAccountForTheSameEmail}
                    />
                  )}
                </div>
              );
            })}
          </>
        )}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id={"iWantToBeContacted"}
                name={"iWantToBeContacted"}
                color={"default"}
                checked={formik.values.iWantToBeContacted}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label={t("eAccountPage.iWantToBeContacted")}
          />
          <FormControlLabel
            control={
              <Checkbox
                id={"iAcceptTheConditions"}
                name={"iAcceptTheConditions"}
                color={"default"}
                checked={formik.values.iAcceptTheConditions}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ required: true }}
              />
            }
            label={iAcceptConditionsLabel()}
          />
          {formik.errors.iAcceptTheConditions != null && (
            <FormHelperText>
              {formik.errors.iAcceptTheConditions}
            </FormHelperText>
          )}
        </FormGroup>
        <Button
          className={"send-button"}
          variant={"text"}
          color={"default"}
          onClick={() => {
            touchFields([
              "paymentId",
              "taxNumber",
              "clientName",
              "email",
              "emailsAreTheSame",
              "secondaryEmail",
              "phoneNumber",
              "everyAccountForTheSameEmail",
              "multiplePaymentPaymentId",
              "multiplePaymentEmail",
              "iWantToBeContacted",
              "iAcceptTheConditions",
            ]);
            formik.validateForm().then((result) => {
              if (_.isEmpty(result)) {
                send();
              }
            });
          }}
        >
          {t("eAccountPage.send")}
          <ArrowForwardIosIcon />
        </Button>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={GET_SITE_KEY()}
          size="invisible"
        />
        {serverError && (
          <ErrorNotification
            open={!!serverError}
            onClose={resetServerError}
            message={serverError}
          />
        )}
      </div>
      {feedBack != null && <div className={"feedback"}>{feedBackBody}</div>}
    </div>
  );
};
