import "./PageLayout.scss";
import PropTypes from "prop-types";

export const PageLayout = ({ header, content, footer, menu }) => {
  return (
    <div className={"PageLayout"}>
      {header}
      <div className={"content"}>
        {menu}
        <div className={"content-row"}>
          <div id={"content-container"} className={"content-container"}>
            {content}
          </div>
          <div className={"footer"}>{footer}</div>
        </div>
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  /** Header component */
  header: PropTypes.element,
  /** Content component */
  content: PropTypes.element,
  /** Footer component */
  footer: PropTypes.element,
  /** Menu component */
  menu: PropTypes.element,
  /** Height of the header in pixels. */
  headerHeight: PropTypes.number,
};
