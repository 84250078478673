import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  postPayerIdentifier,
  getPayerIdentifier,
} from "api/meterReading/MeterReading";
import moment from "moment";
import { useFormik } from "formik";
import DOMPurify from "dompurify";
import { useMutation } from "react-query";
import ReCAPTCHA from "react-google-recaptcha";
import { GET_SITE_KEY } from "../../../utils/utils";
import { ErrorNotification } from "../../molecules/ErrorNotification/ErrorNotification";

export const MeterReadingForm = () => {
  const { t } = useTranslation();
  const recaptchaRef = React.useRef();

  const [step, setStep] = useState(1);
  const [showReading, setShowReading] = useState(false);
  const [paymentElements, setPaymentElements] = useState({});
  const [paymentErrors, setPaymentErrors] = useState();
  const [getMeterError, setGetMeterError] = useState();
  const [serverError, setServerError] = useState(null);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      paymentId: "",
      paymentIdLength: "",
    },
    onSubmit: () => {
      switch (step) {
        case 1:
          submitMeter();
          break;
        case 2:
          sendReadingValues();
          break;
        default:
          break;
      }
    },
    validate: (values) => {
      let errors = {};
      switch (step) {
        case 1:
          if (values.paymentId.length === 0) {
            errors.paymentId = "Kérjük adja meg azonosítóját!";
            break;
          }

          if (
            values.paymentId.charAt(0) === "" ||
            (values.paymentId.charAt(0) !== "5" && !values.paymentId.startsWith("103"))
          ) {
            errors.paymentId =
              "A fizető/felhasználó azonosító 5-tel vagy 103-mal kezdődik!";
          }

          if (values.paymentId.length < 9) {
            errors.paymentIdLength = "A fizető/felhasználó azonosító 9 számjegyből áll!";
          }
          break;
        case 2:
          for (let i = 0; i < paymentElements.meters.length; i++) {
            if (!values["readingValue" + i]) {
              errors.readingValue = "Nem adott meg mérőállást.";
              break;
            }
          }
          break;
        default:
          break;
      }

      return errors;
    },
  });

  const newPaymentId = () => {
    setStep(1);
    setPaymentErrors("");
    setGetMeterError("");
    formik.setFieldValue("paymentId", "");
  };

  const sendReadingValues = async () => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    let readingValues = [];
    paymentElements.meters.forEach((payment, index) => {
      readingValues.push({
        measurementLocationId: payment.measurementLocationId,
        serialNumberOfMeter: payment.serialNumberOfMeter,
        actualMeterValue: formik.values["readingValue" + index],
      });
    });

    const result = await postPayerIdentifierMutation.mutateAsync({
      payerIdentifierNumbers: formik.values.paymentId,
      payload: readingValues,
      token: token,
    });
    if (result) {
      setPaymentErrors(result);
    }
  };

  const getPayerIdentifierMutation = useMutation(getPayerIdentifier);
  const postPayerIdentifierMutation = useMutation(postPayerIdentifier);

  const submitMeter = async () => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const result = await getPayerIdentifierMutation.mutateAsync({
      paymentId: formik.values.paymentId,
      token: token,
    });

    if (result == null || result.status === 503) {
      setServerError(t("meterReading.serviceUnavailable"));
    }

    if (
      result &&
      result.meters &&
      result.meters.length > 0 &&
      result.ableToCommit
    ) {
      setStep(2);
      setPaymentElements(result);
      setShowReading(result.ableToCommit);
      setGetMeterError("");
    } else {
      if (result && result.message && !result.ableToCommit) {
        setGetMeterError(result.message);
      } else {
        setGetMeterError("Nem találtunk adatokat ehhez a fizetőazonosítóhoz.");
      }
    }
  };

  const resetServerError = () => {
    setServerError(null);
  };

  return (
    <>
      <div className={"MeterReadingForm"}>
        {step === 1 && (
          <div className="reading-wrapper">
            <form>
              <div className="reading">
                <TextField
                  id="paymentId"
                  color="default"
                  label={t("meterReadingForm.paymentId")}
                  variant="standard"
                  fullWidth={true}
                  inputProps={{ maxLength: 9 }}
                  value={formik.values.paymentId}
                  onChange={formik.handleChange}
                  error={formik.errors.paymentId}
                />
              </div>
            </form>
            <div className={"button-wrapper"}>
              <Button onClick={() => formik.submitForm()}>
                {t("meterReadingForm.send")} <ArrowForwardIosIcon />
              </Button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            {showReading && (
              <div
                className="give-readings"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(paymentElements.message),
                }}
              />
            )}
            <p className="no-value-error">{formik.errors.readingValue}</p>
            <div className="give-reading-values-wrapper">
              <form>
                {paymentElements.meters &&
                  paymentElements.meters.length > 0 &&
                  paymentElements.meters.map((paymentElem, index) => {
                    return (
                      <div className={"meter-reading"}>
                        <div className={"wrapper"}>
                          <div className="payment-id">
                            {paymentElem.serialNumberOfMeter + " számú mérő"}
                          </div>
                          <div className="meter-reading-value">
                            {"(utolsó elszámolt mérőállás: " +
                              paymentElem.previousMeterValue +
                              ", " +
                              moment(paymentElem.previousCommitDateTime).format(
                                "YYYY.MM.DD"
                              ) +
                              ")"}
                          </div>
                          {paymentElements.ableToCommit && (
                            <TextField
                              id={"readingValue" + index}
                              color="default"
                              variant="standard"
                              fullWidth={true}
                              value={formik.values["readingValue" + index]}
                              onChange={formik.handleChange}
                              inputProps={{ required: true }}
                            />
                          )}
                          {paymentErrors &&
                            paymentErrors.length > 0 &&
                            paymentErrors[index] &&
                            paymentErrors[index].message && (
                              <div
                                className={
                                  paymentErrors[index].actualMeterValueAccepted
                                    ? "done"
                                    : "error"
                                }
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    paymentErrors[index].message
                                  ),
                                }}
                              />
                            )}
                        </div>
                      </div>
                    );
                  })}

                <div className={"button-wrapper"}>
                  {paymentElements.ableToCommit && (
                    <Button
                      id={"send_form"}
                      onClick={() => {
                        setShowReading(false);
                        formik.submitForm();
                      }}
                    >
                      {t("meterReadingForm.send")} <ArrowForwardIosIcon />
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      newPaymentId();
                    }}
                  >
                    {t("meterReadingForm.newPaymentId")} <ArrowForwardIosIcon />
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className="infos-errors">
        <div className={"small-font"}>
          <p>{t("payment.oneStar")}</p>
        </div>

        <div className="errors">
          <p className="error">{formik.errors.paymentId}</p>
          <p className="error">{formik.errors.paymentIdLength}</p>
          <div
            className="error"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(getMeterError),
            }}
          />
        </div>
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={GET_SITE_KEY()} size="invisible" />
      {serverError && (
        <ErrorNotification
          open={!!serverError}
          onClose={resetServerError}
          message={serverError}
        />
      )}
    </>
  );
};
