import PropTypes from "prop-types";
import "./Footer.scss";

export const Footer = ({ left, right }) => {
  return (
    <div className={"Footer primary-background-color primary-inverse-color"}>
      <div className={"left-content"}>{left}</div>
      <div className={"right-content"}>{right}</div>
    </div>
  );
};

Footer.propTypes = {
  /** Left side content */
  left: PropTypes.element,

  /** Right side content */
  right: PropTypes.element,
};
