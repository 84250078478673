import React, { useState } from "react";
import "./ActualitiesPage.scss";
import { ActualityCard } from "components/organisms/ActualityCard/ActualityCard";
import { Pagination } from "@mui/material";
import { useColorVariant } from "hooks/useColorVariant";
import { Subpage } from "components/fragments/Subpage/Subpage";
import { BlogApiQueryKeys, getBlog } from "api/blog/Blog";
import { useQuery } from "react-query";
import DOMPurify from "dompurify";
import { getUrls, UrlsApiQueryKeys } from "api/urls/Urls";
import _ from "lodash";
import { Helmet } from "react-helmet";

export const ActualitiesPage = () => {
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("actualitiesPageNumber")) || 1
  );

  const pageSize = 5;
  const { data: urls } = useQuery(UrlsApiQueryKeys.URLS, getUrls);

  let pageId =
    urls && Array.isArray(urls)
      ? _.find(urls, { url: "aktualitasok" }).pageId
      : false;

  const { data: blogEntries } = useQuery(
    [BlogApiQueryKeys.BLOG, (currentPage - 1) * pageSize, pageSize, pageId],
    getBlog,
    {
      enabled: !!pageId,
    }
  );
  const { colorVariant } = useColorVariant();

  return (
    <Subpage
      className={colorVariant + "-variant"}
      content={
        <div className={"ActualitiesPage"}>
          <Helmet>
            <title>Aktualitások | DBRT</title>
          </Helmet>
          <h1 className={"primary-color"}>Aktualitások</h1>
          {blogEntries && (
            <>
              <div className={"content"}>
                {blogEntries.data.map((data, index) => {
                  return (
                    <ActualityCard
                      key={index}
                      title={data.address}
                      text={
                        <>
                          {data.postMainPics && (
                            <img
                              className="main-pic"
                              src={
                                "media/pics/post_main_pics/" + data.postMainPics
                              }
                              alt={"main-pic"}
                            />
                          )}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(data.text),
                            }}
                          />
                        </>
                      }
                      borderNeeded={true}
                      navigateTo={
                        _.filter(urls, {
                          pageId: data.id,
                          type: "blog_more",
                        })[0]?.url
                      }
                    />
                  );
                })}
              </div>
              <div className={"pagination-wrapper"}>
                <Pagination
                  count={Math.ceil(blogEntries.itemCount / pageSize)}
                  defaultPage={currentPage}
                  page={currentPage}
                  onChange={(event, page) => {
                    setCurrentPage(page);
                    localStorage.setItem(
                      "actualitiesPageNumber",
                      page.toString()
                    );
                  }}
                  variant={"outlined"}
                />
              </div>
            </>
          )}
        </div>
      }
    />
  );
};
