import {
  Autocomplete,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { search, SearchApiQueryKeys } from "api/search/Search";
import _ from "lodash";
import { Paths } from "routing/Paths";
import { useHistory } from "react-router-dom";
import { Search } from "@mui/icons-material";
import "./SearchBox.scss";
import { useColorVariant } from "hooks/useColorVariant";

const SMALL_WIDTH = 70;

export const SearchBox = ({ placeHolder, isMenu }) => {
  let history = useHistory();
  const { spGroup } = useColorVariant();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [width, setWidth] = useState(SMALL_WIDTH);
  const { data: options } = useQuery(
    [SearchApiQueryKeys.SEARCH, searchValue, true, spGroup],
    search,
    {
      enabled: Boolean(searchValue),
    }
  );

  const debouncedSetSearchValue = useMemo(
    () => _.debounce(setSearchValue, 500),
    []
  );

  let placeHolderText = "Keresés".toUpperCase();
  if (placeHolder != null) {
    placeHolderText = placeHolder;
  } else if (sm) {
    placeHolderText = "Keresés az oldalon".toUpperCase();
  }

  const opened = () => {
    setWidth(300);
  };

  const closed = () => {
    setWidth(SMALL_WIDTH);
  };

  return (
    <div className={"SearchBox" + (isMenu ? " menu" : "")}>
      <Autocomplete
        id="search"
        sx={!sm && !isMenu ? { width: width } : { width: 300 }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options || []}
        autoComplete
        freeSolo
        includeInputInList
        filterSelectedOptions
        value={inputValue}
        onChange={(event, newValue) => {
          setInputValue(newValue?.pageTitle);
          newValue &&
            history.push(Paths.SEARCH_RESULTS, {
              searchValue: newValue.pageTitle,
            });
        }}
        onOpen={opened}
        onClose={closed}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          debouncedSetSearchValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            onKeyPress={(e) => {
              if (e.nativeEvent.code === "Enter") {
                history.push(Paths.SEARCH_RESULTS, {
                  searchValue: inputValue,
                });
              }
            }}
            {...params}
            placeholder={sm ? placeHolderText : null}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              placeholder: placeHolderText,
            }}
          />
        )}
        renderOption={(props, option) => {
          return <li {...props}>{option.pageTitle}</li>;
        }}
      />
    </div>
  );
};
