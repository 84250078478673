import axios from "axios";
import { API_URL } from "api/ApiConstants";

export const UrlsApiQueryKeys = {
  URLS: "urls",
};

export const getUrls = async () => {
  const { data } = await axios(`${API_URL}/urls?lang=hu`);
  return data;
};
