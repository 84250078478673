import React from "react";
import { ShrinkingHeaderWithContent } from "../../organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import { PropTypes } from "prop-types";
import DOMPurify from "dompurify";
import "./ServiceCharges.scss";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import { useTheme } from "@mui/material";
import { getBackgroundByPageId } from "utils/ResourceMapper";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";

export const ServiceCharges = ({ pageData }) => {
  const theme = useTheme();

  return (
    <div className={"ServiceCharges"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        text={pageData.subPageAddress}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <>
            {pageData.content.map((content, index) => {
              return (
                <div key={index} className={"content-" + index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.text),
                    }}
                  />
                </div>
              );
            })}
          </>
        }
      />
      <BlueBanner />
    </div>
  );
};

ServiceCharges.propTypes = {
  pageData: PropTypes.object,
};
