import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./ContactUsForm.scss";
import {
  FormControlLabel,
  TextField,
  Checkbox,
  Button,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import dijnetLogo from "resources/inline_dijnet_logo_2018_png.png";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { PopoverInfoIcon } from "components/molecules/PopoverInfoIcon/PopoverInfoIcon";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import _ from "lodash";
import {
  contactUs,
  getCategories,
  getCity,
} from "../../../api/contactUs/Contactus";
import { Paths } from "../../../routing/Paths";
import { useFormik } from "formik";
import { validateEmail } from "../../../utils/utils";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useQuery } from "react-query";
import CancelIcon from "@mui/icons-material/Cancel";

export const ContactUsForm = ({ complaint }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [feedBack, setFeedBack] = useState(null);
  const [files, setFiles] = useState([]);
  const [catError, setCatError] = useState(false);
  const [postCode, setPostCode] = useState(null);
  const [postCodeMailing, setPostCodeMailing] = useState(null);
  const [fileTooLarge, setFileTooLarge] = useState(null);
  const [fileSizeWarning, setFileSizeWarning] = useState(null);

  useQuery("postCode", () => getCity(postCode).then((res) => cityLoaded(res)), {
    enabled: postCode != null,
    retry: false,
  });

  useQuery(
    "postCodeMailing",
    () => getCity(postCodeMailing).then((res) => cityLoadedMailing(res)),
    {
      enabled: postCodeMailing != null,
    }
  );

  const setMaxLengthForEvent = (e, max) => {
    if (e.target.value.toString().length > max) {
      e.target.value = e.target.value.toString().substring(0, max);
    }
  };

  const sanitizeNumberInput = (e) => {
    e.target.value = e.target.value.replace(/\D/g, "");
  };

  const { data: categories } = useQuery("categories", getCategories, {
    retry: 0,
    onError: () => {
      setCatError(true);
    },
  });

  const dragAndDrop = useRef();
  const fileInputRef = useRef();

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      declarerName: "",
      consumerName: "",
      paymentId: "",
      email: "",
      phoneNum: "",
      postcode: "",
      city: "",
      district: "",
      spaceType: "",
      houseNum: "",
      floor: "",
      door: "",
      mailingAddressEqualsConsumptionAddress: false,
      consentForApplyingCost: false,
      emailAndTelephoneConsent: false,
      sendMeDetailedInfo: false,
      category: "",
      issueDetails: "",
      preRegistration: false,
      cityMailing: "",
      districtMailing: "",
    },
    onSubmit: (values) => {
      sendForm(values);
    },
    validate: (values) => {
      let errors = {};
      switch (pageNumber) {
        case 1:
          if (!values.declarerName) {
            errors.declarerName = "Kérjük, adja meg a bejelentő nevét!";
          }

          if (!values.consumerName) {
            errors.consumerName = "Kérjük, adja meg a fogyasztó nevét!";
          }

          if (values.paymentId) {
            if (
              values.paymentId.toString().charAt(0) === "" ||
              values.paymentId.toString().charAt(0) !== "5"
            ) {
              errors.paymentId =
                "A fizetőazonosító mindig 5-ös számmal kezdődik!";
            }

            if (values.paymentId.toString().length !== 9) {
              errors.paymentId = "A fizetőazonosító 9 számjegyből áll!";
            }
          }

          if ((values.email && !validateEmail(values.email)) || !values.email) {
            errors.email = "Kérjük, adja meg az e-mail címét!";
          }

          if (!values.postcode) {
            errors.postcode = "Kérjük, adja meg az irányítószámot!";
          }
          if (
            values.postcode != null &&
            values.postcode.toString().length < 4
          ) {
            errors.postcode = "Helytelen irányítószám!";
          }
          if (!values.city) {
            errors.city = "Kérjük, adja meg a várost!";
          }
          if (!values.spaceType) {
            errors.spaceType = "Kérjük, adja meg az utcát!";
          }
          if (!values.houseNum) {
            errors.houseNum = "Kérjük, adja meg a házszámot!";
          }
          break;
        case 2:
          if (!values.postcodeMailing) {
            errors.postcodeMailing = "Kérjük, adja meg az irányítószámot!";
          }
          if (
            values.postcodeMailing != null &&
            values.postcodeMailing.toString().length < 4
          ) {
            errors.postcodeMailing = "Helytelen irányítószám!";
          }

          if (!values.cityMailing) {
            errors.cityMailing = "Kérjük, adja meg a várost!";
          }

          if (!values.spaceTypeMailing) {
            errors.spaceTypeMailing = "Kérjük, adja meg az utcát!";
          }

          if (!values.houseNumMailing) {
            errors.houseNumMailing = "Kérjük, adja meg a házszámot!";
          }
          break;
        case 3:
          if (!values.consentForApplyingCost) {
            errors.consentForApplyingCost =
              "Hozzá kell járulnia az esetleges költségek felszámításához!";
          }
          if (values.category == null || values.category === "") {
            errors.category = "Válasszon legalább egy kategóriát!";
          }
          if (!values.issueDetails) {
            errors.issueDetails = "Írja meg a bejelentést!";
          }
          break;
        default:
          break;
      }

      return errors;
    },
  });

  const touchFields = (fields) => {
    fields.forEach((field) => {
      formik.setFieldTouched(field);
    });
  };

  const sendForm = (data) => {
    let formData = new FormData();
    if (files.length > 0) {
      files.forEach((f) => {
        formData.append("attachments", f);
      });
    }
    for (let prop in data) {
      if (data[prop] != null && data[prop] !== "") {
        formData.append(prop, data[prop]);
      }
    }
    if (data.mailingAddressEqualsConsumptionAddress) {
      // we need to do this, because the FormData interface converts null values to a string that cotains "null",
      // so we don't want to add the property to the FormData, where the value is null

      const properties = [
        ["city", "cityMailing"],
        ["district", "districtMailing"],
        ["door", "doorMailing"],
        ["floor", "floorMailing"],
        ["houseNum", "houseNumMailing"],
        ["postcode", "postcodeMailing"],
        ["spaceType", "spaceTypeMailing"],
      ];

      properties.forEach((pair) => {
        if (data[pair[0]] != null && data[pair[0]] !== "") {
          formData.append(pair[1], data[pair[0]]);
        }
      });
    }

    formData.set("category", formik.values.category);

    contactUs(formData)
      .then((result) => {
        setFileTooLarge(false);
        if (result.status === 200) {
          setFeedBack(true);
        } else {
          setFeedBack(false);
        }
        if (result) {
          setPageNumber(1);
          formik.resetForm();
          setFiles([]);
        }
      })
      .catch((error) => {
        if (error.response.status === 413) {
          setFileTooLarge(true);
        } else {
          setFileTooLarge(false);
        }
        setFeedBack(false);
      });
  };

  useEffect(() => {
    if (dragAndDrop && dragAndDrop.current) {
      dragAndDrop.current.addEventListener("drop", handleDrop);
      dragAndDrop.current.addEventListener("dragover", prevent);
      dragAndDrop.current.addEventListener("dragenter", prevent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragAndDrop.current]);

  const prevent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const fileUpload = (e) => {
    setFiles((prevFiles) => [...prevFiles, e.target.files[0]]);
  };

  const handleDrop = (e) => {
    prevent(e);
    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0
    ) {
      setFiles((prevFiles) => [...prevFiles, e.dataTransfer.files[0]]);
    }
  };

  const cityLoaded = (res) => {
    formik.setFieldValue("city", res.city);
    if (res.district != null) {
      formik.setFieldValue("district", res.district);
    } else {
      formik.setFieldValue("district", "");
    }
  };

  const cityLoadedMailing = (res) => {
    formik.setFieldValue("cityMailing", res.city);
    if (res.district != null) {
      formik.setFieldValue("districtMailing", res.district);
    } else {
      formik.setFieldValue("districtMailing", "");
    }
  };

  let feedBackBody;
  if (feedBack === true) {
    feedBackBody = (
      <div className={"positive"} onClick={() => setFeedBack(null)}>
        Üzenet sikeresen elküldve!
      </div>
    );
  }
  if (feedBack === false) {
    let msg = "Üzenet küldése sikertelen volt!";
    if (fileTooLarge) {
      msg = "A fájl mérete túl nagy! A feltöltés sikertelen volt.";
    }
    if (fileSizeWarning) {
      msg = fileSizeWarning;
    }
    feedBackBody = (
      <div className={"negative"} onClick={() => setFeedBack(null)}>
        {msg}
      </div>
    );
  }

  const removeFile = (file) => {
    // remove files from <input> element
    fileInputRef.current.value = "";
    setFiles(files.filter((f) => f !== file));
  };

  const { t } = useTranslation();
  return (
    <div className={"ContactUsForm"}>
      {feedBack != null && <div className={"feedback"}>{feedBackBody}</div>}
      <div className={"contact-form"}>
        {catError && (
          <div className={"error-wrapper"}>
            <h1 className={"error-color"}>{t("contactUsForm.catError")}</h1>
          </div>
        )}
        {pageNumber === 1 && <h6>{t("contact.personalData")}</h6>}
        <div className="form-wrapper">
          {pageNumber === 1 && (
            <div className={"basic-details"}>
              <div>
                <TextField
                  id="declarerName"
                  name="declarerName"
                  color="default"
                  label={t("contactUsForm.declarerName")}
                  variant="standard"
                  fullWidth={true}
                  inputProps={{ required: true, maxLength: 150 }}
                  value={formik.values.declarerName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.declarerName && formik.errors.declarerName
                  }
                />
                <TextField
                  id="consumerName"
                  color="default"
                  label={t("contactUsForm.consumerName")}
                  variant="standard"
                  fullWidth={true}
                  value={formik.values.consumerName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.consumerName && formik.errors.consumerName
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <Tooltip
                          title={t("contactUsForm.tooltip.consumerName")}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  id="paymentId"
                  color="default"
                  label={t("contactUsForm.paymentId")}
                  variant="standard"
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <Tooltip title={t("contactUsForm.tooltip.paymentId")}>
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.paymentId}
                  onChange={(e) => {
                    sanitizeNumberInput(e);
                    setMaxLengthForEvent(e, 9);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.paymentId && formik.errors.paymentId}
                />
                <TextField
                  id="email"
                  color="default"
                  label={t("contactUsForm.email")}
                  variant="standard"
                  type="email"
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <Tooltip title={t("contactUsForm.tooltip.email")}>
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
                <TextField
                  id="phoneNum"
                  color="default"
                  label={t("contactUsForm.phoneNum")}
                  variant="standard"
                  type="tel"
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <Tooltip title={t("contactUsForm.tooltip.phoneNum")}>
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.phoneNum}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneNum && formik.errors.phoneNum}
                />
                <FormControlLabel
                  value={formik.values.emailAndTelephoneConsent}
                  control={
                    <Checkbox
                      id="emailAndTelephoneConsent"
                      color={"default"}
                      checked={formik.values.emailAndTelephoneConsent}
                      onChange={(e) => {
                        if (
                          e.target.value === "true" &&
                          formik.values.preRegistration
                        ) {
                          formik.setFieldValue("preRegistration", false);
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.emailAndTelephoneConsent &&
                        formik.errors.emailAndTelephoneConsent
                      }
                    />
                  }
                  label={
                    <div>
                      {t("contactUsForm.emailAndTelephoneConsent1")}
                      <Link
                        to={"/adatvedelem"}
                        target="_blank"
                        rel={"noreferrer"}
                      >
                        {t("contactUsForm.emailAndTelephoneConsent2")}
                      </Link>
                      {t("contactUsForm.emailAndTelephoneConsent3")}
                    </div>
                  }
                />
                <div className="address-and-popover">
                  <h6>{t("contactUsForm.consumptionAddress")}</h6>
                  <PopoverInfoIcon
                    popoverText={t("contactUsForm.consumptionAddressPopover")}
                  />
                </div>
                <FormControlLabel
                  value={formik.values.mailingAddressEqualsConsumptionAddress}
                  control={
                    <Checkbox
                      id="mailingAddressEqualsConsumptionAddress"
                      color={"default"}
                      checked={
                        formik.values.mailingAddressEqualsConsumptionAddress
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.mailingAddressEqualsConsumptionAddress &&
                        formik.errors.mailingAddressEqualsConsumptionAddress
                      }
                    />
                  }
                  label={t(
                    "contactUsForm.mailingAddressEqualsConsumptionAddress"
                  )}
                />
                <TextField
                  id="postcode"
                  color="default"
                  label={t("contactUsForm.postcode")}
                  variant="standard"
                  fullWidth={true}
                  inputProps={{ required: true, maxLength: 4, minLength: 4 }}
                  value={formik.values.postcode}
                  onChange={(e) => {
                    sanitizeNumberInput(e);
                    setMaxLengthForEvent(e, 4);
                    formik.handleChange(e);
                    if (e.target.value.length === 4) {
                      setPostCode(e.target.value);
                      setTimeout(() => {
                        setPostCode(null);
                      }, 100);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.postcode && formik.errors.postcode}
                />
                <TextField
                  id="city"
                  color="default"
                  label={t("contactUsForm.city")}
                  variant="standard"
                  fullWidth={true}
                  inputProps={{ required: true }}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && formik.errors.city}
                />
                <TextField
                  id="district"
                  color="default"
                  label={t("contactUsForm.district")}
                  variant="standard"
                  fullWidth={true}
                  value={formik.values.district}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.district && formik.errors.district}
                />
                <TextField
                  id="spaceType"
                  color="default"
                  label={t("contactUsForm.spaceType")}
                  variant="standard"
                  fullWidth={true}
                  inputProps={{ required: true, maxLength: 250 }}
                  value={formik.values.spaceType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.spaceType && formik.errors.spaceType}
                />
                <div className={"small-details"}>
                  <TextField
                    id="houseNum"
                    color="default"
                    label={t("contactUsForm.houseNum")}
                    variant="standard"
                    fullWidth={true}
                    inputProps={{ required: true, maxLength: 25 }}
                    value={formik.values.houseNum}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.houseNum && formik.errors.houseNum}
                  />
                  <TextField
                    id="floor"
                    color="default"
                    label={t("contactUsForm.floor")}
                    variant="standard"
                    fullWidth={true}
                    value={formik.values.floor}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 10 }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.floor && formik.errors.floor}
                  />
                  <TextField
                    id="door"
                    color="default"
                    label={t("contactUsForm.door")}
                    variant="standard"
                    fullWidth={true}
                    value={formik.values.door}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      sanitizeNumberInput(e);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.door && formik.errors.door}
                  />
                </div>
                <div className={"second-part"}>
                  <h6>{t("contactUsForm.electronicInvoicingPossibility")}</h6>
                  <p>
                    Engedje meg, hogy felhívjuk figyelmét a &nbsp;
                    <a
                      href={"https://www.dijnet.hu"}
                      target="_blank"
                      rel={"noreferrer"}
                    >
                      <img
                        src={dijnetLogo}
                        alt={"dijnet.hu"}
                        className="dijnet-inline-logo"
                      />
                    </a>
                    &nbsp; internetes számlakezelő rendszerére -{" "}
                    <a
                      href={"https://www.dijnet.hu"}
                      target="_blank"
                      rel={"noreferrer"}
                    >
                      www.dijnet.hu
                    </a>{" "}
                    -, melynek használatával Ön egyszerűen, ingyenesen és
                    biztonságosan kezelheti és fizetheti számláit. Többféle
                    fizetési mód közül választhat: fizethet internetbankon
                    keresztül, bankkártyával, mobiltelefon segítségével.
                  </p>
                </div>
                <div className="send-me-detailed-info">
                  <FormControlLabel
                    value={formik.values.sendMeDetailedInfo}
                    control={
                      <Checkbox
                        id="sendMeDetailedInfo"
                        color={"default"}
                        onChange={formik.handleChange}
                        checked={formik.values.sendMeDetailedInfo}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.sendMeDetailedInfo &&
                          formik.errors.sendMeDetailedInfo
                        }
                      />
                    }
                    label={t("contactUsForm.sendMeDetailedInfo")}
                  />
                </div>
                <p className={"small-font"}>
                  {t("contactUsForm.emailAddressRevocation")}
                </p>
                {formik.values.emailAndTelephoneConsent ? (
                  <p>
                    {t("contactUsForm.emailAndTelephoneConsent.1")}
                    <a
                      href={"https://www.dijnet.hu"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={dijnetLogo}
                        alt={"dijnet.hu"}
                        className="dijnet-inline-logo"
                      />
                    </a>
                    {t("contactUsForm.emailAndTelephoneConsent.2")}
                  </p>
                ) : (
                  <p>
                    {t("contactUsForm.emailAndTelephoneConsent.1")}
                    <a
                      href={"https://www.dijnet.hu"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={dijnetLogo}
                        alt={"dijnet.hu"}
                        className="dijnet-inline-logo"
                      />
                    </a>
                    {t("contactUsForm.emailAndTelephoneConsent.3") + " "}
                    <a
                      href={"https://www.dijnet.hu/ekonto/control/reg_start"}
                      target="_blank"
                      rel={"noreferrer"}
                    >
                      itt
                    </a>{" "}
                    {t("contactUsForm.emailAndTelephoneConsent.4")}
                  </p>
                )}
              </div>
              {formik.values.emailAndTelephoneConsent && (
                <div className="send-me-detailed-info">
                  <FormControlLabel
                    value={formik.values.preRegistration}
                    control={
                      <Checkbox
                        id="preRegistration"
                        color={"default"}
                        onChange={formik.handleChange}
                        checked={formik.values.preRegistration}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.preRegistration &&
                          formik.errors.preRegistration
                        }
                      />
                    }
                    label={t("contactUsForm.preRegistration")}
                  />
                  <p className={"small-font"}>
                    {t("contactUsForm.emailAddressRevocation")}
                  </p>
                </div>
              )}
            </div>
          )}
          {pageNumber === 2 && (
            <div className="seperate-consumption-address">
              <div className="address-and-popover">
                <h6>{t("contactUsForm.mailingAddress")}</h6>
                <PopoverInfoIcon
                  popoverText={t("contactUsForm.mailingAddressPopover")}
                />
              </div>
              <TextField
                id="postcodeMailing"
                color="default"
                label={t("contactUsForm.postcode")}
                variant="standard"
                fullWidth={true}
                inputProps={{ required: true, maxLength: 4, minLength: 4 }}
                value={formik.values.postcodeMailing}
                onChange={(e) => {
                  sanitizeNumberInput(e);
                  setMaxLengthForEvent(e, 4);
                  formik.handleChange(e);
                  if (e.target.value.length === 4) {
                    setPostCodeMailing(e.target.value);
                    setTimeout(() => {
                      setPostCodeMailing(null);
                    }, 100);
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postcodeMailing &&
                  formik.errors.postcodeMailing
                }
              />
              <TextField
                id="cityMailing"
                color="default"
                label={t("contactUsForm.city")}
                variant="standard"
                fullWidth={true}
                inputProps={{ required: true }}
                value={formik.values.cityMailing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.cityMailing && formik.errors.cityMailing}
              />
              <TextField
                id="districtMailing"
                color="default"
                label={t("contactUsForm.district")}
                variant="standard"
                fullWidth={true}
                value={formik.values.districtMailing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.districtMailing &&
                  formik.errors.districtMailing
                }
              />
              <TextField
                id="spaceTypeMailing"
                color="default"
                label={t("contactUsForm.spaceType")}
                variant="standard"
                fullWidth={true}
                inputProps={{ required: true, maxLength: 250 }}
                value={formik.values.spaceTypeMailing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.spaceTypeMailing &&
                  formik.errors.spaceTypeMailing
                }
              />
              <div className={"small-details"}>
                <TextField
                  id="houseNumMailing"
                  color="default"
                  label={t("contactUsForm.houseNum")}
                  variant="standard"
                  fullWidth={true}
                  inputProps={{ required: true, maxLength: 25 }}
                  value={formik.values.houseNumMailing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.houseNumMailing &&
                    formik.errors.houseNumMailing
                  }
                />
                <TextField
                  id="floorMailing"
                  color="default"
                  label={t("contactUsForm.floor")}
                  variant="standard"
                  fullWidth={true}
                  value={formik.values.floorMailing}
                  inputProps={{ maxLength: 10 }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.floorMailing && formik.errors.floorMailing
                  }
                />
                <TextField
                  id="doorMailing"
                  color="default"
                  label={t("contactUsForm.door")}
                  variant="standard"
                  fullWidth={true}
                  value={formik.values.doorMailing}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => {
                    sanitizeNumberInput(e);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.doorMailing && formik.errors.doorMailing
                  }
                />
              </div>
            </div>
          )}
          {pageNumber === 3 && (
            <div className="contact-information">
              <div className="subject-of-contact">
                <h6>{t("contactUsForm.reasonForContact")}</h6>
              </div>
              <div className="details-of-subject">
                <p>
                  {t("contactUsForm.reasonForContactDetails")}
                  <Link
                    to={Paths.SERVICEANDCHARGES}
                    target="_blank"
                    rel={"noreferrer"}
                  >
                    {t("contactUsForm.reasonForContactLink")}
                  </Link>
                </p>
              </div>
              <div className={"consent"}>
                <FormControlLabel
                  value={formik.values.consentForApplyingCost}
                  control={
                    <Checkbox
                      id="consentForApplyingCost"
                      color={"default"}
                      checked={formik.values.consentForApplyingCost}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.consentForApplyingCost &&
                        formik.errors.consentForApplyingCost
                      }
                    />
                  }
                  label={t("contactUsForm.reasonForContactConsent")}
                />
              </div>
              <div className="category">
                <FormControl color={"default"} fullWidth>
                  <InputLabel id="category-select-id-1" shrink={false}>
                    {!formik.values.category && t("contactUsForm.category")}
                  </InputLabel>
                  {complaint && (
                    <Select
                      labelId="category-select-id-1"
                      name="category"
                      inputProps={{ required: true }}
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.category}
                    >
                      {categories &&
                        categories.data.map((category, index) => {
                          return (
                            index <= 12 && (
                              <MenuItem value={category}>{category}</MenuItem>
                            )
                          );
                        })}
                    </Select>
                  )}
                  {!complaint && (
                    <Select
                      labelId="category-select-id-1"
                      name="category"
                      inputProps={{ required: true }}
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.category}
                    >
                      {categories &&
                        categories.data.map((category, index) => {
                          return (
                            index > 12 && (
                              <MenuItem value={category}>{category}</MenuItem>
                            )
                          );
                        })}
                    </Select>
                  )}
                </FormControl>
              </div>
              <div className="issue-details">
                <div className="title">{t("contactUsForm.statement")}</div>
                <div>
                  <OutlinedInput
                    id="issueDetails"
                    color={"default"}
                    fullWidth={true}
                    multiline={true}
                    minRows={3}
                    inputProps={{ required: true }}
                    value={formik.values.issueDetails}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.issueDetails}
                  />
                </div>
              </div>

              <div className="attachment">
                <div className="title">{t("contactUsForm.attachment")}</div>
                <div
                  className="drop-zone"
                  ref={dragAndDrop}
                  onClick={() => fileInputRef.current.click()}
                >
                  <div className="upload-file">
                    <input
                      type="file"
                      id="upload-file-input"
                      className="button-upload"
                      onChange={(event) => {
                        const f = event.target.files[0];
                        if (f.size / 1000000 > 10) {
                          setFileSizeWarning(`${f.name} nagyobb mint 10MB`);
                          setFeedBack(false);
                          return;
                        }
                        let sum = 0;
                        files.forEach((file) => {
                          sum += file.size;
                        });
                        if (sum / 1000000 > 25) {
                          setFileSizeWarning(
                            "Az összes fájl mérete meghaladja a 25MB-t."
                          );
                          setFeedBack(false);
                          return;
                        }

                        setFeedBack(null);
                        fileUpload(event);
                      }}
                      ref={fileInputRef}
                    />
                  </div>
                  <div className="inner">
                    <AttachmentOutlinedIcon />
                    <div className="text">
                      <div>{t("contactUsForm.pickAttachment")}</div>
                      <strong>{t("contactUsForm.attachmentdetails")}</strong>
                    </div>
                  </div>
                </div>
                <div className="attachment-uploaded">
                  {files.length > 0 &&
                    files.length + " " + t("contactUsForm.fileAttached")}
                </div>
                <div className="files">
                  {files?.map((f, i) => {
                    return (
                      <div className="file" key={i}>
                        <div>{f?.name}</div>
                        <CancelIcon onClick={() => removeFile(f)} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {pageNumber === 1 && (
        <div className={"button-wrapper"}>
          <Button
            color={"default"}
            onClick={() => {
              touchFields([
                "declarerName",
                "consumerName",
                "email",
                "postcode",
                "city",
                "spaceType",
                "houseNum",
              ]);
              formik.validateForm().then((result) => {
                if (_.isEmpty(result)) {
                  setPageNumber(
                    !formik.values.mailingAddressEqualsConsumptionAddress
                      ? 2
                      : 3
                  );
                }
              });
            }}
          >
            {t("contactUsForm.next")}
            <ArrowForwardIosIcon sx={{ width: "20px", height: "20px" }} />
          </Button>
        </div>
      )}

      {pageNumber === 2 && (
        <div className={"button-wrapper"}>
          <Button onClick={() => setPageNumber(1)} color={"default"}>
            {t("contactUsForm.back")}
          </Button>
          <Button
            color={"default"}
            onClick={() => {
              touchFields([
                "postcodeMailing",
                "cityMailing",
                "spaceTypeMailing",
                "houseNumMailing",
              ]);
              formik.validateForm().then((result) => {
                if (_.isEmpty(result)) {
                  setPageNumber(3);
                }
              });
            }}
          >
            {t("contactUsForm.next")}
          </Button>
        </div>
      )}
      {pageNumber === 3 && (
        <div className={"button-wrapper"}>
          <Button
            color={"default"}
            onClick={() => {
              setPageNumber(
                formik.values.mailingAddressEqualsConsumptionAddress ? 1 : 2
              );
            }}
          >
            {t("contactUsForm.back")}
          </Button>
          <Button
            color={"default"}
            id={"send_form"}
            onClick={() => {
              formik.validateForm().then((result) => {
                if (_.isEmpty(result)) {
                  formik.submitForm();
                }
              });
            }}
          >
            {t("contactUsForm.send")}
          </Button>
        </div>
      )}
      {!_.isEmpty(formik.errors) && (
        <div className="errors">
          {pageNumber === 1 && (
            <>
              <p>{formik.touched.declarerName && formik.errors.declarerName}</p>
              <p>{formik.errors.postcode}</p>
              <p>{formik.errors.city}</p>
              <p>{formik.errors.spaceType}</p>
              <p>{formik.errors.houseNum}</p>
              <p>{formik.errors.consumerName}</p>
              <p>{formik.errors.paymentId}</p>
              <p>{formik.errors.email}</p>
            </>
          )}
          {pageNumber === 2 && (
            <>
              <p>{formik.errors.postcodeMailing}</p>
              <p>{formik.errors.cityMailing}</p>
              <p>{formik.errors.spaceTypeMailing}</p>
              <p>{formik.errors.houseNumMailing}</p>
            </>
          )}
          {pageNumber === 3 && (
            <>
              <p>{formik.errors.category}</p>
              <p>{formik.errors.issueDetails}</p>
              <p>{formik.errors.consentForApplyingCost}</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
