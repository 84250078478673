import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import lang from "./lang.json";

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    resources: lang,
    lng: "hu",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });
};
