import React, { useState } from "react";
import { ShrinkingHeaderWithContent } from "components/organisms/ShrinkingHeaderWithContent/ShrinkingHeaderWithContent";
import DOMPurify from "dompurify";
import "./FogalomtarTemplate.scss";
import { LetterPicker } from "components/molecules/LetterPicker/LetterPicker";
import _ from "lodash";
import { BreadcrumbFromPageData } from "components/molecules/Breadcrumb/BreadcrumbFromPageData";
import { getBackgroundByPageId } from "utils/ResourceMapper";
import { useTheme } from "@mui/material";
import { BlueBanner } from "../../molecules/BlueBanner/BlueBanner";

export const FogalomtarTemplate = ({ pageData }) => {
  const theme = useTheme();

  const getSortedContent = () => {
    return pageData.content.sort((a, b) => {
      const textA = a.address.toUpperCase();
      const textB = b.address.toUpperCase();
      return textA.localeCompare(textB, "hu", { ignorePunctuation: true });
    });
  };

  const [content, setContent] = useState(getSortedContent());

  const letterSelected = (l) => {
    if (l === "all") {
      setContent(getSortedContent());
    } else {
      let newContent = [];
      pageData.content.forEach((c) => {
        if (c.address[0].toUpperCase() === l.toUpperCase()) {
          newContent.push(c);
        }
      });
      setContent(newContent);
    }
  };

  const availableLetters = _.uniq(
    pageData.content.map((c) => c.address[0].toLowerCase())
  );

  return (
    <div className={"FogalomtarTemplate"}>
      <BreadcrumbFromPageData pageData={pageData} />
      <ShrinkingHeaderWithContent
        navigateTo={"/" + pageData.parentPage.url}
        text={pageData.address}
        backgroundImage={getBackgroundByPageId(pageData.id)}
        color={theme.palette.primary.main}
        content={
          <div className={"notion-content-wrapper"}>
            <LetterPicker
              onSelect={letterSelected}
              availableLetters={availableLetters}
            />
            <div className="content-wrapper">
              {content.map((c) => {
                return (
                  <div className={"content"} key={c.id}>
                    <h3>{c.address}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(c.text),
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        }
      />
      <BlueBanner />
    </div>
  );
};
